import clsx from "clsx";
import { MdAccountBalance } from "react-icons/md";
import ButtonGroup from "../ButtonGroup/ButtonGroup";

interface IBankCard {
  account: string;
  bank?: string;
  owner?: string;
  number?: string;
  name?: string;
  selected?: boolean;
  actions?: Array<any>;
  variant?: "blue" | "gray" | "custom";
  actionsTrigger?: "hover" | "normal";
  customStyles?: any;
  className?: string;
  currency: string;
}

const BankCard: React.FC<IBankCard> = ({
  bank,
  owner,
  number,
  name,
  selected = false,
  actions = [],
  account,
  variant = "blue",
  actionsTrigger = "",
  customStyles = {},
  className,
  currency,
}) => {
  const getVariantClasses = () => {
    switch (variant) {
      case "gray": {
        return {
          "bg-gray-100": !selected,
          "text-gray-900": !selected,
          "bg-expenser-gray/80 text-white": selected,
          "hover:bg-expenser-gray/80 hover:text-gray-900": !selected,
          "hover:bg-expenser-gray hover:text-white": selected,
        };
      }
      case "blue": {
        return {
          "bg-secondary-100": !selected,
          "text-secondary-900": !selected,
          "bg-primary/80 text-white": selected,
          "hover:bg-primary/80 hover:text-white": !selected,
          "hover:bg-primary hover:text-white": selected,
        };
      }

      case "custom": {
        return {};
      }
    }
  };

  const SHARED_STYLES = {
    relative: true,
    flex: true,
    "flex-col": true,
    "rounded-md md:rounded-md": true,
    "text-sm": true,
    "p-3": true,
    "gap-7": true,
    "transition-colors": true,
    group: true,
    "shadow-md": true,
  };

  return (
    <>
      <div
        className={clsx([SHARED_STYLES, getVariantClasses(), className])}
        style={customStyles}
      >
        <div
          className={clsx([
            "text-right",
            "flex",
            "flex-row",
            "gap-4",
            "justify-between",
            "items-center",
          ])}
        >
          <MdAccountBalance size={32} />
          <div className={clsx(["flex", "flex-col"])}>
            <p className="text-xs">{bank || ""}</p>
            <p className="text-xs">{currency}</p>
          </div>
        </div>

        <div className={clsx(["pr-0", "xl:pr-8"])}>
          <p className="capitalize truncate">{name || "Cuenta sin nombre"}</p>
          <p className={clsx(["font-medium", "truncate"])}>
            {number || <span className="text-xs pr-4"></span>}
          </p>
        </div>
        {actions?.length > 0 && (
          <div
            className={clsx([
              "absolute",
              "bottom-0",
              "right-0",
              "p-2",
              {
                "md:hidden md:group-hover:flex": actionsTrigger === "hover",
              },
            ])}
          >
            <ButtonGroup gap={2} buttons={actions} />
          </div>
        )}
      </div>
    </>
  );
};

export default BankCard;
