import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import useAccountsStore from "../../store/accountsStore";
import clsx from "clsx";
import { getTotalByTransactionType } from "../../helpers/utils";
import { useTranslation } from "react-i18next";
import { EXPENSE_TYPE, INCOME_TYPE } from "../../helpers/constants";
import AccountsSummaryTable from "../../components/InvoicesChart/AccountsSummaryTable";
import { getColorFromAccountSettings } from "../../helpers/accounts";
import transactionsStore from "../../store/transactionsStore";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface ITransactionsByType {
  transactions: Array<any>;
}

const options = {
  responsive: true,
  maintainAspectRatio: false,
  // aspectRatio
  pointRadius: 15,
  pointHoverRadius: 30,
  interaction: {
    mode: "index" as const,
    intersect: false,
  },
  borderColor: "#444",
  borderWidth: 1,
  elements: {
    line: {
      tension: 0.5,
    },
  },
  plugins: {
    legend: {
      position: "top" as const,
      display: false,
      align: "end" as const,
      labels: {
        padding: 10,
        useBorderRadius: true,
        borderRadius: 3,
        font: {
          size: 16,
        },
      },
    },
  },
};

const TransactionsByType: React.FC<ITransactionsByType> = ({
  transactions,
}) => {
  const { getAccountById } = useAccountsStore();
  const { filters } = transactionsStore();
  const { main: mainFilter } = filters || {};

  const { t } = useTranslation();
  const accounts = Array.from(
    new Set(transactions?.map?.(({ account }) => account) || [])
  )?.filter?.((account) => account);

  let labels = [];

  if (!mainFilter.type) {
    labels.push(t("summary.incomes"));
    labels.push(t("summary.expenses"));
    labels.push(t("summary.balance"));
  } else {
    if (mainFilter.type === INCOME_TYPE) {
      labels.push(t("summary.incomes"));
    } else {
      labels.push(t("summary.expenses"));
    }
  }

  return (
    <div className={clsx("flex", "flex-col-reverse", "gap-4")}>
      <div className={clsx(["flex", "overflow-auto", "w-full"])}>
        <div className={clsx(["w-full", "block"])}>
          {/* <AccountsSummaryTable transactions={transactions} /> */}
        </div>
      </div>

      <div>
        <Bar
          className="w-full"
          options={options}
          data={{
            datasets:
              accounts?.map((account: string, index: number) => {
                const total =
                  getTotalByTransactionType({
                    type: INCOME_TYPE,
                    account,
                    transactions,
                  }) -
                  getTotalByTransactionType({
                    type: EXPENSE_TYPE,
                    account,
                    transactions,
                  });
                return {
                  label:
                    getAccountById(account)?.name || "Transacciones sin cuenta",
                  data: (() => {
                    if (mainFilter.type === INCOME_TYPE) {
                      return [
                        getTotalByTransactionType({
                          type: INCOME_TYPE,
                          account,
                          transactions,
                        }),
                      ];
                    } else if (mainFilter.type === EXPENSE_TYPE) {
                      return [
                        getTotalByTransactionType({
                          type: EXPENSE_TYPE,
                          account,
                          transactions,
                        }),
                      ];
                    }

                    return [
                      getTotalByTransactionType({
                        type: INCOME_TYPE,
                        account,
                        transactions,
                      }),
                      -getTotalByTransactionType({
                        type: EXPENSE_TYPE,
                        account,
                        transactions,
                      }),
                      total,
                    ];
                  })(),
                  backgroundColor: getColorFromAccountSettings(
                    getAccountById(account)?.settings
                  )?.background,
                  borderWidth: 1,
                  borderColor: "#444",
                  borderRadius: 6,
                  hoverBorderColor: "rgb(248, 113, 113)",
                };
              }) || [],
            labels,
          }}
        />
      </div>
    </div>
  );
};

export default TransactionsByType;
