import { useEffect } from "react";
import useAccountsStore from "../../store/accountsStore";
import useCategoriesStore from "../../store/categoriesStore";
import transactionsStore from "../../store/transactionsStore";

const StoreLoaders: React.FC = () => {
  const { setAllAccounts } = useAccountsStore();
  const { setAllPayToList } = transactionsStore();
  const { setAllCategories } = useCategoriesStore();

  useEffect(() => {
    const load = async () => {
      await setAllAccounts({});
      await setAllCategories({});
      await setAllPayToList();
    };

    load();
  }, [setAllAccounts, setAllCategories, setAllPayToList]);

  return <></>;
};

export default StoreLoaders;
