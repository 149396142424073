import clsx from "clsx";
import TwoSidebars from "../../layouts/TwoSidebars/TwoSidebars";
import { useNavigate } from "react-router-dom";

import PageTitle from "../../components/PageTitle/PageTitle";
import ButtonGroup from "../../components/ButtonGroup/ButtonGroup";
import {
  MdArrowBack,
  MdAttachMoney,
  MdCategory,
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdCompareArrows,
  MdOutlineShoppingCart,
  MdViewSidebar,
} from "react-icons/md";
import userPreferences from "../../store/userPreferences";
import { useEffect, useState } from "react";
import transactionsStore from "../../store/transactionsStore";
import _ from "lodash";
import categoriesStore from "../../store/categoriesStore";
import { getRandomColor } from "../../helpers/utils";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import CategoryEditPanel from "../../components/CategoryEditPanel/CategoryEditPanel";
import LoadingWithText from "../../components/LoadingWithText/LoadingWithText";
import { ALL_USER_ICONS } from "../../helpers/user-icons";
import TextInput from "../../components/TextInput/TextInput";
import MediaQuery, { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import SettingsBlocks from "../../components/SettingsBlocks/SettingsBlocks";
import { BUTTON_STYLES } from "../../helpers/constants";

interface ICategoryIcon {
  name: string;
}
export const CategoryIcon: React.FC<ICategoryIcon> = ({ name }) => {
  const Icon = ALL_USER_ICONS.find(
    ({ name: iconName }) => name === iconName
  )?.icon;

  if (Icon) {
    return <Icon size={48} />;
  }

  return <MdCategory size={48} />;
};

const CategoriesPage: React.FC = () => {
  const { rightPanelIsVisible, setRightPanelIsVisible } = userPreferences();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { searchTransactions } = transactionsStore();
  const [isLoadingTransactions, setIsLoadingTransactions] =
    useState<boolean>(true);
  const { getCategories } = categoriesStore();
  const [transactions, setTransactions] = useState<Array<any>>([]);
  const [usedCategories, setUsedCategories] = useState<Array<any>>([]);
  const [customizedCategories, setCustomizedCategories] = useState<Array<any>>(
    []
  );
  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  const [isLoadingCustomizedCategories, setIsLoadingCustomizedCategories] =
    useState<boolean>(true);
  const [refreshKey, setRefreshKey] = useState<number>(0);
  const [categorySearchTerm, setCategorySearchTerm] = useState<string>("");

  useEffect(() => {
    setIsLoadingTransactions(true);
    setIsLoadingCustomizedCategories(true);

    searchTransactions({
      params: new URLSearchParams(),
      onError: (e: any) => {
        // toast.error("Error cargando transacciones");
        console.log("Error", e);

        setIsLoadingTransactions(false);
      },
      onResolved: (res: any) => {
        setTransactions(res);
        setIsLoadingTransactions(false);
        // console.log("Data is", res);
      },
    });

    getCategories({
      onError: () => {
        setIsLoadingCustomizedCategories(false);
      },
      onResolved: (nada: any) => {
        setCustomizedCategories(nada);
        setIsLoadingCustomizedCategories(false);
      },
    });
  }, [refreshKey]);

  const getCategoryCustomInfo = ({ category }: { category: string }) => {
    return (
      customizedCategories?.find?.(
        ({ category: categoryName }) => category === categoryName
      ) || null
    );
  };

  useEffect(() => {
    if (!isLoadingTransactions) {
      const cats: any = [];
      transactions?.forEach?.(({ details, category }) => {
        if (details?.length) {
          details?.forEach?.(({ category }: { category: any }) => {
            if (category) {
              cats.push(category);
            } else {
              cats.push("Sin categoría");
            }
          });
        } else {
          if (category) {
            cats.push(category);
          } else {
            cats.push("Sin categoría");
          }
        }
      });
      setUsedCategories(Array.from(new Set(cats)).sort?.());
    }
  }, [isLoadingTransactions]);

  const isLoading = isLoadingCustomizedCategories || isLoadingTransactions;

  const filteredUsedCategories = () => {
    if (categorySearchTerm && `${categorySearchTerm}`.trim()) {
      return usedCategories?.filter?.((category) =>
        category
          ?.toLowerCase?.()
          ?.trim?.()
          ?.includes?.(categorySearchTerm?.toLowerCase?.()?.trim?.())
      );
    }

    return usedCategories;
  };

  return (
    <TwoSidebars
      rightClasses={clsx(["flex-col-reverse md:flex-col"])}
      showClock={false}
      right={
        <>
          {/* <SettingsBlocks layout="vertical" /> */}
          {<SettingsBlocks layout="vertical" columns={isMobile ? 2 : 1} />}

          {selectedCategory ? (
            <CategoryEditPanel
              onChange={() => {
                const category = selectedCategory;
                const info = getCategoryCustomInfo({ category }) || category;
                setSelectedCategory(info);
              }}
              category={selectedCategory}
            />
          ) : usedCategories?.length ? (
            <>
              <MediaQuery minWidth={768}>
                <div
                  className={clsx([
                    "flex",
                    "justify-center",
                    "items-center",
                    "gap-4",
                    "text-gray-400",
                    "h-full",
                    "flex-col",
                    "py-10",
                    "md:py-0",
                  ])}
                >
                  <p className="text-sm text-center px-1">
                    {t("categories_page.select_category")}
                  </p>
                </div>
              </MediaQuery>
            </>
          ) : (
            <div>
              {/* Aún no has realizado transacciones. Las categorías se agregan
              automáticamente y aparecen en esta lista. */}
            </div>
          )}
        </>
      }
    >
      <div className={clsx(["flex", "w-full", "flex-col", "gap-0"])}>
        <PageTitle
          title={t("shared.settings")}
          subtitle={t("categories_page.title")}
          isLoading={false}
          titleLink="/settings"
          rightSide={
            <>
              <ButtonGroup
                align="right"
                gap={2}
                buttons={[
                  {
                    icon: <MdArrowBack size={24} />,
                    classes: BUTTON_STYLES.HEADING,

                    tooltip: "Atras",
                    onClick: () => {
                      navigate("/settings");
                    },
                    hide: !isMobile,
                  },
                  {
                    icon: <MdOutlineShoppingCart size={24} />,
                    tooltip: "Añadir gasto",
                    onClick: () => {
                      navigate("/transactions/expense");
                    },
                    hide: isMobile,
                  },

                  {
                    icon: <MdAttachMoney size={24} />,
                    tooltip: "Añadir ingreso",
                    hide: isMobile,
                    onClick: () => {
                      navigate("/transactions/income");
                    },
                  },
                  {
                    icon: <MdCompareArrows size={24} />,
                    tooltip: "Transacciones",
                    onClick: () => {
                      navigate("/transactions");
                    },
                    hide: isMobile,
                  },

                  {
                    icon: <MdViewSidebar size={24} />,
                    tooltip: "Panel lateral",
                    kind: rightPanelIsVisible ? "primary" : "secondary",
                    hide: isMobile,
                    onClick: () => {
                      setRightPanelIsVisible(!rightPanelIsVisible);
                    },
                  },
                ]}
              />
            </>
          }
        />

        {isLoading ? (
          <div className={clsx(["flex", "w-full", "justify-start"])}>
            <LoadingWithText
              text={t("shared.loading_categories", { count: 1111 })}
            />
          </div>
        ) : (
          <div className={clsx("flex", "flex-col", "gap-6")}>
            <TextInput
              value={categorySearchTerm}
              onChange={(e: any) => setCategorySearchTerm(e?.target?.value)}
              placeholder={t("categories_page.search_placeholder")}
              disabled={false}
              onKeyDown={_.debounce(() => {
                // setCategorySearchTerm(categorySearchTerm);
              }, 1000)}
            />
            <MediaQuery minWidth={768}>
              <ResponsiveMasonry
                columnsCountBreakPoints={{ 0: 3, 768: 2, 1024: 3 }}
              >
                <Masonry gutter="10px">
                  {filteredUsedCategories().map(
                    (category: any, key: number) => {
                      return (
                        <div
                          className={clsx([
                            "flex",
                            "w-full",
                            "bg-checkers",
                            "rounded-md md:rounded-lg",
                            "transition-colors",
                          ])}
                          key={key}
                        >
                          <div
                            className={clsx([
                              "px-2",
                              "py-4",
                              "w-full",
                              "gap-1",
                              "flex",
                              "flex-col",
                              "items-center",
                              "justify-start",
                              "bg-white",
                              "hover:opacity-80",
                              "cursor-pointer",
                              "rounded-md md:rounded-lg",
                              "transition-all",
                              "relative",
                              "text-sm md:text-base",
                            ])}
                            style={{
                              background:
                                getCategoryCustomInfo({ category })?.bg ||
                                getRandomColor(0.2),
                              color:
                                getCategoryCustomInfo({ category })?.color ||
                                "red",
                            }}
                            onClick={() => {
                              if (category) {
                                const info =
                                  getCategoryCustomInfo({ category }) ||
                                  category;
                                const isSelected = [info].includes(
                                  selectedCategory
                                );

                                if (isSelected) {
                                  setSelectedCategory("");
                                } else {
                                  setSelectedCategory(info);
                                }

                                if (info?.icon) {
                                  const el = document.querySelector(
                                    `div#${info.icon}`
                                  );

                                  if (el) {
                                    el.scrollIntoView({
                                      behavior: "smooth",
                                      block: "center",
                                    });
                                  }
                                }
                              }
                            }}
                          >
                            <div
                              className={clsx(["absolute", "right-2", "top-2"])}
                            >
                              <ButtonGroup
                                buttons={[
                                  {
                                    tooltip: t("shared.select"),
                                    onClick: () => {},
                                    icon: ![
                                      getCategoryCustomInfo({ category }),
                                    ].includes(selectedCategory) ? (
                                      <MdCheckBoxOutlineBlank size={20} />
                                    ) : (
                                      <MdCheckBox size={20} />
                                    ),
                                    classes: clsx([
                                      "p-1",
                                      "rounded-md",
                                      "border-0",
                                    ]),
                                  },
                                ]}
                              />
                            </div>
                            {!getCategoryCustomInfo({ category })?.icon ? (
                              <>
                                <MdCategory size={48} />
                              </>
                            ) : (
                              <CategoryIcon
                                name={getCategoryCustomInfo({ category })?.icon}
                              />
                            )}
                            <div className="text-center capitalize">
                              {category}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </Masonry>
              </ResponsiveMasonry>
            </MediaQuery>
            <MediaQuery maxWidth={767}>
              {selectedCategory ? (
                <div className={clsx(["flex", "overflow-auto", "gap-2"])}>
                  {filteredUsedCategories().map(
                    (category: any, key: number) => {
                      return (
                        <div
                          className={clsx([
                            "flex",
                            "w-full",
                            "bg-checkers",
                            "rounded-lg",
                            "transition-colors",
                          ])}
                          key={key}
                        >
                          <div
                            className={clsx([
                              "px-2",
                              "py-4",
                              "w-full",
                              "gap-1",
                              "flex",
                              "flex-row",
                              "items-center",
                              "justify-start",
                              "bg-white",
                              "hover:opacity-80",
                              "cursor-pointer",
                              "rounded-lg",
                              "transition-all",
                              "relative",
                              "text-sm md:text-base",
                            ])}
                            style={{
                              background:
                                getCategoryCustomInfo({ category })?.bg ||
                                getRandomColor(0.2),
                              color:
                                getCategoryCustomInfo({ category })?.color ||
                                "red",
                            }}
                            onClick={() => {
                              if (category) {
                                const info =
                                  getCategoryCustomInfo({ category }) ||
                                  category;
                                const isSelected = [info].includes(
                                  selectedCategory
                                );

                                if (isSelected) {
                                  setSelectedCategory("");
                                } else {
                                  setSelectedCategory(info);
                                }

                                if (info?.icon) {
                                  const el = document.querySelector(
                                    `div#${info.icon}`
                                  );

                                  if (el) {
                                    el.scrollIntoView({
                                      behavior: "smooth",
                                      block: "center",
                                    });
                                  }
                                }
                              }
                            }}
                          >
                            <div
                              className={clsx(["absolute", "right-2", "top-2"])}
                            >
                              <ButtonGroup
                                buttons={[
                                  {
                                    tooltip: t("shared.select"),
                                    onClick: () => {},
                                    icon: ![
                                      getCategoryCustomInfo({ category }),
                                    ].includes(selectedCategory) ? (
                                      <MdCheckBoxOutlineBlank size={20} />
                                    ) : (
                                      <MdCheckBox size={20} />
                                    ),
                                    classes: clsx([
                                      "p-1",
                                      "rounded-md",
                                      "border-0",
                                    ]),
                                  },
                                ]}
                              />
                            </div>
                            {!getCategoryCustomInfo({ category })?.icon ? (
                              <>
                                <MdCategory size={48} />
                              </>
                            ) : (
                              <CategoryIcon
                                name={getCategoryCustomInfo({ category })?.icon}
                              />
                            )}
                            <div className="text-center capitalize whitespace-nowrap pr-10">
                              {category}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              ) : (
                <Masonry gutter="10px">
                  {filteredUsedCategories().map(
                    (category: any, key: number) => {
                      return (
                        <div
                          className={clsx([
                            "flex",
                            "w-full",
                            "bg-checkers",
                            "rounded-lg",
                            "transition-colors",
                          ])}
                          key={key}
                        >
                          <div
                            className={clsx([
                              "px-2",
                              "py-4",
                              "w-full",
                              "gap-1",
                              "flex",
                              "flex-col",
                              "items-center",
                              "justify-start",
                              "bg-white",
                              "hover:opacity-80",
                              "cursor-pointer",
                              "rounded-lg",
                              "transition-all",
                              "relative",
                              "text-sm md:text-base",
                            ])}
                            style={{
                              background:
                                getCategoryCustomInfo({ category })?.bg ||
                                getRandomColor(0.2),
                              color:
                                getCategoryCustomInfo({ category })?.color ||
                                "red",
                            }}
                            onClick={() => {
                              if (category) {
                                const info =
                                  getCategoryCustomInfo({ category }) ||
                                  category;
                                const isSelected = [info].includes(
                                  selectedCategory
                                );

                                if (isSelected) {
                                  setSelectedCategory("");
                                } else {
                                  setSelectedCategory(info);
                                }

                                if (info?.icon) {
                                  const el = document.querySelector(
                                    `div#${info.icon}`
                                  );

                                  if (el) {
                                    el.scrollIntoView({
                                      behavior: "smooth",
                                      block: "center",
                                    });
                                  }
                                }
                              }
                            }}
                          >
                            <div
                              className={clsx(["absolute", "right-2", "top-2"])}
                            >
                              <ButtonGroup
                                buttons={[
                                  {
                                    tooltip: t("shared.select"),
                                    onClick: () => {},
                                    icon: ![
                                      getCategoryCustomInfo({ category }),
                                    ].includes(selectedCategory) ? (
                                      <MdCheckBoxOutlineBlank size={20} />
                                    ) : (
                                      <MdCheckBox size={20} />
                                    ),
                                    classes: clsx([
                                      "p-1",
                                      "rounded-md",
                                      "border-0",
                                    ]),
                                  },
                                ]}
                              />
                            </div>
                            {!getCategoryCustomInfo({ category })?.icon ? (
                              <>
                                <MdCategory size={48} />
                              </>
                            ) : (
                              <CategoryIcon
                                name={getCategoryCustomInfo({ category })?.icon}
                              />
                            )}
                            <div className="text-center capitalize">
                              {category}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </Masonry>
              )}
            </MediaQuery>
          </div>
        )}
      </div>
    </TwoSidebars>
  );
};

export default CategoriesPage;
