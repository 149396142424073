import clsx from "clsx";
import { ReactElement, useId } from "react";

interface ITextInput {
  label?: string | ReactElement;
  placeholder?: string;
  id?: string;
  required?: boolean;
  value?: string;
  onChange: (e: any) => void;
  type?: string;
  disabled?: boolean;
  readOnly?: boolean;
  autoFocus?: boolean;
  list?: string;
  fontBold?: boolean;
  form?: string;
  onKeyDown?: (e: any) => void;
}

const TextInput: React.FC<ITextInput> = ({
  label,
  placeholder,
  id,
  required,
  value = "",
  onChange,
  onKeyDown = () => {},
  type = "text",
  disabled = false,
  readOnly = false,
  autoFocus = false,
  list = "",
  fontBold = false,
  form = "",
}) => {
  const inputAutoId = useId();

  return (
    <div className="flex flex-col w-full">
      {label && (
        <label
          htmlFor={id || inputAutoId}
          className={clsx([
            "text-sm",
            "md:text-base",
            "text-gray-700",
            "font-medium",
          ])}
        >
          {label}
          {required && <span className="ml-1 text-red-500">*</span>}
        </label>
      )}
      <input
        value={value}
        id={id || inputAutoId}
        placeholder={placeholder}
        required={required}
        type={type}
        autoFocus={autoFocus}
        list={list}
        {...(form ? { form } : {})}
        className={clsx([
          "text-primary",
          "dark:text-gray-900",
          "border-solid",
          "border-b-2",
          "text-sm",
          "md:text-base",
          "w-full",
          "h-[40px]",
          "md:h-[46px]",
          "rounded-sm",
          "focus:border-secondary-500 dark:focus:border-gray-500",
          "focus:outline-none",
          "placeholder:text-sm",
          "placeholder:font-light",
          "appearance-none",
          "min-w-full",
          "bg-transparent dark:bg-white",
          "px-1",
          "md:px-2",
          {
            "border-red-500": required,
            "placeholder:text-gray-500": !required,
            "placeholder:text-red-500": required,
            "border-secondary-300 dark:border-gray-300": !required,
            "font-light": !fontBold,
          },
        ])}
        onChange={onChange}
        disabled={disabled}
        readOnly={readOnly}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

export default TextInput;
