import { ReactElement, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import clsx from "clsx";
import userPreferences from "../../store/userPreferences";
import BlocksNavigation from "../../components/Navigation/BlocksNavigation";
import {
  MdArrowDownward,
  MdArrowUpward,
  MdBarChart,
  MdDashboard,
  MdCompareArrows,
} from "react-icons/md";
import { t } from "i18next";
import MediaQuery from "react-responsive";
import StoreLoaders from "../../components/StoreLoaders/StoreLoaders";

interface ITwoSidebars {
  children: ReactElement;
  right?: ReactElement | string | null;
  rightClasses?: string;
  showClock?: boolean;
  customTopBackground?: any;
}

const TwoSidebars: React.FC<ITwoSidebars> = ({
  children,
  right = null,
  rightClasses = "",
  showClock = true,
  customTopBackground = null,
}) => {
  const { rightPanelIsVisible, navIsCollapsed } = userPreferences();
  const [isNavOpen, setIsNavOpen] = useState<boolean>(false);

  return (
    <>
      <StoreLoaders />
      <div
        className={clsx([
          "flex",
          "flex-col",
          "md:flex-row",
          "md:h-[100vh]",
          "pb-[72px]",
          "md:pb-0",
        ])}
      >
        <div
          className={clsx([
            "flex",
            // "bg-secondary-500 dark:bg-red-400",
            "flex-shrink-0",
            {
              // hidden: !isNavOpen,
              // "md:w-[230px]": !navIsCollapsed,
              // "xl:w-[290px]": !navIsCollapsed,
            },
            "pb-[40px]",
            "md:pb-0",
          ])}
        >
          <Sidebar />
        </div>
        <div
          className={clsx([
            "flex",
            "p-4",
            "pt-0 md:pt-4",
            "h-full",
            "md:overflow-y-scroll",
            "md:overflow-x-hidden",
            "flex-col",
            "flex-grow",
            "flex-shrink",
          ])}
        >
          <div
            className={clsx([
              // "pt-2",
              "md:pt-0",
              "pb-0",
              "md:pb-5",
              "min-h-full",
              "flex",
              "flex-col",
              "flex-grow",
            ])}
          >
            <div
              className={clsx([
                'content-[""]',
                "md:hidden",
                "absolute",
                "top-0",
                "left-0",
                "right-0",
                {
                  "h-[185px]": customTopBackground?.bg,
                  "h-[45px]": !customTopBackground?.bg,
                },
                [
                  // "bg-blue-200",
                  customTopBackground?.bg || "bg-gray-800",
                ],
                // customTopBackground?.bg
                //   ? "bg-gradient-to-b from-gray-400 via-gray-50 to-transparent"
                //   : "",
                "-z-10",
              ])}
            />
            <div className={clsx(["flex", "flex-grow"])}>{children}</div>
            <div className={clsx(["md:min-h-[15px]", "flex"])} />
          </div>
        </div>
        {right && (
          <MediaQuery maxWidth={767}>
            <div
              className={clsx([
                "flex",
                "flex-col",
                "md:w-[350px]",
                "md:overflow-auto",
                "p-4",
                "pt-0 md:pt-4",
                {
                  // "bg-secondary-50": true,
                  "md:bg-[#F5F8FF]/40 border-l border-primary/5": false,
                  "md:bg-gray-50/30 dark:md:bg-gray-100": true,
                },
                "flex-shrink-0",
                "gap-6",
                "md:gap-10",
                rightClasses,
              ])}
            >
              {right}
            </div>
          </MediaQuery>
        )}
        {rightPanelIsVisible && right && (
          <MediaQuery minWidth={767}>
            <div
              className={clsx([
                "flex",
                "flex-col",
                "md:w-[350px]",
                "md:overflow-auto",
                "p-4",
                "pt-0 md:pt-4",
                {
                  // "bg-secondary-50": true,
                  // "md:bg-gray-400/100 border-l border-primary/5": true,
                  "md:border-l md:border-primary/5": true,
                  // "md:bg-blue-300/20": true,
                },
                "flex-shrink-0",
                "gap-6",
                "md:gap-10",
                // "md:bg-gray-400/10",
                rightClasses,
              ])}
            >
              {/* {showClock && (
              <div className={clsx(["md:flex", "w-full", "hidden"])}>
                <SidebarClock big />
              </div>
            )} */}
              {right}
            </div>
          </MediaQuery>
        )}
      </div>
      <div
        className={clsx([
          "fixed",
          "bottom-0",
          "right-0",
          // "bg-primary dark:bg-white/90 dark:md:bg-gray-700",
          "bg-gradient-to-t from-white via-white to-transparent",
          "py-2",
          "px-4",
          "md:hidden",
          "left-0",
          // "border-t-4 border-gray-600",
          "z-40",
          // "bg-white/100",
        ])}
      >
        <div
          className={clsx([
            "justify-center",
            "item-center",
            "flex",
            "w-full",
            "z-40",
            "border-b-4",
            "border-b-gray-800",
            "pb-2",
          ])}
        >
          <BlocksNavigation
            links={[
              {
                link: "/dashboard",
                text: t("menu.home"),
                icon: <MdDashboard size={32} />,
                activeClasses: clsx(["!text-blue-500"]),
              },
              {
                link: "/transactions/income",
                text: t("menu.income"),
                icon: <MdArrowDownward size={32} />,
                activeClasses: clsx(["!text-green-500"]),
              },
              {
                link: "/transactions/expense",
                text: t("menu.expense"),
                icon: <MdArrowUpward size={32} />,
                activeClasses: clsx(["!text-red-500"]),
              },

              {
                link: "/transactions",
                text: t("menu.transactions"),
                icon: <MdCompareArrows size={32} />,
                activeClasses: clsx(["!text-purple-500"]),
              },
              {
                link: "/reports",
                text: t("menu.reports"),
                icon: <MdBarChart size={32} />,
                activeClasses: clsx(["!text-cyan-500"]),
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default TwoSidebars;
