import { useTranslation } from "react-i18next";
import Modal from "../Modal/Modal";
import {
  MdCheck,
  MdCheckBox,
  MdCheckCircle,
  MdClear,
  MdClearAll,
  MdClose,
  MdDeleteSweep,
  MdFilterAlt,
  MdFilterAltOff,
  MdFilterListOff,
  MdRadioButtonChecked,
  MdRadioButtonUnchecked,
  MdRemoveCircle,
  MdSearch,
  MdSearchOff,
} from "react-icons/md";
import clsx from "clsx";
import Select from "../Select/Select";
import { useMediaQuery } from "react-responsive";
import { useEffect, useMemo, useState } from "react";
import TextInput from "../TextInput/TextInput";
import useAccountsStore from "../../store/accountsStore";
import useCategoriesStore from "../../store/categoriesStore";

import moment from "moment";
import {
  APP_USER_ID_KEY,
  BUTTON_STYLES,
  EXPENSE_TYPE,
  INCOME_TYPE,
  PAYMENT_METHODS,
  TRANSACTION_STATUSES,
} from "../../helpers/constants";
import { transformToSelectOptions } from "../../helpers/utils";
import ButtonGroup from "../ButtonGroup/ButtonGroup";
import transactionsStore from "../../store/transactionsStore";
import Tags from "../Tags/Tags";
import usersStore from "../../store/usersStore";
import _ from "lodash";

const TRANSACTIONS_SEARCH_FORM = "transactions-search-form";

interface ITransactionsSearchModal {
  onClose: () => void;
  disableFields?: boolean;
  onSubmit: (values: any) => void;
}

const TransactionsSearchModal: React.FC<ITransactionsSearchModal> = ({
  onClose,
  disableFields = false,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const { filters, setFilter, allPayToList } = transactionsStore();

  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const {
    main: mainFilter = {
      dateFrom: "",
      dateTo: "",
      type: "",
      payTo: "",
      status: "",
      account: "",
      paymentMethod: "",
      tags: [],
      category: "",
    },
  } = filters || {};

  const { getAccountsListForSelect } = useAccountsStore();
  const { allCategories } = useCategoriesStore();

  const [dateFrom, setDateFrom] = useState<string>("");
  const [dateTo, setDateTo] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [account, setAccount] = useState<string>("");
  const [payTo, setPayTo] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [paymentMethod, setPaymentMethod] = useState<string>("");
  const [tags, setTags] = useState<Array<string>>([]);
  const [category, setCategory] = useState<string>("");

  const isCustomFilter =
    dateFrom ||
    dateTo ||
    payTo ||
    status ||
    paymentMethod ||
    type ||
    tags?.length ||
    category;

  const availableAccounts = useMemo(
    () => getAccountsListForSelect(),
    [getAccountsListForSelect]
  );

  const dateOptions = () => {
    const today = moment();

    return [
      {
        name: t("transactions_search.today"),
        dateFrom: today.format("yyyy-MM-DD"),
        dateTo: today.format("yyyy-MM-DD"),
        active:
          dateFrom === today.format("yyyy-MM-DD") &&
          dateTo === today.format("yyyy-MM-DD"),
      },
      {
        name: t("transactions_search.yesterday"),
        dateFrom: moment(today).subtract(1, "day").format("yyyy-MM-DD"),
        dateTo: moment(today).subtract(1, "day").format("yyyy-MM-DD"),
        active:
          dateFrom === moment(today).subtract(1, "day").format("yyyy-MM-DD") &&
          dateTo === moment(today).subtract(1, "day").format("yyyy-MM-DD"),
      },
      {
        name: t("transactions_search.last_7_days"),
        dateFrom: moment(today).subtract(1, "week").format("yyyy-MM-DD"),
        dateTo: moment(today).format("yyyy-MM-DD"),
        active:
          dateFrom === moment(today).subtract(1, "week").format("yyyy-MM-DD") &&
          dateTo === moment(today).format("yyyy-MM-DD"),
      },
      {
        name: t("transactions_search.last_15_days"),
        dateFrom: moment(today).subtract(2, "week").format("yyyy-MM-DD"),
        dateTo: moment(today).format("yyyy-MM-DD"),
        active:
          dateFrom === moment(today).subtract(2, "week").format("yyyy-MM-DD") &&
          dateTo === moment(today).format("yyyy-MM-DD"),
      },

      {
        name: t("transactions_search.current_month"),
        dateFrom: moment(today).startOf("month").format("yyyy-MM-DD"),
        dateTo: moment(today).endOf("month").format("yyyy-MM-DD"),
        active:
          dateFrom === moment(today).startOf("month").format("yyyy-MM-DD") &&
          dateTo === moment(today).endOf("month").format("yyyy-MM-DD"),
      },
      {
        name: t("transactions_search.last_month"),
        dateFrom: moment(today)
          .subtract(1, "month")
          .startOf("month")
          .format("yyyy-MM-DD"),
        dateTo: moment(today)
          .subtract(1, "month")
          .endOf("month")
          .format("yyyy-MM-DD"),
        active:
          dateFrom ===
            moment(today)
              .subtract(1, "month")
              .startOf("month")
              .format("yyyy-MM-DD") &&
          dateTo ===
            moment(today)
              .subtract(1, "month")
              .endOf("month")
              .format("yyyy-MM-DD"),
      },
      // {
      //   name: t("transactions_search.reset_dates"),
      //   dateFrom: "",
      //   dateTo: "",
      // },
    ];
  };

  useEffect(() => {
    setDateFrom(mainFilter.dateFrom);
    setDateTo(mainFilter.dateTo);
    setType(mainFilter.type);
    setStatus(mainFilter.status);
    setPaymentMethod(mainFilter.paymentMethod);
    setPayTo(mainFilter.payTo);
    setAccount(mainFilter.account);
    setTags(mainFilter.tags);
    setCategory(mainFilter.category);
  }, [mainFilter]);

  const { getUser } = usersStore();
  const [userEnabledPaymentMethods, setUserEnabledPaymentMethods] = useState<
    Array<string>
  >([]);

  const paymentMethods = () =>
    PAYMENT_METHODS()?.filter?.(({ id }) =>
      userEnabledPaymentMethods.includes(id)
    );

  useEffect(() => {
    const data = localStorage.getItem(APP_USER_ID_KEY);

    const nada = async () => {
      if (data) {
        const res = await getUser({ _id: data });

        if (res.success) {
          if (res?.data?.settings) {
            setUserEnabledPaymentMethods(
              res.data.settings.payment_methods || []
            );
          }
        } else {
          console.log("Error en los datos del usuario");
        }
      } else {
        console.log("No se puede");
      }
    };

    nada();
  }, []);

  return (
    <Modal width="w-full md:w-auto md:min-w-[65vw]" onEscape={() => onClose()}>
      <Modal.Header
        onClose={() => onClose()}
        title={t("transactions_search.title")}
        icon={<MdSearch size={28} />}
      />
      <Modal.Body className={clsx(["!max-h-[60vh]", "md:h-auto"])}>
        <div className={clsx(["flex", "flex-col", "gap-4", "pb-2"])}>
          <form
            id={TRANSACTIONS_SEARCH_FORM}
            className={clsx(["flex", "flex-col", "gap-4"])}
            onSubmit={(e) => {
              e?.preventDefault();
              onSubmit({
                dateFrom,
                dateTo,
                type,
                paymentMethod,
                account,
                payTo,
                status,
                tags,
                category,
              });
            }}
          >
            <div
              className={clsx(
                "flex",
                "flex-col",
                "lg:flex-row",
                "gap-2",
                "md:gap-2"
              )}
            >
              <div className={clsx(["md:pr-5", "flex", "flex-col", "gap-2"])}>
                <h3
                  className={clsx([
                    "text-sm",
                    "md:text-base",
                    "text-gray-700",
                    "font-medium",
                  ])}
                >
                  {t("transactions_search.predefined_dates")}
                </h3>
                <ul
                  className={clsx([
                    "md:pr-5",
                    "border-r-0",
                    "md:border-r-8",
                    "border-red-400",
                    "md:h-full",
                    "lg:min-w-[190px]",
                    "grid",
                    "grid-cols-2",
                    "lg:block",
                    "gap-1",
                    "md:gap-0",
                  ])}
                >
                  {dateOptions().map((option, index) => {
                    return (
                      <li
                        key={index}
                        className={clsx([
                          "whitespace-nowrap",
                          "py-2",
                          "md:py-1",
                          "cursor-pointer",
                          {
                            "hover:text-gray-800 md:hover:text-red-500":
                              !option.active,
                            "font-medium text-white md:text-red-500 !bg-gray-800 md:!bg-white":
                              option.active,
                          },
                          "flex",
                          "flex-row",
                          "gap-1",
                          "items-center",
                          "bg-gray-300",
                          "md:bg-transparent",
                          "rounded-md",
                          "md:rounded-none",
                          "px-2",
                          "md:px-0",
                          "text-sm",
                          "md:text-base",
                        ])}
                        onClick={() => {
                          const { dateFrom, dateTo, active } = option;

                          if (!active) {
                            setDateFrom(dateFrom);
                            setDateTo(dateTo);
                          } else {
                            setDateFrom("");
                            setDateTo("");
                          }
                        }}
                      >
                        {option.active ? (
                          <MdCheckCircle
                            className={clsx([
                              "md:flex",
                              "md:text-red-400",
                              "text-lime-400",
                            ])}
                            size={24}
                          />
                        ) : (
                          <>
                            {option?.dateFrom && option?.dateTo && (
                              <MdRadioButtonUnchecked
                                className={clsx(["md:flex", "text-gray-800"])}
                                size={24}
                              />
                            )}
                          </>
                        )}
                        {option.name}
                      </li>
                    );
                  })}
                </ul>
                <div
                  className={clsx([
                    "flex",
                    "items-center",
                    "text-gray-400",
                    "justify-center",
                    "md:pr-10",
                  ])}
                ></div>
              </div>
              <div className={clsx(["flex", "flex-col", "gap-4", "w-full"])}>
                {/* {isMobile && (
                <Select
                  options={availableAccounts}
                  label="Cuenta"
                  onChange={(e) => setAccount(e?.target?.value)}
                  value={account}
                  disabled={disableFields}
                  emptyOption={{
                    label: "-- Incluir todas --",
                    value: "",
                    selected: true,
                  }}
                  form={TRANSACTIONS_SEARCH_FORM}
                />
              )} */}
                <div className={clsx(["grid", "grid-cols-2", "gap-2"])}>
                  <TextInput
                    value={dateFrom}
                    onChange={(e: any) => setDateFrom(e?.target?.value)}
                    type="date"
                    label={t("transactions_search.from")}
                    disabled={disableFields}
                    form={TRANSACTIONS_SEARCH_FORM}
                  />
                  <TextInput
                    value={dateTo}
                    onChange={(e: any) => setDateTo(e?.target?.value)}
                    type="date"
                    label={t("transactions_search.to")}
                    disabled={disableFields}
                    form={TRANSACTIONS_SEARCH_FORM}
                  />
                </div>
                <div className={clsx(["grid", "grid-cols-2", "gap-2"])}>
                  <Select
                    disabled={disableFields}
                    options={[
                      {
                        value: EXPENSE_TYPE,
                        label: t("shared.expenses"),
                      },

                      {
                        value: INCOME_TYPE,
                        label: t("shared.income"),
                      },
                    ]}
                    label={t("transactions_search.type")}
                    onChange={(e) => setType(e?.target?.value)}
                    value={type}
                    emptyOption={{
                      label: `-- ${t("shared.select_include_all")} --`,
                      value: "",
                      selected: true,
                    }}
                    form={TRANSACTIONS_SEARCH_FORM}
                  />
                  <Select
                    options={allPayToList}
                    disabled={disableFields}
                    label={
                      type
                        ? type === INCOME_TYPE
                          ? "Recibido de"
                          : "Pagado a"
                        : t("transactions_search.origin_destination")
                    }
                    onChange={(e: any) => setPayTo(e?.target?.value)}
                    value={payTo}
                    emptyOption={{
                      label: `-- ${t("shared.select_include_all")} --`,
                      value: "",
                      selected: true,
                    }}
                    form={TRANSACTIONS_SEARCH_FORM}
                  />
                </div>
                <div className={clsx(["grid", "grid-cols-2", "gap-2"])}>
                  <Select
                    disabled={disableFields}
                    options={transformToSelectOptions({
                      data: TRANSACTION_STATUSES(),
                      labelField: "name",
                      valueField: "id",
                    })}
                    label={t("transactions_search.status")}
                    onChange={(e: any) => setStatus(e?.target?.value)}
                    value={status}
                    emptyOption={{
                      label: `-- ${t("shared.select_include_all")} --`,
                      value: "",
                      selected: true,
                    }}
                    form={TRANSACTIONS_SEARCH_FORM}
                  />
                  <Select
                    disabled={disableFields}
                    options={transformToSelectOptions({
                      data: paymentMethods(),
                      labelField: "name",
                      valueField: "id",
                    })}
                    label={t("transactions_search.payment_method")}
                    onChange={(e) => setPaymentMethod(e?.target?.value)}
                    value={paymentMethod}
                    emptyOption={{
                      label: `-- ${t("shared.select_include_all")} --`,
                      value: "",
                      selected: true,
                    }}
                    form={TRANSACTIONS_SEARCH_FORM}
                  />
                </div>
                <div className={clsx(["flex", "w-full"])}>
                  <Select
                    options={_.sortBy(
                      transformToSelectOptions({
                        data: allCategories,
                        labelField: "category",
                        valueField: "category",
                      }),
                      "label"
                    )}
                    disabled={disableFields}
                    label={t("shared.category")}
                    onChange={(e: any) => setCategory(e?.target?.value)}
                    value={category}
                    emptyOption={{
                      label: `-- ${t("shared.select_include_all")} --`,
                      value: "",
                      selected: true,
                    }}
                    form={TRANSACTIONS_SEARCH_FORM}
                  />
                </div>
              </div>
            </div>
          </form>
          <Tags
            readOnly
            onChange={(tags: Array<string>) => {
              setTags(tags);
            }}
            selected={tags}
            showCount
          />
        </div>
      </Modal.Body>
      <Modal.Footer
        actions={[
          {
            text: t("shared.close"),
            icon: <MdClose size={32} />,
            onClick: () => onClose(),
          },
        ]}
        leftContent={
          <>
            <ButtonGroup
              gap={2}
              align="left"
              buttons={[
                {
                  text: t("shared.search"),
                  onClick: () => {},
                  icon: <MdSearch size={24} />,
                  type: "submit",
                  form: TRANSACTIONS_SEARCH_FORM,
                  classes: BUTTON_STYLES.HEADING,
                },
                {
                  tooltip: "Limpiar campos",
                  onClick: () => {
                    setDateFrom("");
                    setDateTo("");
                    setPayTo("");
                    setType("");
                    setStatus("");
                    setPaymentMethod("");
                    setTags([]);
                    setCategory("");
                  },
                  icon: <MdDeleteSweep size={24} />,
                  form: TRANSACTIONS_SEARCH_FORM,
                  hide: !isCustomFilter,
                  // classes: BUTTON_STYLES.HEADING,
                },
              ]}
            />
          </>
        }
      ></Modal.Footer>
    </Modal>
  );
};

export default TransactionsSearchModal;
