import { create } from "zustand";
import ApiClient from "../helpers/api-client";
import { APP_USER_ID_KEY } from "../helpers/constants";

const DEFAULT_HEADERS = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const usersStore = (set: any, get: any) => ({
  currentUserData: null,
  getUser: async ({ _id }: { _id: string }) => {
    try {
      const response: any = await ApiClient.get(`/user`, {
        headers: DEFAULT_HEADERS,
        params: { _id },
      });

      const res = response.data;

      if (res?.success) {
        return {
          success: true,
          data: res?.data || null,
        };
      } else {
        console.log("Error");
      }

      return {
        success: false,
        data: null,
      };
    } catch (e) {
      return {
        success: false,
        message: e,
        data: null,
      };
    }
  },

  getUsers: async ({
    onResolved = () => {},
    onError = () => {},
  }: {
    onResolved: (e: any) => void;
    onError: (e: any) => void;
  }) => {
    try {
      await ApiClient.get(`/users`)
        .then((result) => {
          const data = result.data;
          onResolved(data);
        })
        .catch((e: any) => onError(e));
    } catch (e) {
      return {
        success: false,
        message: e,
        data: null,
      };
    }
  },

  getCurrentUser: async ({
    onResolved = () => {},
    onError = () => {},
  }: {
    onResolved?: (e: any) => void;
    onError?: (e: any) => void;
  }) => {
    try {
      const userId = localStorage.getItem(APP_USER_ID_KEY);

      const res = await get().getUser({ _id: userId });

      if (res.success) {
        set({ currentUserInfo: res.data });

        onResolved({ ...res.data });
      } else {
        set({ currentUserInfo: null });
        onResolved({ data: null });
      }
    } catch (e) {
      onError({ data: null });
    }
  },
});

export default create(usersStore);
