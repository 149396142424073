import { useEffect } from "react";
import { setMode } from "../../helpers/utils";

const ApplyTheme: React.FC = () => {
  useEffect(() => {
    setMode();
  }, []);

  return <></>;
};

export default ApplyTheme;
