import DataTable from "react-data-table-component";
import i18next from "i18next";
import moment from "moment";
import { formatNumber } from "../../helpers/utils";
import CellWithBackground from "../../pages/Transactions/BackgroundCell";
import { MdArrowDownward } from "react-icons/md";
import {
  EXPENSE_TYPE,
  INCOME_TYPE,
  PAGINATION_MIN,
  PAGINATION_ROWS_PER_PAGE_OPTIONS,
} from "../../helpers/constants";
import TransactionTypeCell from "../../pages/Transactions/TransactionTypeCell";
import useAccountsStore from "../../store/accountsStore";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface IMonthlySummaryTableaaa {
  transactions: any;
}

const MonthlySummaryTableaaa: React.FC<IMonthlySummaryTableaaa> = ({
  transactions,
}) => {
  const currentLanguage = i18next.language?.toLowerCase?.() || "en";
  const [income, expenses, balance] = transactions;
  const { t } = useTranslation();

  const COLUMNS = [
    {
      name: "MonthNumber",
      selector: (row: any) => row.month,
      omit: true,
    },
    {
      name: t("shared.month"),
      selector: (row: any) => row.month,
      sortable: true,
      cell: (row: any) => {
        return (
          <span className="capitalize">
            {moment(row.month, "M").locale(currentLanguage).format("MMMM")}
          </span>
        );
      },
    },
    {
      name: t("shared.income"),
      selector: (row: any) => row.income,
      sortable: true,
      cell: (row: any) => {
        return (
          <TransactionTypeCell currency={""} type={INCOME_TYPE}>
            {row.income}
          </TransactionTypeCell>
        );
      },
    },
    {
      name: t("shared.expenses"),
      selector: (row: any) => row.expense,
      sortable: true,
      cell: (row: any) => {
        return (
          <TransactionTypeCell currency={""} type={EXPENSE_TYPE}>
            {row.expense}
          </TransactionTypeCell>
        );
      },
    },
    {
      name: t("shared.balance"),
      selector: (row: any) => row.balance,
      sortable: true,
      cell: (row: any) => {
        const isRed = parseFloat(row.balance) < 0;

        return (
          <CellWithBackground
            background={isRed ? "rgb(239, 68, 68)" : "rgb(34, 197, 94)"}
            color={"#fff"}
            value={row.balance}
          />
        );
      },
    },
  ];

  const data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].reduce(
    (acc: any, current: any, index) => {
      const theExpense = expenses.data[index];
      const theIncome = income.data[index];
      const theBalance = income.data[index] + expenses.data[index];

      if (theExpense || theIncome) {
        return [
          ...acc,
          {
            month: index + 1,
            income: formatNumber(theIncome),
            expense: formatNumber(theExpense),
            balance: formatNumber(theBalance),
          },
        ];
      }

      return acc;
    },
    []
  );

  return (
    // <div
    //   className={clsx([
    //     "block",
    //     "w-full",
    //     "overflow-auto",
    //     "max-h-[250px]",
    //     "md:max-h-none",
    //   ])}
    // >
    <DataTable
      columns={COLUMNS}
      // striped
      data={data}
      sortIcon={<MdArrowDownward />}
      selectableRowsVisibleOnly
      paginationRowsPerPageOptions={PAGINATION_ROWS_PER_PAGE_OPTIONS}
      pagination={transactions?.length > PAGINATION_MIN}
      paginationPerPage={PAGINATION_MIN}
    />
    // </div>
  );
};

export default MonthlySummaryTableaaa;
