import DataTable from "react-data-table-component";
import {
  formatNumber,
  getCurrencyDataByCode,
  getTotalByTransactionType,
  getUniqueAccountsFromTransactions,
} from "../../helpers/utils";
import { useTranslation } from "react-i18next";
import CellWithBackground from "../../pages/Transactions/BackgroundCell";
import { getColorFromAccountSettings } from "../../helpers/accounts";
import { MdArrowDownward, MdOutlineFolder } from "react-icons/md";
import TransactionTypeCell from "../../pages/Transactions/TransactionTypeCell";
import {
  EXPENSE_TYPE,
  INCOME_TYPE,
  PAGINATION_MIN,
  PAGINATION_ROWS_PER_PAGE_OPTIONS,
} from "../../helpers/constants";
import useAccountsStore from "../../store/accountsStore";
import { useMemo } from "react";
import transactionsStore from "../../store/transactionsStore";
import clsx from "clsx";
import ButtonGroupCell from "../../pages/Transactions/ButtonGroupCell";
import { useNavigate } from "react-router-dom";

interface IAccountsSummaryTable {
  transactions: Array<any>;
  showIncome?: boolean;
  showExpenses?: boolean;
  showBalance?: boolean;
  showAccount?: boolean;
  currency: string;
}

const AccountsSummaryTable: React.FC<IAccountsSummaryTable> = ({
  transactions,
  showAccount = true,
  showIncome = true,
  showExpenses = true,
  showBalance = true,
  currency,
}) => {
  const { t } = useTranslation();
  const { filters } = transactionsStore();
  const {
    main: mainFilter = {
      dateFrom: "",
      dateTo: "",
      type: "",
      payTo: "",
      status: "",
      account: "",
      paymentMethod: "",
      tags: [],
    },
  } = filters || {};
  const { getAccountById } = useAccountsStore();
  const currencySymbol =
    getCurrencyDataByCode({
      code: currency,
    })?.symbol || "";

  const availableAccounts = useMemo(() => {
    return getUniqueAccountsFromTransactions({
      transactions,
    })?.map?.((accountId) => getAccountById(accountId));
  }, [transactions, getAccountById]);
  const navigate = useNavigate();

  const COLUMNS = [
    {
      name: "ID",
      selector: (row: any) => row.account,
      sortable: true,
      omit: true,
    },
    {
      cell: (row: any) => {
        const { background, color } = getColorFromAccountSettings(row.settings);

        return (
          <CellWithBackground
            background={background}
            color={color}
            value={row.name}
          />
        );
      },
      selector: (row: any) => row.name,
      name: t("shared.account"),
      sortable: true,
      // omit: !showAccount,
    },
    {
      name: t("summary.incomes"),
      selector: (row: any) => {
        return getTotalByTransactionType({
          transactions,
          account: row.account,
          type: INCOME_TYPE,
        });
      },
      omit: mainFilter?.type === EXPENSE_TYPE,
      sortable: true,
      right: true,
      cell: (row: any) => {
        return (
          <TransactionTypeCell currency={currencySymbol} type={INCOME_TYPE}>
            {formatNumber(
              getTotalByTransactionType({
                transactions,
                account: row.account,
                type: INCOME_TYPE,
              })
            )}
          </TransactionTypeCell>
        );
      },
    },
    {
      omit: mainFilter?.type === INCOME_TYPE,
      name: t("summary.expenses"),
      right: true,
      selector: (row: any) => {
        return getTotalByTransactionType({
          transactions,
          account: row.account,
          type: EXPENSE_TYPE,
        });
      },
      cell: (row: any) => {
        return (
          <TransactionTypeCell currency={currencySymbol} type={EXPENSE_TYPE}>
            {formatNumber(
              getTotalByTransactionType({
                transactions,
                account: row.account,
                type: EXPENSE_TYPE,
              })
            )}
          </TransactionTypeCell>
        );
      },
      sortable: true,
    },
    {
      name: t("summary.balance"),
      right: true,
      omit: !!mainFilter?.type,
      cell: (row: any) => {
        const expenses = getTotalByTransactionType({
          transactions,
          account: row.account,
          type: EXPENSE_TYPE,
        });
        const income = getTotalByTransactionType({
          transactions,
          account: row.account,
          type: INCOME_TYPE,
        });

        const isRed = Number(income) - Number(expenses) < 0;

        return (
          <CellWithBackground
            background={isRed ? "rgb(239, 68, 68)" : "rgb(34, 197, 94)"}
            color={"#fff"}
            value={`${currencySymbol}${formatNumber(
              Number(income) + Number(-expenses)
            )}`}
          />
        );
      },
      selector: (row: any) => {
        const expenses = getTotalByTransactionType({
          transactions,
          account: row.account,
          type: EXPENSE_TYPE,
        });
        const income = getTotalByTransactionType({
          transactions,
          account: row.account,
          type: INCOME_TYPE,
        });

        return Number(income) + Number(-expenses);
      },
      sortable: true,
    },
    {
      cell: (row: any) => {
        return (
          <div
            className={clsx(["flex", "w-full", "justify-end", "items-center"])}
          >
            <ButtonGroupCell
              buttons={[
                // {
                //   icon: <MdAttachment size={24} />,
                //   onClick: () => {},
                //   hide: !row?.attachments?.length,
                // },
                {
                  onClick: (e: any) => {
                    if (e?.metaKey || e?.altKey) {
                      window.open(
                        `/transactions/${mainFilter?.account || ""}`,
                        "_blank"
                      );
                    } else {
                      navigate(`/transactions/${mainFilter?.account || ""}`);
                    }
                  },
                  icon: <MdOutlineFolder size={24} />,
                },
              ]}
            />
          </div>
        );
      },
      selector: (row: any) => row,
      name: "",
      sortable: false,
      right: true,
    },
  ];

  return (
    <DataTable
      // striped
      columns={COLUMNS}
      data={availableAccounts}
      pagination={availableAccounts?.length > PAGINATION_MIN}
      highlightOnHover
      sortIcon={<MdArrowDownward />}
      selectableRowsVisibleOnly
      paginationRowsPerPageOptions={PAGINATION_ROWS_PER_PAGE_OPTIONS}
      paginationPerPage={PAGINATION_MIN}
    />
  );
};

export default AccountsSummaryTable;
