import clsx from "clsx";
import { ReactElement, useId, useState } from "react";
import { SketchPicker } from "react-color";

interface IColor {
  r: any;
  g: any;
  b: any;
  a: any;
}

interface IColorPicker {
  color?: IColor;
  onChange: (color: any) => void;
  label?: string | ReactElement;
  required?: boolean;
}

const ColorPicker: React.FC<IColorPicker> = ({
  color = { r: 0, g: 0, b: 0, a: 1 },
  onChange = () => {},
  label,
  required,
}) => {
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);

  return (
    <>
      {label && (
        <label
          className={clsx(["text-md", "text-gray-700", "font-medium"])}
          onClick={() => setShowColorPicker(!showColorPicker)}
        >
          {label}
          {required && <span className="ml-1 text-red-500">*</span>}
        </label>
      )}
      <div
        className={clsx([
          "flex",
          "w-full",
          "h-[40px] md:h-[46px]",
          "p-1",
          "rounded-md",
          "cursor-pointer",
          "border",
          "border-gray-300",
        ])}
        onClick={() => setShowColorPicker(!showColorPicker)}
      >
        <div
          className={clsx(["flex", "w-full", "h-full", "rounded-md"])}
          style={{
            backgroundColor: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
          }}
        ></div>
      </div>
      {showColorPicker && (
        <>
          <div
            className={clsx([
              "fixed",
              "top-0",
              "left-0",
              "right-0",
              "bottom-0",
              "bg-black/20",
            ])}
            onClick={() => setShowColorPicker(false)}
          ></div>
          <div
            className={clsx([
              "md:absolute",
              "z-10",
              "fixed",
              "left-1/2",
              "top-1/2",
              "-translate-x-1/2",
              "-translate-y-1/2",
              "md:left-auto",
              "md:top-auto",
              "md:translate-x-0",
              "md:translate-y-0",
            ])}
          >
            <SketchPicker
              width="270px"
              color={color}
              onChange={(data) => {
                onChange(data);
              }}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ColorPicker;
