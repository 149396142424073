import clsx from "clsx";
import { MdAdd, MdEdit } from "react-icons/md";
import LoadingWithText from "../LoadingWithText/LoadingWithText";
import BankCard from "../BankCard/BankCard";
import EmptyComponentState from "../EmptyComponentState/EmptyComponentState";
import SectionTitle from "../SectionTitle/SectionTitle";
import Slider from "react-slick";
import useModalsStore from "../../store/modalsStore";
import { useTranslation } from "react-i18next";
import useAccountsStore from "../../store/accountsStore";
import { getColorFromAccountSettings } from "../../helpers/accounts";
import SliderArrowRight from "../Slider/SliderArrowRight";
import SliderArrowLeft from "../Slider/SliderArrowLeft";
import _ from "lodash";

interface IAccountsSlider {
  onCardClick?: (data: any) => void;
  showTitle?: boolean;
  layout?: "vertical" | "horizontal";
  mobileSettings?: any;
  desktopSettings?: any;
  showActions?: boolean;
  onSlideChange?: (data: any) => void;
  activeAccount?: string;
}

const AccountsSlider: React.FC<IAccountsSlider> = ({
  onCardClick = () => {},
  showTitle = true,
  layout = "vertical",
  mobileSettings = {},
  desktopSettings = {},
  showActions = true,
  onSlideChange = () => {},
  activeAccount = null,
}) => {
  const { allAccounts, isLoading } = useAccountsStore();

  const { setShowCreateNewAccount, setShowEditAccount } = useModalsStore();

  let initialSlide = 0;
  if (activeAccount) {
    initialSlide = _.findIndex(allAccounts as any, { account: activeAccount });
  }

  const settings: any = {
    arrows: false,
    nextArrow: <SliderArrowRight />,
    prevArrow: <SliderArrowLeft />,
    beforeChange: (_prev: number, next: number) => {
      onSlideChange(allAccounts[next]);
    },
    initialSlide,
    responsive: [
      ...(allAccounts?.length === 1
        ? [
            {
              breakpoint: 100000,
              settings: "unslick" as "unslick",
            },
          ]
        : [
            {
              breakpoint: 100000,
              settings:
                layout === "vertical"
                  ? ("unslick" as "unslick")
                  : {
                      infinite: true,
                      speed: 500,
                      dots: true,
                      slidesToShow: 3,
                      slidesToScroll: 1,
                      ...desktopSettings,
                    },
            },
            {
              breakpoint: 768,
              settings: mobileSettings?.unslick
                ? ("unslick" as "unslick")
                : {
                    infinite: true,
                    speed: 500,
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    ...mobileSettings,
                    // dots: false,
                  },
            },
          ]),
    ],
  };

  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex-col gap-2 w-full">
        {isLoading ? (
          <div
            className={clsx([
              "flex",
              "w-full",
              "justify-center",
              "min-h-[124px]",
              "bg-white",
              "rounded-md",
              "border",
            ])}
          >
            <LoadingWithText
              text={t("shared.loading_accounts", { count: 1111 })}
            />
          </div>
        ) : (
          <>
            {allAccounts?.length ? (
              <>
                {showTitle && (
                  <SectionTitle title={t("transactions_page.accounts_title")} />
                )}
                <Slider
                  {...settings}
                  className={clsx([
                    {
                      "gap-2": true,
                      flex: true,
                      "flex-col": true,
                    },
                  ])}
                >
                  {allAccounts.map?.(
                    (
                      {
                        name,
                        bank,
                        account,
                        owner,
                        number,
                        currency,
                        settings,
                      },
                      index
                    ) => (
                      <div
                        // onDragOver={(e) => {
                        //   e?.preventDefault?.();
                        // }}
                        // onDrop={async (e) => {
                        //   const data = e?.dataTransfer.getData("transaction");
                        //   if (data) {
                        //     const parsedData = JSON.parse(data);

                        //     if (parsedData.contentType === "transaction") {
                        //       const { id, account: transactionAccount } =
                        //         parsedData;

                        //       if (transactionAccount !== account) {
                        //         try {
                        //           const result = await moveTransactions({
                        //             to: account,
                        //             transactions: [id],
                        //           });

                        //           if (result.success) {
                        //             toast.success(
                        //               "Transacción movida correctamente"
                        //             );
                        //             // onUpdateNeeded();
                        //           } else {
                        //             toast.error(
                        //               "Error moviendo la transacción"
                        //             );
                        //           }
                        //         } catch (e) {
                        //           toast.error("Error moviendo la transacción");
                        //         }
                        //       } else {
                        //         toast.info(
                        //           "La transacción ya está en esta cuenta"
                        //         );
                        //       }
                        //     }
                        //   }
                        // }}
                        // onClick={() => {
                        //   if (onCardClick) {
                        //     onCardClick(account);
                        //   } else {
                        //     if (theAccount === account) {
                        //       setFilter({
                        //         name: "main",
                        //         values: {
                        //           account: "",
                        //         },
                        //       });
                        //     } else {
                        //       setFilter({
                        //         name: "main",
                        //         values: {
                        //           account: account,
                        //         },
                        //       });
                        //     }
                        //   }
                        // }}
                        className={clsx(["cursor-pointer"])}
                        key={index}
                        onClick={() => {
                          onCardClick(account);
                        }}
                      >
                        <BankCard
                          name={name}
                          owner={owner}
                          bank={bank}
                          number={number}
                          customStyles={getColorFromAccountSettings(settings)}
                          className={clsx(["hover:opacity-100"])}
                          actions={
                            showActions
                              ? [
                                  {
                                    icon: <MdEdit size={24} />,
                                    onClick: (e: any) => {
                                      e?.preventDefault();
                                      setShowEditAccount(account);
                                    },
                                    tooltip: t("shared.edit"),
                                    classes: "p-1 rounded-sm border-none",
                                  },
                                ]
                              : []
                          }
                          account={account}
                          variant="custom"
                          currency={currency}
                        />
                      </div>
                    )
                  )}
                </Slider>
              </>
            ) : (
              <>
                {showTitle && (
                  <SectionTitle title={t("transactions_page.accounts_title")} />
                )}
                <EmptyComponentState
                  message={t("empty_states.add_account")}
                  buttons={[
                    {
                      kind: "primary",
                      icon: <MdAdd size={32} />,
                      text: t("shared.add"),
                      onClick: () => setShowCreateNewAccount(true),
                      isLoading: false,
                      disabled: false,
                    },
                  ]}
                />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default AccountsSlider;
