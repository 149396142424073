import {
  MdArrowDownward,
  MdArrowUpward,
  MdNoteAlt,
  MdViewSidebar,
  MdCompareArrows,
  MdCalendarMonth,
  MdPayment,
  MdCategory,
  MdAttachMoney,
  MdFilterAlt,
  MdFilterAltOff,
  MdSearch,
  MdEditCalendar,
  MdAccountBalance,
  MdCreditCard,
  MdCheck,
  MdPerson,
  MdCenterFocusWeak,
  MdBusiness,
  MdBusinessCenter,
  MdOutlineBusiness,
  MdPeopleAlt,
  MdPerson4,
  MdPerson3,
  MdPerson2,
  MdOutlinePersonSearch,
  MdMoreHoriz,
  MdPrint,
  MdDownload,
  MdFileOpen,
  MdRadioButtonUnchecked,
  MdRadioButtonChecked,
  MdFilter,
  MdCheckCircle,
} from "react-icons/md";
import PageTitle from "../../components/PageTitle/PageTitle";
import ButtonGroup from "../../components/ButtonGroup/ButtonGroup";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadingWithText from "../../components/LoadingWithText/LoadingWithText";
import TwoSidebars from "../../layouts/TwoSidebars/TwoSidebars";
import clsx from "clsx";
import userPreferences from "../../store/userPreferences";
import useAccountsStore from "../../store/accountsStore";
import {
  TRANSACTIONS_BY_CATEGORY_CHART,
  TRANSACTIONS_BY_PAYMENT_METHOD_CHART,
  TRANSACTIONS_BY_TYPE_CHART,
  TRANSACTIONS_PER_MONTH_CHART,
  TRANSACTIONS_PER_CLIENT_PROVIDER_CHART,
} from "./TransactionsCharts";
import {
  getCurrencyDataByCode,
  getUniqueYearsFromTransactions,
  rgbaObjectToString,
} from "../../helpers/utils";
import transactionsStore from "../../store/transactionsStore";
import LinksList from "../../components/LinksList/LinksList";
import { toast } from "react-toastify";
import useModalsStore from "../../store/modalsStore";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import Select from "../../components/Select/Select";
import ReportByTransactionType from "../../components/Reports/ReportByTransactionType";
import ReportByCategories from "../../components/Reports/ReportByCategories";
import ReportByMonth from "../../components/Reports/ReportByMonth";
import ReportByPaymentMethods from "../../components/Reports/ReportByPaymentMethods";
import ReportByClientProvider from "../../components/Reports/ReportByClientProvider";
import TransactionsSearchModal from "../../components/Modals/TransactionsSearchModal";
import InfoBlock from "../../components/InfoBlock/InfoBlock";
import EmptyComponentState from "../../components/EmptyComponentState/EmptyComponentState";
import AccountsSlider from "../../components/Accounts/AccountsSlider";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import TransactionsSummary from "./TransactionsSummary";
import {
  BUTTON_STYLES,
  EXPENSE_TYPE,
  INCOME_TYPE,
} from "../../helpers/constants";

const Reports: React.FC = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [selectedYear, setSelectedYear] = useState<string>("");
  const [selectedCurrency, setSelectedCurrency] = useState<string>("");
  const [availableYears, setAvailableYears] = useState<Array<any>>([]);
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [filteredResults, setFilteredResults] = useState<Array<any>>([]);
  const { searchTransactions } = transactionsStore();
  const { rightPanelIsVisible, setRightPanelIsVisible } = userPreferences();
  const { filters, setFilter, setLastUsedAccount } = transactionsStore();
  const [showSearchModal, setShowSearchModal] = useState<boolean>(false);

  const {
    main: mainFilter = {
      dateFrom: "",
      dateTo: "",
      type: "",
      payTo: "",
      status: "",
      account: "",
      paymentMethod: "",
      tags: [],
      category: "",
    },
  } = filters || {};

  const isCustomFilter = !!(
    mainFilter.dateFrom ||
    mainFilter.dateTo ||
    mainFilter.payTo ||
    mainFilter.status ||
    mainFilter.paymentMethod ||
    mainFilter.type ||
    mainFilter.tags?.length ||
    mainFilter?.category
  );

  const [isLoadingTransactions, setIsLoadingTransactions] =
    useState<boolean>(true);
  const navigate = useNavigate();
  const { account: accountParameter } = useParams();
  const { setShowCreateNewTask } = useModalsStore();
  const { t } = useTranslation();
  const [activeAccount, setActiveAccount] = useState<string>("");

  const [transactions, setTransactions] = useState<Array<any>>([]);

  const { getAccountById, getFirstAccountInTheList, allAccounts } =
    useAccountsStore();

  const preparedQueryParameters = useCallback(() => {
    const params = new URLSearchParams();
    const {
      dateFrom,
      dateTo,
      payTo,
      type,
      status,
      paymentMethod,
      tags,
      category,
    } = mainFilter;

    if (dateFrom) {
      params.append("dateFrom", dateFrom);
    }
    if (dateTo) {
      params.append("dateTo", dateTo);
    }
    if (payTo) {
      params.append("payTo", payTo);
    }
    if (type) {
      params.append("type", type);
    }

    if (category) {
      params.append("category", category);
    }

    params.append("account", accountParameter || "");

    if (status) {
      params.append("status", status);
    }
    if (paymentMethod) {
      params.append("paymentMethod", paymentMethod);
    }

    if (tags?.length) {
      params.append("tags", JSON.stringify(tags));
    }

    return params;
  }, [accountParameter, mainFilter]);

  const loadInvoices = useCallback(async () => {
    setIsLoadingTransactions(true);

    const params = preparedQueryParameters();

    await searchTransactions({
      params: params,
      onError: (e: any) => {
        toast.error("Error cargando tus transacciones", {
          toastId: "error_cargando",
        });
        console.log("Error", e);
        setIsLoadingTransactions(false);
      },
      onResolved: (res: any) => {
        setTransactions(res);
        setIsLoadingTransactions(false);
      },
    });
  }, [preparedQueryParameters, searchTransactions]);

  useEffect(() => {
    if (accountParameter) {
      setActiveAccount(accountParameter);
      setFilter({
        name: "main",
        values: {
          account: accountParameter,
        },
      });
    } else if (mainFilter?.account) {
      setActiveAccount(mainFilter?.account);
    } else {
      setActiveAccount("");
      setFilter({
        name: "main",
        values: {
          account: "",
        },
      });
    }
  }, [accountParameter, mainFilter?.account, setFilter]);

  useEffect(() => {
    loadInvoices();
  }, [mainFilter, filters, activeAccount, loadInvoices, accountParameter]);

  const getAccountName = (accountId: string) => {
    const account = getAccountById(accountId);

    return account?.name;
  };

  useEffect(() => {
    if (activeAccount) {
      setFilter({ name: "main", values: { account: activeAccount } });
    }
  }, [activeAccount, setFilter]);

  useEffect(() => {
    setActiveTab(activeTab);
  }, [activeTab, accountParameter]);

  useEffect(() => {
    setActiveAccount(accountParameter || "");
  }, [accountParameter]);

  useEffect(() => {
    setAvailableYears(
      getUniqueYearsFromTransactions({
        transactions,
      })?.map?.((year) => ({ value: `${year}`, label: `${year}` })) || []
    );
  }, [transactions]);

  const transformedTransactions = useMemo(() => {
    return (
      transactions?.map?.((transaction) => {
        const account = getAccountById(transaction.account);

        return {
          ...transaction,
          transactionCurrency: account?.currency,
          transactionYear: new Date(transaction.date).getFullYear(),
        };
      }) || []
    );
  }, [transactions, getAccountById]);

  useEffect(() => {
    setFilteredResults(
      transformedTransactions.filter?.((transaction) => {
        return (
          Number(transaction.transactionYear) === Number(selectedYear) &&
          selectedCurrency === transaction.transactionCurrency
        );
      })
    );
  }, [
    transactions,
    selectedCurrency,
    selectedYear,
    transformedTransactions,
    mainFilter,
  ]);

  const transactionsPerSelectedYear = useMemo(() => {
    if (selectedYear) {
      return transformedTransactions?.filter?.(
        (transaction) => Number(selectedYear) === transaction.transactionYear
      );
    }

    return [];
  }, [transformedTransactions, selectedYear]);

  const currenciesPerSelectedYear = useMemo(() => {
    if (selectedYear) {
      return Array.from(
        new Set(
          transactionsPerSelectedYear?.map(
            (transaction) => transaction.transactionCurrency
          )
        )
      );
    }

    return [];
  }, [selectedYear, transactionsPerSelectedYear]);

  useEffect(() => {
    if (currenciesPerSelectedYear?.length) {
      if (!currenciesPerSelectedYear.includes(selectedCurrency)) {
        const [defaultCurrency] = currenciesPerSelectedYear;
        setSelectedCurrency(defaultCurrency);
      }
    } else {
      setSelectedCurrency("");
    }
  }, [currenciesPerSelectedYear, selectedCurrency]);

  useEffect(() => {
    if (!selectedYear) {
      if (availableYears?.length) {
        const [defaultYear] = availableYears;
        setSelectedYear(defaultYear.value);
      } else {
        setSelectedYear("");
      }
    }
  }, [availableYears, selectedYear]);

  const uniqueYearsList = () =>
    getUniqueYearsFromTransactions({
      transactions,
    })?.map?.((year) => ({
      value: `${year}`,
      label: `${year}`,
    })) || [];

  const hasAccountSelected = activeAccount;

  return (
    <>
      <TwoSidebars
        rightClasses={clsx(["!flex-col-reverse", "md:!flex-col"])}
        customTopBackground={{
          bg: activeAccount ? "bg-gray-800" : "",
        }}
        right={
          <>
            {!isMobile && (
              <div className={clsx(["w-full", "flex", "flex-col", "gap-1"])}>
                <SectionTitle title={t("transactions_page.summary_title")} />
                <TransactionsSummary
                  onUpdateNeeded={() => {
                    // setRefreshKey((current) => current + 1);
                  }}
                  isLoading={isLoadingTransactions}
                  transactions={filteredResults}
                  onIncomeClick={() => {
                    const newValue =
                      mainFilter?.type === INCOME_TYPE ? "" : INCOME_TYPE;
                    setFilter({
                      name: "main",
                      values: {
                        type: newValue,
                      },
                    });
                  }}
                  onExpenseClick={() => {
                    const newValue =
                      mainFilter?.type === EXPENSE_TYPE ? "" : EXPENSE_TYPE;
                    setFilter({
                      name: "main",
                      values: {
                        type: newValue,
                      },
                    });
                  }}
                  onTotalClick={() => {
                    setFilter({
                      name: "main",
                      values: {
                        type: "",
                      },
                    });
                  }}
                  selected={mainFilter?.type}
                />
              </div>
            )}

            {!isMobile && (
              <AccountsSlider
                activeAccount={activeAccount}
                showActions={false}
                layout="vertical"
                showTitle={!isMobile}
                mobileSettings={{
                  dots: true,
                  arrows: false,
                  infinite: false,
                  // centerMode: true,
                  // centerPadding: "20px",
                }}
                onCardClick={({ account }) => {
                  navigate(`/reports/${account}`);
                }}
              />
            )}
          </>
        }
      >
        <div
          className={clsx([
            "flex",
            "flex-col",
            "w-full",
            "gap-2",
            "md:gap-2",
            "h-full",
          ])}
        >
          <PageTitle
            title={t("shared.reports")}
            subtitle={
              <>
                {activeAccount ? (
                  <div
                    className={clsx([
                      "bg-red-500",
                      "text-white",
                      "rounded-md md:rounded-md",
                      "px-2",
                    ])}
                    style={{
                      background:
                        getAccountById(activeAccount)?.settings?.bg || "",
                      color:
                        getAccountById(activeAccount)?.settings?.color || "",
                    }}
                  >
                    {getAccountName(activeAccount)}
                  </div>
                ) : (
                  t("transactions_page.subtitle_all")
                )}
              </>
            }
            link={{
              text: t("transactions_page.title"),
              icon: <MdCompareArrows />,
              link: "/accounts",
            }}
            titleLink={activeAccount ? "/reports" : ""}
            titleLinkClick={() => {
              setFilter({
                name: "main",
                values: { account: "" },
              });
              setActiveAccount("");
            }}
            rightSide={
              <>
                <ButtonGroup
                  buttons={[
                    {
                      icon: isCustomFilter ? (
                        <MdFilterAlt
                          className={clsx(["text-lime-400"])}
                          size={24}
                        />
                      ) : (
                        <MdSearch size={24} />
                      ),
                      onClick: () => {
                        setShowSearchModal(true);
                      },
                      tooltip: t("transactions_search.title"),
                      // kind:,
                      // hide: !isMobile,
                      classes: BUTTON_STYLES.HEADING,
                    },
                    {
                      icon: (
                        <MdArrowUpward
                          className={clsx(["text-red-400"])}
                          size={24}
                        />
                      ),
                      onClick: () => {
                        if (activeAccount) {
                          setLastUsedAccount({ account: activeAccount });
                        }
                        navigate("/transactions/expense");
                      },
                      hide: isMobile,
                      tooltip: t("shared.add_expense"),
                    },
                    {
                      icon: (
                        <MdArrowDownward
                          className={clsx(["text-green-400"])}
                          size={24}
                        />
                      ),
                      onClick: () => {
                        if (activeAccount) {
                          setLastUsedAccount({
                            account: activeAccount,
                          });
                        }
                        navigate("/transactions/income");
                      },
                      hide: isMobile,
                      tooltip: t("shared.add_income"),
                    },
                    {
                      icon: (
                        <MdNoteAlt
                          className={clsx(["text-yellow-400"])}
                          size={24}
                        />
                      ),
                      tooltip: t("shared.add_note"),
                      onClick: () => {
                        setShowCreateNewTask(true);
                      },
                      hide: isMobile,
                    },
                    {
                      icon: <MdAccountBalance size={24} />,
                      classes: BUTTON_STYLES.HEADING,
                      options: [
                        ...(allAccounts?.map?.((account: any) => ({
                          text: account.name,
                          onClick: () => {
                            navigate(`/reports/${account?.account}`);
                            setActiveAccount(account.account);
                            setFilter({
                              name: "main",
                              values: {
                                account: account.account,
                              },
                            });
                          },
                          icon: (
                            <div
                              className={clsx([
                                "flex",
                                "rounded-full",
                                "h-[20px]",
                                "w-[20px]",
                                "items-center",
                                "justify-center",
                              ])}
                              style={{
                                backgroundColor: rgbaObjectToString(
                                  account?.settings?.bg
                                ),
                              }}
                            >
                              {mainFilter?.account === account.account ? (
                                <MdCheck
                                  size={20}
                                  style={{
                                    color: rgbaObjectToString(
                                      account?.settings?.color
                                    ),
                                  }}
                                />
                              ) : (
                                <MdRadioButtonUnchecked
                                  size={20}
                                  style={{
                                    color: rgbaObjectToString(
                                      account?.settings?.color
                                    ),
                                  }}
                                />
                              )}
                            </div>
                          ),
                        })) || []),
                        {
                          icon: !activeAccount ? (
                            <MdCheck
                              size={20}
                              className={clsx(["text-gray-800"])}
                            />
                          ) : (
                            <MdRadioButtonUnchecked
                              className={clsx(["text-gray-800"])}
                              size={20}
                            />
                          ),
                          text: "All accounts",
                          onClick: () => {
                            navigate(`/reports`);
                            setActiveAccount("");
                            setFilter({
                              name: "main",
                              values: {
                                account: "",
                              },
                            });
                          },
                        },
                      ],
                      hide: !allAccounts?.length,
                    },

                    {
                      icon: <MdViewSidebar size={24} />,
                      tooltip: t("shared.lateral_panel"),
                      kind: rightPanelIsVisible ? "primary" : "secondary",
                      hide: isMobile,
                      onClick: () => {
                        setRightPanelIsVisible(!rightPanelIsVisible);
                      },
                    },
                    {
                      icon: <MdMoreHoriz size={24} />,
                      classes: BUTTON_STYLES.HEADING,
                      options: [
                        {
                          text: "Imprimir",
                          icon: <MdPrint size={24} />,
                          onClick: () => {
                            // createNewTransaction({ type: INCOME_TYPE });
                          },
                        },
                        {
                          text: "Descargar en PDF",
                          icon: <MdDownload size={24} />,
                          onClick: () => {
                            // createNewTransaction({ type: INCOME_TYPE });
                          },
                        },
                        {
                          text: "Exportar en CSV",
                          icon: <MdFileOpen size={24} />,
                          onClick: () => {
                            // createNewTransaction({ type: INCOME_TYPE });
                          },
                        },
                      ],
                    },
                  ]}
                  gap={2}
                />
              </>
            }
            isLoading={isLoadingTransactions}
          />

          {isMobile && activeAccount && (
            <div className={clsx(["pb-4"])}>
              <AccountsSlider
                key={activeAccount}
                activeAccount={activeAccount}
                showActions={false}
                layout="horizontal"
                showTitle={false}
                mobileSettings={{
                  dots: true,
                  arrows: false,
                  infinite: true,
                  // centerMode: true,
                  // centerPadding: "20px",
                }}
                desktopSettings={{
                  dots: true,
                }}
                onCardClick={(account) => {
                  navigate(`/reports/${account}`);
                }}
                onSlideChange={(account: any) => {
                  if (account) {
                    navigate(`/reports/${account?.account}`);
                    setFilter({
                      name: "main",
                      values: {
                        account: account.account,
                      },
                    });
                  } else {
                    navigate(`/reports`);
                    setFilter({
                      name: "main",
                      values: {
                        account: "",
                      },
                    });
                  }
                }}
              />
            </div>
          )}

          <LinksList
            links={[
              {
                text: t("shared.summary"),
                onClick: () => {
                  // navigate("/transactions/stats");

                  setActiveTab(TRANSACTIONS_BY_TYPE_CHART);
                },
                active: activeTab === TRANSACTIONS_BY_TYPE_CHART,
                icon: (
                  <>
                    <MdAttachMoney size={32} className="block md:hidden" />
                    <MdAttachMoney size={40} className="hidden md:block" />
                  </>
                ),
                isLoading:
                  isLoadingTransactions &&
                  activeTab === TRANSACTIONS_BY_TYPE_CHART,
              },
              {
                text: t("shared.monthly"),
                onClick: () => {
                  setActiveTab(TRANSACTIONS_PER_MONTH_CHART);
                },
                active: activeTab === TRANSACTIONS_PER_MONTH_CHART,
                icon: (
                  <>
                    <MdCalendarMonth size={32} className="block md:hidden" />
                    <MdCalendarMonth size={40} className="hidden md:block" />
                  </>
                ),
                isLoading:
                  isLoadingTransactions &&
                  activeTab === TRANSACTIONS_PER_MONTH_CHART,
              },
              {
                text: t("shared.categories"),
                onClick: () => {
                  setActiveTab(TRANSACTIONS_BY_CATEGORY_CHART);
                },
                active: activeTab === TRANSACTIONS_BY_CATEGORY_CHART,
                icon: (
                  <>
                    <MdCategory size={32} className="block md:hidden" />
                    <MdCategory size={40} className="hidden md:block" />
                  </>
                ),
                isLoading:
                  isLoadingTransactions &&
                  activeTab === TRANSACTIONS_BY_CATEGORY_CHART,
              },
              {
                text: t("shared.client_provider"),
                onClick: () => {
                  setActiveTab(TRANSACTIONS_PER_CLIENT_PROVIDER_CHART);
                },
                active: activeTab === TRANSACTIONS_PER_CLIENT_PROVIDER_CHART,
                icon: (
                  <>
                    <MdOutlinePersonSearch
                      size={32}
                      className="block md:hidden"
                    />
                    <MdPerson size={40} className="hidden md:block" />
                  </>
                ),
                isLoading:
                  isLoadingTransactions &&
                  activeTab === TRANSACTIONS_BY_PAYMENT_METHOD_CHART,
              },
              {
                text: t("shared.payment_methods"),
                onClick: () => {
                  setActiveTab(TRANSACTIONS_BY_PAYMENT_METHOD_CHART);
                },
                active: activeTab === TRANSACTIONS_BY_PAYMENT_METHOD_CHART,
                icon: (
                  <>
                    <MdPayment size={32} className="block md:hidden" />
                    <MdPayment size={40} className="hidden md:block" />
                  </>
                ),
                isLoading:
                  isLoadingTransactions &&
                  activeTab === TRANSACTIONS_BY_PAYMENT_METHOD_CHART,
              },
            ]}
          />

          {isCustomFilter && (
            <InfoBlock
              message={t("shared.showing_filtered_results")}
              buttons={[
                {
                  text: t("shared.remove_filter"),
                  icon: <MdFilterAltOff size={24} />,
                  onClick: () => {
                    setFilter({
                      name: "main",
                      values: {
                        dateFrom: "",
                        dateTo: "",
                        type: "",
                        payTo: "",
                        status: "",
                        paymentMethod: "",
                        tags: [],
                      },
                    });
                    navigate("/reports");
                  },
                },
              ]}
            />
          )}

          <div className={clsx(["w-full", "flex", "flex-col", "gap-6"])}>
            {[isLoadingTransactions, hasAccountSelected]?.includes(true) ? (
              <div className={clsx(["flex", "justify-start", "w-full"])}>
                <LoadingWithText
                  text={t("shared.loading_transactions", { count: 1111 })}
                />
              </div>
            ) : filteredResults?.length ? (
              <>
                {(uniqueYearsList()?.length > 1 ||
                  currenciesPerSelectedYear?.length > 1) && (
                  <div
                    className={clsx([
                      "w-full",
                      "grid",
                      `grid-cols-${
                        uniqueYearsList()?.length > 1 &&
                        currenciesPerSelectedYear?.length > 1
                          ? "2"
                          : "1"
                      }`,
                      "gap-4",
                    ])}
                  >
                    {uniqueYearsList()?.length > 1 && (
                      <Select
                        label={t("shared.year")}
                        options={uniqueYearsList()}
                        value={selectedYear}
                        onChange={(e: any) => setSelectedYear(e?.target?.value)}
                        emptyOption={{ show: false }}
                      />
                    )}
                    {currenciesPerSelectedYear?.length > 1 && (
                      <Select
                        options={currenciesPerSelectedYear?.map((currency) => ({
                          value: currency,
                          label:
                            getCurrencyDataByCode({ code: currency })?.name ||
                            "",
                        }))}
                        label={t("shared.currency")}
                        value={selectedCurrency}
                        emptyOption={{ show: false }}
                        onChange={(e: any) =>
                          setSelectedCurrency(e?.target?.value)
                        }
                      />
                    )}
                  </div>
                )}
                {selectedCurrency && selectedYear ? (
                  <>
                    {activeTab === TRANSACTIONS_BY_TYPE_CHART && (
                      <ReportByTransactionType
                        transactions={filteredResults}
                        currency={selectedCurrency}
                      />
                    )}

                    {activeTab === TRANSACTIONS_PER_MONTH_CHART && (
                      <ReportByMonth
                        currency={selectedCurrency}
                        transactions={filteredResults}
                      />
                    )}

                    {activeTab === TRANSACTIONS_BY_CATEGORY_CHART && (
                      <ReportByCategories
                        transactions={filteredResults}
                        currency={selectedCurrency}
                      />
                    )}

                    {activeTab === TRANSACTIONS_BY_PAYMENT_METHOD_CHART && (
                      <ReportByPaymentMethods
                        transactions={filteredResults}
                        currency={selectedCurrency}
                      />
                    )}

                    {activeTab === TRANSACTIONS_PER_CLIENT_PROVIDER_CHART && (
                      <ReportByClientProvider
                        transactions={filteredResults}
                        currency={selectedCurrency}
                      />
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                <div className={clsx(["flex", "w-full"])}>
                  {/* <InfoBlock
                    title="Sin resultados"
                    message="No hay datos para mostrar"
                    buttons={[]}
                  /> */}
                  <EmptyComponentState message="No hay datos para mostrar." />
                </div>
              </>
            )}
          </div>
        </div>
      </TwoSidebars>
      {showSearchModal && (
        <TransactionsSearchModal
          onClose={() => setShowSearchModal(false)}
          onSubmit={(filterOptions) => {
            setFilter({
              name: "main",
              values: {
                ...mainFilter,
                ...filterOptions,
              },
            });
            setShowSearchModal(false);
          }}
        />
      )}
    </>
  );
};

export default Reports;
