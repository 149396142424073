import { useState } from "react";
import clsx from "clsx";
import { MdCheck, MdClose, MdPassword } from "react-icons/md";

import { CONFIRM_DIALOG_ICON_SIZE } from "../../constants/icons";
import TextInput from "../TextInput/TextInput";
import Modal from "../Modal/Modal";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const FORM_ID = "update-password-form";

interface IChangePasswordModal {}

const ChangePasswordModal: React.FC<IChangePasswordModal> = () => {
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>("");
  const { t } = useTranslation();

  const location = useLocation();

  return (
    <>
      <div className="grid grid-cols-1 gap-4">
        <form
          className={clsx(["flex", "flex-col", "gap-6"])}
          onSubmit={(e: any) => {
            e?.preventDefault?.();
          }}
          id={FORM_ID}
        >
          <TextInput
            value={currentPassword}
            onChange={(e: any) => setCurrentPassword(e?.target?.value)}
            label={t("change_password_page.current_password_label")}
            placeholder={t("change_password_page.current_password_placeholder")}
            type="password"
            required
          />
          <TextInput
            value={newPassword}
            onChange={(e: any) => setNewPassword(e?.target?.value)}
            label={t("change_password_page.new_password_label")}
            placeholder={t("change_password_page.new_password_placeholder")}
            type="password"
            required
          />
          <TextInput
            value={newPasswordConfirm}
            onChange={(e: any) => setNewPasswordConfirm(e?.target?.value)}
            label={t("change_password_page.confirm_password_label")}
            placeholder={t("change_password_page.confirm_password_placeholder")}
            type="password"
            required
          />
        </form>
      </div>
    </>
  );
};

export default ChangePasswordModal;
