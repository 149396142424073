import clsx from "clsx";
import TwoSidebars from "../../layouts/TwoSidebars/TwoSidebars";
import { useNavigate } from "react-router-dom";
import { MdAdd, MdDelete } from "react-icons/md";
import ButtonGroup from "../../components/ButtonGroup/ButtonGroup";
import { useTranslation } from "react-i18next";
import TasksListPanel from "../../components/TasksListPanel/TasksListPanel";
import PageTitle from "../../components/PageTitle/PageTitle";
import useModalsStore from "../../store/modalsStore";
import { BUTTON_STYLES } from "../../helpers/constants";

const NotesPage: React.FC = () => {
  const { t } = useTranslation();
  const { setShowCreateNewTask } = useModalsStore();

  return (
    <TwoSidebars>
      <div
        className={clsx([
          "flex",
          "items-center",
          "justify-center",
          "w-full",
          "gap-4",
          "flex-col",
          "text-gray-500",
        ])}
      >
        <PageTitle
          title={t("notes.title")}
          subtitle={t("notes.notes_page_subtitle")}
          rightSide={
            <ButtonGroup
              align="right"
              gap={2}
              buttons={[
                {
                  icon: <MdAdd size={24} />,
                  tooltip: t("shared.add_note"),
                  onClick: () => {
                    setShowCreateNewTask(true);
                  },
                  classes: BUTTON_STYLES.HEADING,
                },
                {
                  icon: <MdDelete size={24} />,
                  onClick: () => {
                    setShowCreateNewTask(true);
                  },
                  classes: BUTTON_STYLES.HEADING,
                },
              ]}
            />
          }
        />
        <TasksListPanel />
      </div>
    </TwoSidebars>
  );
};

export default NotesPage;
