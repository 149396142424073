import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { lazy, Suspense } from "react";

import transactionsStore from "./store/transactionsStore";
import clsx from "clsx";
import LoadingWithText from "./components/LoadingWithText/LoadingWithText";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import LogoutPage from "./pages/Logout/Logout";
import ProtectedRoutes from "./components/ProtectedRoute/ProtectedRoute";
import CategoriesPage from "./pages/Categories/Categories";
import SettingsPage from "./pages/Settings/SettingsPage";
import { useTranslation } from "react-i18next";
import { EXPENSE_TYPE, INCOME_TYPE } from "./helpers/constants";
import ModalsLoader from "./components/Modals/ModalsLoader";
import PaymentMethodsPage from "./pages/PaymentMethods/PaymentMethods";
import ProfilePage from "./pages/Profile/ProfilePage";
import AccountsPage from "./pages/Accounts/AccountsPage";
import ChangePasswordPage from "./pages/Password/ChangePasswordPage";
import TransactionsSettingsPage from "./pages/Transactions/TransactionsSettingsPage";

import NotesPage from "./pages/Notes/NotesPage";
import LinksPage from "./pages/Links/LinksPage";

import Reports from "./pages/Transactions/Reports";
const NotFound404 = lazy(() => import("./pages/NotFound404/NotFound404"));
const RegisterTransactionPage = lazy(
  () => import("./pages/Transactions/RegisterTransaction")
);
const RegisterAccountPage = lazy(
  () => import("./pages/RegisterAccount/RegisterAccount")
);
const LoginPage = lazy(() => import("./pages/Login/Login"));

const ArchivePage = lazy(() => import("./pages/Dashboard/DashboardPage"));
const Transactions = lazy(() => import("./pages/Transactions/Transactions"));

export const LeRoutes: React.FC = () => {
  const { filters } = transactionsStore();
  const { t } = useTranslation();
  const { main: transactionsMainFilter } = filters;

  return (
    <Router>
      <ScrollToTop />
      <Suspense
        fallback={
          <div
            className={clsx([
              "flex",
              "h-screen",
              "w-full",
              "items-center",
              "justify-center",
            ])}
          >
            <LoadingWithText text={t("shared.loading")} />
          </div>
        }
      >
        <Routes>
          <Route element={<ProtectedRoutes />}>
            <Route path="/notes" element={<NotesPage />} />
            <Route path="/links" element={<LinksPage />} />

            <Route
              path="/reports"
              element={
                transactionsMainFilter.account ? (
                  <Navigate
                    replace
                    to={`/reports/${transactionsMainFilter.account}`}
                  />
                ) : (
                  <Reports />
                )
              }
            />
            <Route path="/reports/:account" element={<Reports />} />

            <Route path="/settings/" element={<SettingsPage />} />
            <Route path="/settings/accounts" element={<AccountsPage />} />
            <Route
              path="/settings/transactions"
              element={<TransactionsSettingsPage />}
            />

            <Route path="/settings/categories" element={<CategoriesPage />} />
            {/* <Route path="/settings/profile" element={<SettingsPage />} /> */}
            <Route path="/settings/password" element={<ChangePasswordPage />} />
            <Route
              path="/settings/payment-methods"
              element={<PaymentMethodsPage />}
            />
            <Route
              path="/transactions"
              element={
                transactionsMainFilter.account ? (
                  <Navigate
                    replace
                    to={`/transactions/${transactionsMainFilter.account}`}
                  />
                ) : (
                  <Transactions />
                )
              }
            />
            <Route path="/settings/profile" element={<ProfilePage />} />
            <Route path="/transactions/:account" element={<Transactions />} />

            <Route
              path="/transactions/expense/:invoice"
              element={
                <RegisterTransactionPage
                  transactionType={EXPENSE_TYPE}
                  editMode
                />
              }
            />

            <Route
              path="/transactions/expense"
              element={
                <RegisterTransactionPage transactionType={EXPENSE_TYPE} />
              }
            />

            <Route
              path="/transactions/income/:invoice"
              element={
                <RegisterTransactionPage
                  editMode
                  transactionType={INCOME_TYPE}
                />
              }
            />

            <Route
              path="/transactions/income"
              element={
                <RegisterTransactionPage transactionType={INCOME_TYPE} />
              }
            />

            <Route path="/dashboard" element={<ArchivePage />} />
            <Route path="/logout" element={<LogoutPage />} />
            <Route path="*" element={<NotFound404 />} />
          </Route>

          {/* <Route
          path="/transactions/:invoice"
          element={<RegisterTransactionPage editMode />}
        /> */}

          <Route path="/register" element={<RegisterAccountPage />} />
          <Route path="/" element={<LoginPage />} />
        </Routes>
      </Suspense>
      <ModalsLoader />
    </Router>
  );
};

export default LeRoutes;
