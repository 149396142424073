import {
  MdArrowBack,
  MdAttachMoney,
  MdCheck,
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdCompareArrows,
  MdOutlineShoppingCart,
} from "react-icons/md";
import PageTitle from "../../components/PageTitle/PageTitle";
import TwoSidebars from "../../layouts/TwoSidebars/TwoSidebars";
import ButtonGroup from "../../components/ButtonGroup/ButtonGroup";
import { useNavigate } from "react-router-dom";
import userPreferences from "../../store/userPreferences";
import clsx from "clsx";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import {
  API_ENDPOINT_UPDATE_PROFILE,
  APP_USER_ID_KEY,
  BUTTON_STYLES,
  PAYMENT_METHODS,
} from "../../helpers/constants";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import InfoBlock from "../../components/InfoBlock/InfoBlock";
import { useEffect, useState } from "react";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import usersStore from "../../store/usersStore";
import apiClient from "../../helpers/api-client";
import { toast } from "react-toastify";
import Button from "../../components/Button/Button";
import SettingsBlocks from "../../components/SettingsBlocks/SettingsBlocks";

const PaymentMethodsPage: React.FC = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [selectedMethods, setSelectedMethods] = useState<Array<string>>([]);
  const [isUpdatingProfile, setIsUpdatingProfile] = useState<boolean>(false);

  const { getUser } = usersStore();

  const isEnabled = (methodId: string) => {
    return !!selectedMethods.includes?.(methodId);
  };

  const updateProfile = () => {
    setIsUpdatingProfile(true);

    const formData = new FormData();

    formData.append("paymentMethods", JSON.stringify([...selectedMethods]));

    apiClient
      .put(API_ENDPOINT_UPDATE_PROFILE, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(async (res: any) => {
        toast.success("Métodos de pago actualizados");
        setShowConfirmDialog(false);
        // onUpdate();
      })
      .catch((e) => {
        toast.error("Error actualizando tus métodos de pago");
      })
      .finally(() => {
        setIsUpdatingProfile(false);
      });
  };

  const toggleMethod = (methodId: string) => {
    if (isEnabled(methodId)) {
      setSelectedMethods(
        selectedMethods.filter?.((method) => method !== methodId)
      );
    } else {
      setSelectedMethods([...selectedMethods, methodId]);
    }
  };

  useEffect(() => {
    const data = localStorage.getItem(APP_USER_ID_KEY);

    const nada = async () => {
      if (data) {
        const res = await getUser({ _id: data });

        if (res.success) {
          if (res?.data?.settings) {
            setSelectedMethods(res.data.settings.payment_methods);
          }
        } else {
          console.log("Error en los datos del usuario");
        }
      } else {
        console.log("No se puede");
      }
    };

    nada();
  }, []);

  return (
    <>
      <TwoSidebars
        right={
          <>
            {<SettingsBlocks layout="vertical" columns={isMobile ? 2 : 1} />}

            {/* <SettingsBlocks layout="vertical" /> */}
          </>
        }
      >
        <div className={clsx(["flex", "w-full", "flex-col", "gap-0"])}>
          <PageTitle
            title={t("settings.title")}
            subtitle={t("settings.payment_methods_title")}
            titleLink="/settings"
            isLoading={false}
            rightSide={
              <>
                <ButtonGroup
                  align="right"
                  gap={2}
                  buttons={[
                    {
                      classes: BUTTON_STYLES.HEADING,

                      icon: <MdArrowBack size={24} />,
                      tooltip: "Atras",
                      onClick: () => {
                        navigate("/settings");
                      },
                      hide: !isMobile,
                    },
                    {
                      icon: <MdOutlineShoppingCart size={24} />,
                      tooltip: "Añadir gasto",
                      onClick: () => {
                        navigate("/transactions/expense");
                      },
                      hide: isMobile,
                    },

                    {
                      icon: <MdAttachMoney size={24} />,
                      tooltip: "Añadir ingreso",
                      hide: isMobile,
                      onClick: () => {
                        navigate("/transactions/income");
                      },
                    },
                    {
                      icon: <MdCompareArrows size={24} />,
                      tooltip: "Transacciones",
                      onClick: () => {
                        navigate("/transactions");
                      },
                      hide: isMobile,
                    },
                  ]}
                />
              </>
            }
          />

          <div className={clsx(["flex", "flex-col", "w-full", "gap-4"])}>
            <SectionTitle title={t("payment_methods_page.table_title")} />
            <InfoBlock message={t("settings.payment_methods_note")} />
            <table className="max-w-full table-fixed">
              <thead
                className={clsx([
                  "border-b-4",
                  "border-gray-500",
                  "h-[50px]",
                  "bg-gray-100",
                ])}
              >
                <tr>
                  <th
                    className={clsx([
                      "font-medium",
                      "text-sm",
                      "text-left",
                      "px-3",
                      "uppercase",
                      "text-gray-800",
                      "whitespace-nowrap",
                    ])}
                  >
                    {t("payment_methods_page.name_column")}
                  </th>
                  <th
                    className={clsx([
                      "w-[80px]",
                      "font-medium",
                      "text-sm",
                      "text-center",
                      "px-3",
                      "uppercase",
                      "text-gray-800",
                      "whitespace-nowrap",
                    ])}
                  >
                    {t("payment_methods_page.active_column")}
                  </th>
                </tr>
              </thead>
              <tbody className={clsx(["text-base", "text-gray-900"])}>
                {PAYMENT_METHODS().map((method, index) => {
                  const isOdd = index % 2 === 0;

                  return (
                    <tr
                      key={index}
                      className={clsx([
                        "transition-all",
                        "border-b",
                        "border-b-primary/6",
                        "cursor-pointer",
                        "hover:text-red-600",
                        "hover:bg-gray-100",
                        {
                          "bg-white": isOdd && !isEnabled(method.id),
                          "bg-gray-50/30": !isOdd && !isEnabled(method.id),
                          "text-red-600": isEnabled(method.id),
                          "text-red-400 hover:bg-gray-100 bg-gray-100":
                            isEnabled(method.id),
                        },
                      ])}
                      onClick={() => toggleMethod(method.id)}
                    >
                      <td
                        className={clsx(["px-1", "whitespace-nowrap", "py-2"])}
                      >
                        <div>{method.name}</div>
                      </td>
                      <td
                        className={clsx(["px-1", "whitespace-nowrap", "py-2"])}
                      >
                        <div
                          className={clsx([
                            "flex",
                            "items-center",
                            "justify-center",
                          ])}
                        >
                          <Button
                            onClick={() => {}}
                            classes={clsx([
                              "!bg-transparent",
                              "border-0",
                              "rounded-none",
                              "!text-gray-900",
                            ])}
                            icon={
                              <>
                                {isEnabled(method.id) ? (
                                  <MdCheckBox
                                    size={32}
                                    className="text-red-400"
                                  />
                                ) : (
                                  <MdCheckBoxOutlineBlank size={32} />
                                )}
                              </>
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div
            className={clsx([
              "w-full",
              "flex",
              "justify-end",
              "bg-inherit",
              "md:py-3",
              "sticky",
              "bottom-20",
              "md:bottom-0",
            ])}
          >
            <ButtonGroup
              buttons={[
                {
                  text: t("shared.update"),
                  onClick: () => {
                    if (selectedMethods?.length) {
                      setShowConfirmDialog(true);
                    }
                  },
                  icon: <MdCheck size={32} />,
                  kind: "success",
                  isLoading: isUpdatingProfile,
                  disabled: isUpdatingProfile || !selectedMethods?.length,
                },
              ]}
            />
          </div>
        </div>
      </TwoSidebars>
      {showConfirmDialog && (
        <ConfirmDialog
          isLoading={isUpdatingProfile}
          onConfirm={async () => {
            updateProfile();
          }}
          onClose={() => setShowConfirmDialog(false)}
          description={t("dialogs.payment_methods.update_dialog_question")}
          title={t("dialogs.payment_methods.update_dialog_title")}
        />
      )}
    </>
  );
};

export default PaymentMethodsPage;
