import clsx from "clsx";
import { ReactElement, useId } from "react";
import { Tooltip } from "react-tooltip";

export interface IButton {
  type?: "button" | "reset" | "submit";
  onClick: (e: any) => void;
  kind?: "danger" | "primary" | "secondary" | "warning" | "success" | undefined;
  icon?: ReactElement;
  text?: string;
  disabled?: boolean;
  form?: string;
  tooltip?: string;
  active?: boolean;
  classes?: string;
  hide?: boolean;
  isLoading?: boolean;
  autoFocus?: boolean;
}

const Button: React.FC<IButton> = ({
  type = "button",
  onClick,
  kind = "secondary",
  text = null,
  icon,
  disabled = false,
  form = "",
  tooltip = "",
  active,
  classes = "",
  hide = false,
  isLoading = false,
  autoFocus = false,
}) => {
  const tooltipId = useId();

  const getClasses = () => {
    return {
      normal: [],
      active: [],
      disabled: [],
      hover: [],
    };
  };

  const kindClasses = () => {
    switch (kind) {
      case "secondary": {
        return [
          "bg-white",
          "text-gray-500",
          "border-gray-200",
          "hover:bg-gray-100",
          "hover:text-gray-500",
          "focus:bg-gray-500",
          "focus:text-white",
          "group-hover:bg-gray-500",
          "group-hover:text-white",
        ];
      }
      case "danger": {
        return [
          "bg-red-100 dark:bg-red-500",
          "text-red-500 dark:text-white",
          "border-red-200 dark:border-red-600",
          "hover:bg-red-500 dark:hover:bg-red-600",
          "hover:text-white dark:hover:text-white",
          "focus:bg-red-500 dark:focus:bg-red-600",
          "focus:text-white dark:focus:text-white",
        ];
      }
      case "success": {
        return [
          "bg-lime-400",
          "text-gray-800",
          "border-lime-500",
          "hover:bg-lime-500",
          "hover:text-gray-800",
          "focus:bg-lime-400",
          "focus:text-white",
          "active:bg-lime-400",
          "active:text-white",
        ];
      }
      case "warning": {
        return [
          "bg-yellow-100",
          "text-yellow-500",
          "border-yellow-200",
          "hover:bg-yellow-500",
          "hover:text-white",
          "focus:bg-yellow-500",
          "focus:text-white",
        ];
      }

      default: {
        return [
          "bg-secondary-100 dark:bg-gray-100",
          "text-secondary-500 dark:text-gray-500",
          "border-secondary-200 dark:border-gray-200",
          "hover:bg-secondary-500 dark:hover:bg-gray-500",
          "hover:text-white dark:hover:text-white",
          "focus:bg-secondary-500 dark:focus:bg-gray-500",
          "focus:text-white",
        ];
      }
    }
  };

  const activeClasses = () => {
    switch (kind) {
      case "secondary": {
        return ["bg-gray-500", "text-white"];
      }
      case "danger": {
        return ["bg-red-500", "text-white"];
      }
      case "success": {
        return ["bg-green-500", "text-white"];
      }
      case "warning": {
        return ["bg-yellow-500", "text-white"];
      }

      default: {
        return [];
      }
    }
  };

  if (hide) return null;

  return (
    <>
      <button
        disabled={disabled || isLoading}
        type={type}
        data-tooltip-id={tooltipId}
        data-tooltip-content={tooltip}
        data-tooltip-position-strategy="fixed"
        data-tooltip-float={true}
        data-tooltip-offset={20}
        onClick={onClick}
        autoFocus={autoFocus}
        className={clsx([
          "transition-colors",
          "inline-flex",
          "items-center",
          "gap-1",
          {
            "p-2 md:p-3": true,
            "h-[40px] md:h-[46px]": true,
            "min-w-[40px] md:min-w-[46px]": true,
          },
          "rounded-sm",
          "md:rounded-md",
          "border",
          "md:border",
          // "text-sm",
          ...kindClasses(),
          classes,
          active ? [...activeClasses()] : {},
        ])}
        {...(form ? { form } : {})}
      >
        {isLoading ? (
          <>
            <div
              className={clsx([
                "h-[32px]",
                "w-[32px]",
                "flex",
                "items-center",
                "justify-center",
              ])}
            >
              <div
                className={clsx([
                  "inline-block",
                  "h-[24px]",
                  "w-[24px]",
                  "animate-spin",
                  "rounded-full",
                  "border-4",
                  "border-solid",
                  "border-current",
                  "border-e-transparent",
                  "align-[-0.125em]",
                  "text-surface",
                  "motion-reduce:animate-[spin_1.5s_linear_infinite]",
                  "dark:text-red-400",
                  "text-sm",
                  "md:text-base",
                ])}
              />
            </div>
          </>
        ) : (
          <>{icon && <span>{icon}</span>}</>
        )}
        {text && (
          <span className=" capitalize text-sm md:text-base">{text}</span>
        )}
        <Tooltip id={tooltipId} />
      </button>
    </>
  );
};

export default Button;
