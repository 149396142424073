import { MdCheck, MdClose, MdFavorite, MdWarning } from "react-icons/md";
import Modal from "../Modal/Modal";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface IConfirmDialog {
  title: string;
  onClose: () => void;
  onConfirm: () => void;
  description: string;
  confirmKind?: string;
  isLoading?: boolean;
  icon?: ReactElement;
}

const ConfirmDialog: React.FC<IConfirmDialog> = ({
  title,
  onClose,
  onConfirm,
  description,
  confirmKind = "primary",
  isLoading = false,
  icon = <MdFavorite size={28} className="text-red-100" />,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      width="max-w-[700px] lg:min-w-[500px]"
      onEscape={() => {
        if (!isLoading) {
          onClose();
        }
      }}
    >
      <Modal.Header
        onClose={() => {
          if (!isLoading) {
            onClose();
          }
        }}
        title={title}
        // icon={
        //   confirmKind === "danger" && !icon ? (
        //     <MdWarning size={28} className="text-red-500" />
        //   ) : (
        //     icon
        //   )
        // }
      />
      <Modal.Body>
        <p className="text-sm md:text-base">{description}</p>
      </Modal.Body>
      <Modal.Footer
        actions={[
          {
            kind: "secondary",
            onClick: onClose,
            text: t("shared.cancel"),
            icon: <MdClose size={32} />,
            disabled: isLoading,
          },
          {
            kind: confirmKind,
            onClick: onConfirm,
            text: t("shared.confirm"),
            icon: <MdCheck size={32} />,
            isLoading,
            disabled: isLoading,
            autoFocus: true,
          },
        ]}
      />
    </Modal>
  );
};

export default ConfirmDialog;
