import {
  MdArrowDownward,
  MdArrowUpward,
  MdCompareArrows,
} from "react-icons/md";
import PageTitle from "../../components/PageTitle/PageTitle";
import TwoSidebars from "../../layouts/TwoSidebars/TwoSidebars";
import ButtonGroup from "../../components/ButtonGroup/ButtonGroup";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import i18n from "../../i18n";
import SettingsBlocks from "../../components/SettingsBlocks/SettingsBlocks";
import { useMediaQuery } from "react-responsive";

const SettingsPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  const currentLanguage = i18n.language?.toLowerCase?.() || "en";

  return (
    <>
      <TwoSidebars showClock={false}>
        <div className={clsx(["flex", "w-full", "flex-col", "gap-0"])}>
          <PageTitle
            title={t("settings.title")}
            subtitle={t("settings.subtitle")}
            isLoading={false}
            rightSide={
              <>
                {!isMobile && (
                  <ButtonGroup
                    align="right"
                    gap={2}
                    buttons={[
                      {
                        icon: (
                          <MdArrowUpward
                            className={clsx(["text-red-400"])}
                            size={24}
                          />
                        ),
                        tooltip: t("shared.add_expense"),
                        hide: isMobile,
                        onClick: () => {
                          navigate("/transactions/expense");
                        },
                      },

                      {
                        icon: (
                          <MdArrowDownward
                            className={clsx(["text-green-400"])}
                            size={24}
                          />
                        ),
                        tooltip: t("shared.add_income"),
                        hide: isMobile,
                        onClick: () => {
                          navigate("/transactions/income");
                        },
                      },
                      {
                        icon: (
                          <MdCompareArrows
                            className={clsx(["text-blue-400"])}
                            size={24}
                          />
                        ),
                        tooltip: t("shared.transactions"),
                        onClick: () => {
                          navigate("/transactions");
                        },
                      },
                    ]}
                  />
                )}
              </>
            }
          />
          <div className={clsx(["flex", "flex-col", "gap-6 md:gap-12"])}>
            <div className={clsx(["flex", "flex-col", "gap-6"])}>
              <SectionTitle title={t("settings.language")} />
              <div
                className={clsx([
                  "grid",
                  "lg:grid-cols-3",
                  "grid-cols-2",
                  "md:grid-cols-2",
                  "gap-2",
                ])}
              >
                {[
                  {
                    text: "English",
                    code: "en",
                    icon: (
                      <span className="text-2xl rounded-sm  md:text-3xl fi fi-us"></span>
                    ),
                    onClick: () => changeLanguage("en"),
                  },
                  {
                    text: "Español",
                    code: "es",
                    icon: (
                      <span className="text-2xl rounded-sm  md:text-3xl fi fi-es"></span>
                    ),
                    onClick: () => changeLanguage("es"),
                  },
                ].map(({ text, icon, onClick, code }, key) => (
                  <div
                    key={key}
                    className={clsx([
                      "transition-colors",
                      "flex",
                      "flex-row",
                      "lg:flex-row",
                      "items-center",
                      "gap-2",
                      "md:gap-2",
                      "shadow-sm",
                      "border",
                      "border-gray-100",
                      "hover:text-white",
                      "hover:bg-gray-800",
                      "hover:border-gray-200",
                      "py-2",
                      "px-2",
                      "md:px-3",
                      "md:py-4",
                      "rounded-md md:rounded-md",
                      "cursor-pointer",
                      {
                        "bg-white text-gray-600": code !== currentLanguage,
                        "bg-gray-800 text-white": code === currentLanguage,
                      },
                    ])}
                    onClick={() => {
                      if (onClick) {
                        onClick();
                      }
                    }}
                  >
                    <div>{icon}</div>
                    <div
                      className={clsx([
                        "flex",
                        "flex-col",
                        "items-center",
                        "lg:items-start",
                        "justify-center",
                        "lg:justify-start",
                      ])}
                    >
                      <div
                        className={clsx([
                          "md:font-medium",
                          "leading-none",
                          "md:leading-normal",
                          "w-full",
                          "text-center md:text-left",
                          "text-sm md:text-base",
                        ])}
                      >
                        {text}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <SettingsBlocks />
            <div className={clsx(["flex", "flex-col", "gap-6"])}>
              {/* <SectionTitle title={t("settings.config_options")} /> */}
            </div>
          </div>
        </div>
      </TwoSidebars>
      {/* <PaymentMethodsModal onClose={() => navigate("/settings")} /> */}
    </>
  );
};

export default SettingsPage;
