import { create } from "zustand";
import {
  API_ENDPOINT_ADD_INVOICE,
  API_ENDPOINT_ALL_INVOICES,
  API_ENDPOINT_CLONE_TRANSACTION,
  API_ENDPOINT_DELETE_INVOICE,
  API_ENDPOINT_UPDATE_INVOICE,
} from "../helpers/constants";
import moment from "moment";
import apiClient from "../helpers/api-client";
import { TRANSACTION_STATUS_PAID } from "../constants/transaction-status";
import { getUniquePaidToFromTransactions } from "../helpers/utils";

const transactionsStore = (set: any, get: any) => ({
  filters: {
    main: {
      dateFrom: "",
      dateTo: "",
      type: "",
      status: "",
      payTo: "",
      account: "",
      searchTerm: "",
      paymentMethod: "",
      tags: [],
      category: "",
    },
  },
  lastUsedAccount: "",
  statsChartIsActive: false,
  errorSearchingTransactions: false,
  isSearchingTransactions: false,
  tagsList: [],
  allPayToList: [],

  setFilter: ({ name, values }: { name: string; values: any }) => {
    const leFilters = {
      ...get().filters,
      [name]: {
        ...get().filters[name],
        ...values,
      },
    };
    set({ filters: leFilters });
  },

  setLastUsedAccount: ({ account }: { account: string }) => {
    set({ lastUsedAccount: account });
  },

  setStatsChartActive: ({ active }: { active: boolean }) => {
    set({ statsChartIsActive: active });
  },

  searchTransactions: async ({
    params = new URLSearchParams(),
    onResolved = () => {},
    onError = () => {},
  }: {
    params: URLSearchParams;
    onResolved: (e: any) => void;
    onError: (e: any) => void;
  }) => {
    // if (!['true', 'false'].includes(params.get('archived') || '')) {
    //   params.append('archived', 'false')
    // }

    apiClient
      .get(API_ENDPOINT_ALL_INVOICES, {
        params,
      })
      .then((data) => {
        onResolved(data.data);
      })
      .catch((e) => {
        onError(e);
      });
  },

  getLast7DaysTransactions: async ({
    onResolved = () => {},
    onError = () => {},
    params = new URLSearchParams(),
  }: {
    onResolved: (e: any) => void;
    onError: (e: any) => void;
    params: URLSearchParams;
  }) => {
    // const today = moment().add(1, "days");
    // const lastDay = today.format("YYYY-MM-DD");
    // const firstDay = today.subtract(8, "days").format("YYYY-MM-DD");

    await get().searchTransactions({
      params: new URLSearchParams({
        // dateFrom: firstDay,
        // dateTo: lastDay,
        // status: TRANSACTION_STATUS_PAID,
        account: params?.get("account") || "",
      }),
      onResolved,
      onError,
    });
  },

  populateTagsList: () => {
    get().searchTransactions({
      params: new URLSearchParams({}),
      onResolved: (data: any) => {
        if (data?.length) {
          const result = data?.reduce((acc: any, current: any) => {
            if (current.tags?.length) {
              return [...acc, ...current.tags];
            }

            return acc;
          }, []);

          set({ tagsList: Array.from(new Set(result)) });
        } else {
          set({ tagsList: [] });
        }
      },
      onError: () => {
        console.log("Error");
      },
    });
  },

  setAllPayToList: async () => {
    await get().searchTransactions({
      onResolved: (data: any) => {
        const unique = getUniquePaidToFromTransactions({ transactions: data })
          ?.sort?.()
          ?.map((value) => ({
            label: `${value}`?.trim?.(),
            value: `${value}`?.trim?.(),
          }));

        set({ allPayToList: unique });
      },
      onError: (e: any) => {
        console.log(e);
      },
    });
  },

  updateTransaction: async ({
    transactionId,
    params,
  }: {
    transactionId: string;
    params: any;
  }) => {
    return await apiClient
      .put(API_ENDPOINT_UPDATE_INVOICE, {
        invoice: transactionId,
        ...params,
      })
      .then((data) => {
        return {
          success: true,
          data,
        };
      })
      .catch((error) => {
        return {
          success: false,
          error,
        };
      });
  },

  updateTransactions: async ({
    transactionIds,
    params,
  }: {
    transactionIds: Array<string>;
    params: any;
  }) => {
    return await apiClient
      .put(API_ENDPOINT_UPDATE_INVOICE, {
        transactionIds: JSON.stringify(transactionIds),
        params,
      })
      .then((data) => {
        return {
          error: false,
          success: true,
          data,
        };
      })
      .catch((error) => {
        return {
          success: false,
          error,
          data: [],
        };
      });
  },

  moveTransactionsToAccount: async ({
    to,
    transactions,
  }: {
    to: string;
    transactions: Array<string>;
  }) => {
    let hasError = false;

    try {
      const result = await get().updateTransactions({
        transactionIds: transactions,
        params: {
          account: to,
        },
      });

      return {
        error: false,
        success: true,
        data: result,
      };
    } catch (_e) {
      hasError = true;
    }

    return {
      success: !hasError,
      data: [],
      error: hasError,
    };
  },

  // deleteTransactions: async ({
  //   transactionIds,
  // }: {
  //   transactionIds: Array<string>;
  // }) => {
  //   // setIsDeletingTransactions(true);

  //   apiClient
  //     .delete(`${API_ENDPOINT_DELETE_INVOICE}/${transactionIds}`)
  //     .then((res: any) => {});
  // },

  archiveTransactions: async ({
    transactionIds,
  }: {
    transactionIds: Array<string>;
  }) => {
    let hasError = false;

    try {
      const result = await get().updateTransactions({
        transactionIds,
        params: {
          archived: true,
        },
      });

      return {
        error: false,
        success: true,
        data: result,
      };
    } catch (_e) {
      hasError = true;
    }

    return {
      success: !hasError,
      data: [],
      error: hasError,
    };
  },

  unarchiveTransactions: async ({
    transactionIds,
  }: {
    transactionIds: Array<string>;
  }) => {
    let hasError = false;

    try {
      const result = await get().updateTransactions({
        transactionIds,
        params: {
          archived: false,
        },
      });

      return {
        error: false,
        success: true,
        data: result,
      };
    } catch (_e) {
      hasError = true;
    }

    return {
      success: !hasError,
      data: [],
      error: hasError,
    };
  },

  deleteTransactions: async ({
    transactionIds,
  }: {
    transactionIds: Array<string>;
  }) => {
    apiClient
      .delete(
        `${API_ENDPOINT_DELETE_INVOICE}/${JSON.stringify(transactionIds)}`
      )
      .then((res: any) => {});
  },

  saveTransaction: async ({
    transactionId,
    params,
  }: {
    transactionId: string;
    params: any;
  }) => {
    return await apiClient
      .post(API_ENDPOINT_ADD_INVOICE, {
        invoice: transactionId,
        ...params,
      })
      .then((data) => {
        return {
          error: false,
          success: true,
          data,
        };
      })
      .catch((error) => {
        return {
          success: false,
          error,
        };
      });
  },

  cloneTransaction: async ({
    transactionId,
    cloneId,
  }: {
    transactionId: string;
    cloneId: string;
  }) => {
    return await apiClient
      .post(API_ENDPOINT_CLONE_TRANSACTION, { transactionId, cloneId })
      .then((data) => {
        return {
          error: false,
          success: true,
          data,
        };
      })
      .catch((error) => {
        return {
          success: false,
          error,
        };
      });
  },
});

export default create(transactionsStore);
