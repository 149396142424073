import clsx from "clsx";
import TextInput from "../TextInput/TextInput";
import { useEffect, useState } from "react";
import Button from "../Button/Button";
import {
  MdAdd,
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdIndeterminateCheckBox,
  MdInfo,
} from "react-icons/md";
import { toast } from "react-toastify";
import transactionsStore from "../../store/transactionsStore";
import Badge from "../Badge/Badge";
import { text } from "stream/consumers";
import { useTranslation } from "react-i18next";

interface ITags {
  onChange: (tags: Array<string>) => void;
  selected: Array<string>;
  showCount?: boolean;
  readOnly?: boolean;
}

const FORM_ID = "tags-form";

const Tags: React.FC<ITags> = ({
  selected = [],
  showCount = false,
  onChange = () => {},
  readOnly = false,
}) => {
  const [newTags, setNewTags] = useState<Array<string>>([]);
  const { populateTagsList, tagsList } = transactionsStore();
  const { t } = useTranslation();

  useEffect(() => {
    populateTagsList();
  }, []);

  const [searchTerm, setSearchTerm] = useState<string>("");

  const isSelected = (tag: string) => {
    return !!selected?.find?.((selectedTag: any) => tag === selectedTag);
  };

  const allMixedTags = Array.from(
    new Set([...newTags, ...tagsList]?.sort?.() || [])
  )?.sort?.();

  const filteredTags = searchTerm?.trim?.()
    ? allMixedTags.filter?.((tag) =>
        tag
          ?.trim?.()
          .toLowerCase?.()
          .includes(searchTerm?.trim?.().toLowerCase?.())
      )
    : allMixedTags;

  return (
    <div className={clsx(["flex", "flex-col", "gap-4"])}>
      <form
        className={clsx(["flex", "flex-row", "gap-1", "items-end"])}
        onSubmit={(e: any) => {
          e?.preventDefault?.();
          e?.stopPropagation?.();
        }}
        id={FORM_ID}
      >
        <TextInput
          label={t("tags.title")}
          placeholder={"Selecciona una o varias etiquetas"}
          value={searchTerm}
          onChange={(e: any) => setSearchTerm(e?.target?.value)}
        />
        {!readOnly && (
          <div>
            <Button
              form={FORM_ID}
              icon={<MdAdd size={32} />}
              type="submit"
              onClick={() => {
                if (searchTerm?.trim?.()) {
                  if (!allMixedTags.includes(searchTerm?.trim?.())) {
                    setNewTags([...newTags, searchTerm?.trim?.()]);
                    onChange([...selected, searchTerm?.trim?.()]);
                  } else {
                    onChange([...selected, searchTerm?.trim?.()]);
                  }
                } else {
                  toast.warn("Digita el texto de la etiqueta");
                }
              }}
              tooltip={t("shared.add_tag")}
            />
          </div>
        )}
      </form>
      {filteredTags?.length ? (
        <div
          className={clsx([
            "resize-y",
            "grid",
            "grid-cols-2",
            "lg:grid-cols-3",
            "xl:grid-cols-4",
            "gap-1",
            "md:gap-2",
            // "h-[250px]",
            // "min-h-[250px]",
            "overflow-auto",
            // "border",
            "rounded-md",
            // "p-2",
            "text-sm",
            "md:text-base",
            "cursor-pointer",
          ])}
        >
          {filteredTags.map((tag: any, index: number) => {
            return (
              <div
                key={index}
                onClick={() => {
                  if (isSelected(tag)) {
                    onChange(
                      selected?.filter?.(
                        (selectedTag: string) => selectedTag !== tag
                      )
                    );
                  } else {
                    onChange([...selected, tag]);
                  }
                }}
                className={clsx(["flex", "gap-1", "flex-row"])}
              >
                {isSelected(tag) ? (
                  <MdCheckBox size={24} className="shrink-0 text-gray-800" />
                ) : (
                  <MdCheckBoxOutlineBlank size={24} className="shrink-0" />
                )}
                <span>{tag}</span>
              </div>
            );
          })}
        </div>
      ) : (
        <div
          className={clsx([
            "flex",
            "flex-row",
            "items-center",
            "text-sm",
            "gap-1",
            "md:text-base",
          ])}
        >
          {readOnly ? (
            <>
              <MdInfo size={16} />
              <p>
                La etiqueta{" "}
                <strong className="font-medium">
                  {searchTerm?.trim?.().toLowerCase?.()}
                </strong>{" "}
                no existe
              </p>
            </>
          ) : (
            <>
              <MdAdd size={16} />
              <p>
                Agrega{" "}
                <strong className="font-medium">
                  {searchTerm?.trim?.().toLowerCase?.()}
                </strong>{" "}
                a tus etiquetas
              </p>
            </>
          )}
        </div>
      )}
      {selected?.length > 0 && showCount && (
        <div
          className={clsx([
            "flex",
            "w-full",
            "flex-row",
            "justify-start",
            "items-center",
            "gap-1",
            "text-gray-500",
          ])}
        >
          <MdInfo size={24} />
          <p className="text-sm">
            {t("shared.selected", { count: selected.length })}
          </p>
        </div>
      )}
    </div>
  );
};

export default Tags;
