import React, { useEffect, useState } from "react";
import {
  MdAccountBalance,
  MdBarChart,
  MdDashboard,
  MdFullscreen,
  MdLink,
  MdLogout,
  MdManageAccounts,
  MdMenuOpen,
  MdNote,
  MdNoteAdd,
  MdNoteAlt,
  MdCompareArrows,
  MdSettings,
} from "react-icons/md";
import SidebarLogo from "../SidebarLogo/SidebarLogo";
import BlocksNavigation from "../Navigation/BlocksNavigation";
import userPreferences from "../../store/userPreferences";
import clsx from "clsx";
import {
  hasFullscreenSupport,
  isDarkMode,
  toggleFullscreen,
} from "../../helpers/utils";
import { APP_LOCAL_STORAGE_USER_DATA } from "../../helpers/constants";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import { CONFIRM_DIALOG_ICON_SIZE } from "../../constants/icons";
import ButtonGroup from "../ButtonGroup/ButtonGroup";
import ExpenserMobileTopLogo from "../../Logo/ExpenserMobileTopLogo";
import { useTranslation } from "react-i18next";
import usersStore from "../../store/usersStore";
import ButtonWithMenu from "../ButtonWithMenu/ButtonWithMenu";
import useModalsStore from "../../store/modalsStore";

const Sidebar: React.FC = () => {
  const userDataLocalStorage =
    localStorage?.getItem?.(APP_LOCAL_STORAGE_USER_DATA) || null;
  let localUserName = "";
  if (userDataLocalStorage) {
    const parsedUserData = JSON.parse(userDataLocalStorage);
    const [firstName] = parsedUserData.name?.split(" ");

    localUserName = firstName || "";
  }
  const {
    setShowCreateNewAccount,
    setShowCreateNewTask,
    setShowCreateNewLink,
  } = useModalsStore();

  const { navIsCollapsed, setNavIsCollapsed } = userPreferences();
  const iconSize = navIsCollapsed ? 48 : 32;
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const navigate = useNavigate();
  const [showLogoutDialog, setShowLogoutDialog] = useState<boolean>(false);
  const { getCurrentUser } = usersStore();
  const [userName, setUserName] = useState<string>(localUserName || "");
  const { setShowEditProfile } = useModalsStore();

  useEffect(() => {
    const nada = async () => {
      await getCurrentUser({
        onResolved: (res: any) => {
          if (res?.name) {
            const [firstName] = res.name?.split(" ");
            setUserName(firstName || "");
          }
        },
      });
    };

    nada();
  }, []);

  const toggleDarkMode = () => {
    if (isDarkMode()) {
      localStorage.removeItem("theme");
    } else {
      localStorage.setItem("theme", "dark");
    }
    window.location.reload();
  };

  const { t } = useTranslation();

  return (
    <>
      <ExpenserMobileTopLogo />
      <div
        className={clsx([
          "hidden",
          "md:flex",
          "flex-col",
          "md:w-full",
          "md:h-full",
          "md:justify-between",
          // "bg-primary dark:bg-gray-700",
          "bg-gray-800",
          "text-white",
          "text-md",
          "font-light",
          "gap-14",
          "py-2 md:py-4",
          "px-4",
          "justify-start",
          "md:border-r-4",
          "z-10",
          "shadow-md shadow-gray-100/80 md:shadow-none",
          {
            "items-center": navIsCollapsed,
            "md:px-5": !navIsCollapsed,
          },
          "z-50 fixed top-0 md:relative",
          "rounded-full md:rounded-none mt-4 md:mt-0",
          "left-4 right-4 md:left-auto md:right-auto",
        ])}
      >
        <div
          className={clsx([
            "flex",
            "flex-row",
            "md:flex-col",
            "gap-4",
            "md:gap-14",
            "w-full",
            "justify-between",
            "md:justify-normal",
            "md:overflow-hidden",
            "md:h-full",
            // "border-b-4 border-transparent md:border-b-0",
          ])}
        >
          <div className={clsx(["hidden", "md:flex", "justify-center"])}>
            <SidebarLogo rotate={navIsCollapsed} />
          </div>
          <div
            className={clsx([
              "flex",
              "max-h-full",
              "flex-col",
              "flex-grow",
              "md:overflow-auto",
              "h-full",
            ])}
          >
            <div
              className={clsx([
                "hidden",
                "md:flex",
                "flex-col",
                "h-full",
                "gap-14",
                "justify-between",
              ])}
            >
              <BlocksNavigation
                links={[
                  {
                    link: "/dashboard",
                    text: navIsCollapsed ? null : t("menu.home"),
                    icon: <MdDashboard size={iconSize} />,
                    tooltip: navIsCollapsed ? t("menu.home") : "",
                  },
                  {
                    link: "/transactions",
                    text: navIsCollapsed ? null : t("menu.transactions"),
                    icon: <MdCompareArrows size={iconSize} />,
                    tooltip: navIsCollapsed ? t("menu.transactions") : "",
                  },
                  {
                    link: "/reports",
                    text: navIsCollapsed ? null : t("menu.reports"),
                    icon: <MdBarChart size={iconSize} />,
                    tooltip: navIsCollapsed ? t("menu.reports") : "",
                  },
                  {
                    link: "/settings",
                    text: navIsCollapsed ? null : t("menu.settings"),
                    icon: <MdSettings size={iconSize} />,
                    tooltip: navIsCollapsed ? t("menu.settings") : "",
                  },
                  {
                    onClick: () => setShowLogoutDialog(true),
                    text: navIsCollapsed ? null : t("menu.signout"),
                    icon: (
                      <MdLogout
                        size={navIsCollapsed ? 24 : iconSize}
                        className={clsx([
                          {
                            "text-red-500": showLogoutDialog,
                          },
                        ])}
                      />
                    ),
                    tooltip: navIsCollapsed ? t("menu.signout") : "",
                    active: showLogoutDialog,
                  },
                ]}
              />
              <div className={clsx(["sticky", "bottom-28"])}>
                {/* <LanguageSwitcher /> */}
                <ButtonGroup
                  align={navIsCollapsed ? "center" : "left"}
                  buttons={[
                    // {
                    //   icon: isDarkMode() ? (
                    //     <MdPalette size={32} className="text-red-500" />
                    //   ) : (
                    //     <MdPalette size={32} className="text-primary" />
                    //   ),
                    //   onClick: () => toggleDarkMode(),
                    //   classes: clsx([
                    //     "border-0",
                    //     "p-1",
                    //     "rounded-md",

                    //     "dark:bg-gray-800 dark:hover:bg-gray-900",
                    //   ]),
                    //   hide: navIsCollapsed,
                    //   tooltip: "Alternar diseño",
                    // },
                    {
                      icon: <MdFullscreen size={32} />,
                      tooltip: t("menu.fullscreen"),
                      onClick: () => toggleFullscreen(),
                      hide: !hasFullscreenSupport() || navIsCollapsed,
                      active: isFullscreen,
                      classes: clsx([
                        "border-0",
                        "p-1",
                        "rounded-md",
                        "bg-[#212f3c] dark:hover:bg-gray-900",
                      ]),
                    },
                    {
                      onClick: () => setNavIsCollapsed(!navIsCollapsed),
                      icon: navIsCollapsed ? (
                        <MdMenuOpen size={32} />
                      ) : (
                        <MdMenuOpen
                          size={32}
                          className="text-primary dark:text-red-500"
                        />
                      ),
                      classes: clsx([
                        "border-0",
                        "p-1",
                        "rounded-md",
                        "dark:bg-gray-800 dark:hover:bg-gray-900",
                      ]),
                      tooltip: t("menu.menu"),
                    },
                  ]}
                  gap={2}
                />
              </div>
              {/* <SidebarClock big={!navIsCollapsed} /> */}
            </div>
            {/* <div className={clsx(["md:hidden", "justify-end", "flex"])}>
              Hola
            </div> */}
          </div>
        </div>
      </div>
      {showLogoutDialog && (
        <ConfirmDialog
          icon={<MdLogout size={CONFIRM_DIALOG_ICON_SIZE} />}
          description="¿Deseas cerrar tu sesión?"
          onClose={() => setShowLogoutDialog(false)}
          onConfirm={() => {
            navigate("/logout");
          }}
          title="Salir"
        />
      )}
    </>
  );
};

export default Sidebar;
