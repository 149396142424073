import React from "react";

import {
  MdAccountBox,
  MdArrowBack,
  MdAttachMoney,
  MdCheck,
  MdCompareArrows,
  MdOutlineShoppingCart,
  MdViewSidebar,
} from "react-icons/md";
import PageTitle from "../../components/PageTitle/PageTitle";
import TwoSidebars from "../../layouts/TwoSidebars/TwoSidebars";
import ButtonGroup from "../../components/ButtonGroup/ButtonGroup";
import { Link, useNavigate } from "react-router-dom";
import userPreferences from "../../store/userPreferences";
import clsx from "clsx";
import { useMediaQuery } from "react-responsive";
import InfoBlock from "../../components/InfoBlock/InfoBlock";
import TextInput from "../../components/TextInput/TextInput";
import Select from "../../components/Select/Select";
import { useTranslation } from "react-i18next";
import { useEffect, useId, useState } from "react";
import usersStore from "../../store/usersStore";
import { LazyLoadImage } from "react-lazy-load-image-component";
import LoadingWithText from "../../components/LoadingWithText/LoadingWithText";
import apiClient from "../../helpers/api-client";
import {
  API_ENDPOINT_UPDATE_PROFILE,
  APP_USER_ID_KEY,
  BUTTON_STYLES,
  FILE_STORAGE_SERVER_URL,
} from "../../helpers/constants";
import { toast } from "react-toastify";
import { COUNTRY_SELECT_OPTIONS } from "../../helpers/utils";
import SettingsBlocks from "../../components/SettingsBlocks/SettingsBlocks";
import ChangePasswordModal from "../../components/ChangePasswordModal/ChangePasswordModal";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import { CONFIRM_DIALOG_ICON_SIZE } from "../../constants/icons";
import SectionTitle from "../../components/SectionTitle/SectionTitle";

const FORM_ID = "update-profile";

const ChangePasswordPage: React.FC = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const { getUser } = usersStore();
  const [name, setName] = useState<string>("Cali");
  const [picture, setPicture] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [website, setWebsite] = useState<string>("");

  const [isUpdatingProfile, setIsUpdatingProfile] = useState<boolean>(false);
  const [file, setFile] = useState<any>(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const tooltipId = useId();
  const { t } = useTranslation();

  const { rightPanelIsVisible, setRightPanelIsVisible } = userPreferences();

  useEffect(() => {
    const data = localStorage.getItem(APP_USER_ID_KEY);

    const nada = async () => {
      if (data) {
        const res = await getUser({ _id: data });

        if (res.success) {
          setPicture(
            res?.data?.picture
              ? `${FILE_STORAGE_SERVER_URL}/${res?.data?.picture}`
              : ""
          );
          setName(res?.data?.name || "");
        } else {
          console.log("Error en los datos del usuario");
        }
      } else {
        console.log("No se puede");
      }
    };

    nada();
  }, []);

  const updateProfile = () => {
    setIsUpdatingProfile(true);

    const formData = new FormData();

    if (file) {
      formData.append("file", file);
    }

    formData.append("name", name);

    apiClient
      .put(API_ENDPOINT_UPDATE_PROFILE, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(async (res: any) => {
        toast.success("Perfil actualizado");
        setShowConfirmDialog(false);
      })
      .catch((e) => {
        toast.error("Error actualizando tu perfil");
      })
      .finally(() => {
        setIsUpdatingProfile(false);
      });
  };

  return (
    <>
      <TwoSidebars
        right={
          <>
            {<SettingsBlocks layout="vertical" columns={isMobile ? 2 : 1} />}

            {/* <TasksListPanel />
            <CustomLinks /> */}
          </>
        }
      >
        <div className={clsx(["flex", "w-full", "flex-col", "gap-0"])}>
          <PageTitle
            title={t("settings.title")}
            subtitle={t("password_page.title")}
            titleLink="/settings"
            isLoading={false}
            rightSide={
              <>
                <ButtonGroup
                  align="right"
                  gap={2}
                  buttons={[
                    {
                      icon: <MdArrowBack size={24} />,
                      classes: BUTTON_STYLES.HEADING,

                      tooltip: "Atras",
                      onClick: () => {
                        navigate("/settings");
                      },
                      hide: !isMobile,
                    },
                    {
                      icon: <MdOutlineShoppingCart size={24} />,
                      tooltip: "Añadir gasto",
                      onClick: () => {
                        navigate("/transactions/expense");
                      },
                      hide: isMobile,
                    },

                    {
                      icon: <MdAttachMoney size={24} />,
                      tooltip: "Añadir ingreso",
                      hide: isMobile,
                      onClick: () => {
                        navigate("/transactions/income");
                      },
                    },
                    {
                      icon: <MdCompareArrows size={24} />,
                      tooltip: "Transacciones",
                      onClick: () => {
                        navigate("/transactions");
                      },
                      hide: isMobile,
                    },

                    {
                      icon: <MdViewSidebar size={24} />,
                      tooltip: "Panel lateral",
                      kind: rightPanelIsVisible ? "primary" : "secondary",
                      hide: isMobile,
                      onClick: () => {
                        setRightPanelIsVisible(!rightPanelIsVisible);
                      },
                    },
                  ]}
                />
              </>
            }
          />
          <div className={clsx(["flex", "gap-4", "flex-col"])}>
            <SectionTitle title={t("profile_page.change_password_title")} />
            <ChangePasswordModal />
            <div
              className={clsx([
                "w-full",
                "flex",
                "justify-end",
                "bg-inherit",
                "md:py-3",
              ])}
            >
              <ButtonGroup
                buttons={[
                  {
                    text: t("shared.update"),
                    onClick: () => {
                      setShowConfirmDialog(true);
                    },
                    icon: <MdCheck size={32} />,
                    kind: "success",
                    isLoading: isUpdatingProfile,
                    disabled: isUpdatingProfile,
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </TwoSidebars>
      {showConfirmDialog && (
        <ConfirmDialog
          icon={<MdAccountBox size={CONFIRM_DIALOG_ICON_SIZE} />}
          title={t("dialogs.profile.update_dialog_title")}
          description={t("dialogs.profile.update_dialog_question")}
          onClose={() => {
            setShowConfirmDialog(false);
          }}
          confirmKind="success"
          onConfirm={() => {
            updateProfile();
          }}
          isLoading={isUpdatingProfile}
        />
      )}
    </>
  );
};

export default ChangePasswordPage;
