import { create } from "zustand";
import { API_ENDPOINT_ALL_INVOICES } from "../helpers/constants";
import apiClient from "../helpers/api-client";

const invoicesStore = (set: any, get: any) => ({
  payToList: [],
  categoriesList: [],
  errorSearchingTransactions: false,
  isSearchingTransactions: false,
  isLoadingPayToList: false,
  errorLoadingPayToList: false,
  searchInvoices: async ({
    params = "",
    onResolved = () => {},
    onError = () => {},
  }: {
    params: string;
    onResolved: (e: any) => void;
    onError: (e: any) => void;
  }) => {
    set({ errorSearchingTransactions: false, isSearchingTransactions: true });

    try {
      apiClient
        .get(API_ENDPOINT_ALL_INVOICES, {
          params,
        })
        .then((result) => {
          set({ isSearchingTransactions: false });
          onResolved(result);
        })
        .catch((e: any) => {
          set({
            errorSearchingTransactions: true,
            isSearchingTransactions: false,
          });
          onError(e);
        });
    } catch (_e) {
      set({
        errorSearchingTransactions: true,
        isSearchingTransactions: false,
      });
    }
  },

  getAllInvoices: async ({
    onResolved = () => {},
    onError = () => {},
  }: {
    onResolved: (e: any) => void;
    onError: (e: any) => void;
  }) => {
    await get().searchInvoices({
      params: {},
      onResolved: (data: any) => {
        onResolved(data.data);
      },
      onError: (e: any) => {
        onError(e);
      },
    });
  },

  populatePayToList: async () => {
    set({ isLoadingPayToList: true, errorLoadingPayToList: false });
    await get().searchInvoices({
      onResolved: (res: any) => {
        const data = res.data;

        const unique = Array.from(
          new Set(data?.map?.(({ payTo }: { payTo: string }) => payTo) || [])
        )
          ?.sort?.()
          ?.map((value) => ({
            label: `${value}`?.trim?.(),
            value: `${value}`?.trim?.(),
          }));

        set({ isLoadingPayToList: false });
        set({ payToList: unique });
      },
      onError: (e: any) => {
        set({ isLoadingPayToList: false, errorLoadingPayToList: true });

        console.log("Error", e);
      },
    });
  },

  populateCategories: async () => {
    await get().searchInvoices({
      onResolved: (res: any) => {
        const data = res.data;
        const allDetails = data?.map?.(
          ({ details, category }: { details: any; category: any }) => {
            if (details?.length) {
              return details?.map?.(
                ({ category }: { category: string }) => category
              );
            } else {
              return category ? [category] : [""];
            }
          }
        );
        const detailsSet = new Set([]);

        allDetails?.forEach?.((val: any) => {
          val?.forEach((cat: any) => {
            if (cat) {
              detailsSet.add(cat?.trim() as never);
            }
          });
        });

        set({ categoriesList: Array.from(detailsSet)?.sort?.() });
      },
      onError: (e: any) => {
        console.log("Error", e);
      },
    });
  },
});

export default create(invoicesStore);
