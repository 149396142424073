import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import ExpenserTriangle from "../components/ExpenserTriangle/ExpenserTriangle";
import ButtonWithMenu from "../components/ButtonWithMenu/ButtonWithMenu";
import {
  MdAccountBalance,
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdHistory,
  MdLink,
  MdLogoDev,
  MdLogout,
  MdManageAccounts,
  MdNote,
  MdNoteAdd,
  MdNoteAlt,
  MdNotes,
  MdOutlineCheckBoxOutlineBlank,
  MdOutlineStrikethroughS,
  MdRefresh,
  MdSettings,
  MdStrikethroughS,
  MdTask,
  MdTaskAlt,
  MdToggleOff,
  MdToggleOn,
  MdViewArray,
} from "react-icons/md";
import { useTranslation } from "react-i18next";
import useModalsStore from "../store/modalsStore";
import useHistoryStore from "../store/historyStore";

import { APP_LOCAL_STORAGE_USER_DATA } from "../helpers/constants";
import Button from "../components/Button/Button";
import { classNames } from "@react-pdf-viewer/core";

const ExpenserMobileTopLogo: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userName = () => {
    const userDataLocalStorage =
      localStorage?.getItem?.(APP_LOCAL_STORAGE_USER_DATA) || null;
    if (userDataLocalStorage) {
      const parsedUserData = JSON.parse(userDataLocalStorage);
      const [firstName] = parsedUserData.name?.split(" ");

      return firstName || "";
    }

    return "";
  };

  const { historyIsEnabled, enableHistory } = useHistoryStore();

  const {
    setShowCreateNewAccount,
    setShowCreateNewTask,
    setShowDeleteHistoryConfirm,
  } = useModalsStore();

  return (
    <div
      className={clsx([
        "fixed",
        "md:hidden",
        "top-0",
        "left-0",
        "right-0",
        "z-40",
        "h-[41px]",
        "bg-[#212f3c]",
        "flex",
        "w-full",
        "items-center",
        "justify-center",
        "px-4",
        "shadow-sm",
      ])}
    >
      <div
        className={clsx([
          "flex",
          "flex-row",
          "gap-2",
          "w-full",
          "rounded-md",
          "justify-between",
          // "px-4",
          "py-2",
          // "shadow-md",
          "mt-0",
        ])}
      >
        <Link
          to="/dashboard"
          className={clsx([
            "flex",
            "flex-row",
            "items-center",
            "md:justify-end",
            "gap-1",
          ])}
        >
          <ExpenserTriangle size={22} className={clsx(["text-red-600"])} />
          <h1 className={clsx(["text-xl", "font-medium", "text-white"])}>
            eXpenser
            <span className={clsx(["text-xs", "uppercase", "text-red-600"])}>
              .net
            </span>
          </h1>
        </Link>
        <div className={clsx(["flex", "flex-row", "gap-2", "items-center"])}>
          <Button
            onClick={() => {
              setShowCreateNewTask(true);
            }}
            icon={<MdNoteAlt size={24} />}
            classes={clsx([
              "py-0",
              "rounded-none",
              "!bg-transparent",
              "!text-yellow-400",
              "border-0",
            ])}
          />
          <ButtonWithMenu
            classes={clsx([
              "py-0",
              "px-2",
              "py-1",
              "border-0",
              "stext-sm",
              // "!text-white",
              "hover:!text-gray-300",
              "!text-gray-200",
              "!bg-transparent",
            ])}
            text={userName()}
            icon={<MdManageAccounts size={24} />}
            options={[
              {
                text: t("menu.settings"),
                onClick: () => {
                  navigate("/settings");
                },
                icon: <MdSettings size={24} className="text-blue-600" />,
              },
              {
                text: t("shared.notes"),
                onClick: () => {
                  navigate("/notes");
                },
                icon: <MdNoteAlt size={24} className="text-yellow-400" />,
              },
              {
                text: t("shared.my_links"),
                onClick: () => {
                  navigate("/links");
                },
                icon: <MdLink size={24} className="text-orange-400" />,
              },

              {
                text: "Habilitar historial",
                onClick: () => enableHistory(!historyIsEnabled),
                icon: historyIsEnabled ? (
                  <MdCheckBox className="text-green-400" size={24} />
                ) : (
                  <MdCheckBoxOutlineBlank
                    className="text-green-400"
                    size={24}
                  />
                ),
              },

              {
                text: t("menu.signout"),
                onClick: () => {
                  navigate("/logout");
                },
                icon: <MdLogout size={24} className="text-purple-600" />,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default ExpenserMobileTopLogo;
