import { useEffect, useState } from "react";
import clsx from "clsx";
import { MdCategory, MdCheck } from "react-icons/md";
import { SketchPicker } from "react-color";

import SectionTitle from "../SectionTitle/SectionTitle";
import TextInput from "../TextInput/TextInput";
import ButtonGroup from "../ButtonGroup/ButtonGroup";
import categoriesStore from "../../store/categoriesStore";
import { toast } from "react-toastify";
import IconSelector from "../IconSelector/IconSelector";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import { CategoryIcon } from "../../pages/Categories/Categories";
import { CONFIRM_DIALOG_ICON_SIZE, UPDATE_ICON } from "../../constants/icons";
import { useTranslation } from "react-i18next";

interface ICategoryEditPanel {
  category: any;
  onChange: () => void;
}

const CategoryEditPanel: React.FC<ICategoryEditPanel> = ({
  onChange,
  category: categoryData,
}) => {
  const { addCategory } = categoriesStore();
  const [textColor, setTextColor] = useState<string>("#ffffff");
  const [bgColor, setBgColor] = useState<string>("#ffffff");
  const [icon, setIcon] = useState<string>("");
  const [categoryName, setCategoryName] = useState<string>("");
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [showUpdateDialog, setShowUpdateDialog] = useState<boolean>(false);
  const [iconSearchTerm, setIconSearchTerm] = useState<string>("");
  const { t } = useTranslation();

  useEffect(() => {
    if (categoryData?.category) {
      const { color, bg, category, icon } = categoryData;

      setTextColor(color || "#ffffff");
      setBgColor(bg || "#ffffff");
      setCategoryName(category || "");
      setIcon(icon);
    } else {
      setTextColor("#ffffff");
      setBgColor("#ffffff");
      setCategoryName(categoryData || "");
      setIcon("MdCategory");
    }
  }, [categoryData]);

  return (
    <>
      <div className="grid grid-cols-1 gap-4">
        <SectionTitle title={t("categories_page.customize")} />
        <div className={clsx(["flex", "flex-col", "gap-4"])}>
          <div
            className={clsx([
              "flex",
              "w-full",
              "gap-1",
              "bg-white",
              "py-0 md:p-0",
            ])}
          >
            <div className={clsx(["bg-checkers", "w-full", "rounded-lg"])}>
              <div
                className={clsx([
                  "flex",
                  "w-full",
                  "flex-col",
                  "items-center",
                  "justify-start",
                  "p-4",
                  "md:py-10 md:p-6",
                  "cursor-pointer",
                  "rounded-lg",
                  "transition-all",
                  "gap-2",
                ])}
                style={{
                  backgroundColor: bgColor || "red",
                  color: textColor || "white",
                }}
              >
                {!icon ? (
                  <>
                    <MdCategory size={48} />
                  </>
                ) : (
                  <CategoryIcon name={icon} />
                )}
                <div className="text-center capitalize">{categoryName}</div>
              </div>
            </div>
          </div>
          <form className={clsx(["flex", "flex-col", "gap-4", "w-full"])}>
            <TextInput
              placeholder={t("categories_page.category_placeholder")}
              onChange={(e: any) => setCategoryName(e?.target?.value)}
              value={categoryName}
              label={t("categories_page.category_label")}
              required
            />
            <details
              // open
              className={clsx(["bg-white", "p-4", "rounded-lg"])}
              style={{
                background: bgColor,
              }}
            >
              <summary
                className={clsx(["text-md", "text-gray-700"])}
                style={{ color: textColor }}
              >
                {t("categories_page.bg_color_label")}
              </summary>
              <div className="py-2">
                <SketchPicker
                  onChange={(color: any) => {
                    const { r, g, b, a } = color.rgb;
                    const rgba = `rgba(${r},${g},${b},${a})`;

                    setBgColor(rgba);
                  }}
                  color={bgColor || ""}
                  width="full"
                />
              </div>
            </details>
            <details
              // open
              className={clsx(["bg-white", "p-4", "rounded-lg"])}
              style={{
                background: bgColor,
              }}
            >
              <summary
                className={clsx(["text-md", "text-gray-700"])}
                style={{ color: textColor }}
              >
                {t("categories_page.text_color_label")}
              </summary>
              <div className="py-2">
                <SketchPicker
                  onChange={(color: any) => {
                    const { r, g, b, a } = color.rgb;
                    const rgba = `rgba(${r},${g},${b},${a})`;

                    setTextColor(rgba);
                  }}
                  color={textColor}
                  width="full"
                />
              </div>
            </details>
          </form>
          <details
            // open
            className={clsx(["bg-white", "p-4", "rounded-lg"])}
            style={{
              background: bgColor,
            }}
          >
            <summary
              className={clsx(["text-md", "text-gray-700"])}
              style={{ color: textColor }}
            >
              {t("categories_page.icon_label")}
            </summary>
            <div
              className={clsx([
                "my-2",
                "py-2",
                "flex",
                "flex-col",
                "gap-4",
                "bg-white",
                "p-3",
                "rounded-lg",
              ])}
            >
              <TextInput
                value={iconSearchTerm}
                onChange={(e: any) => setIconSearchTerm(e?.target?.value)}
                placeholder={t("categories_page.search_icon_placeholder")}
                disabled={false}
              />
              <IconSelector
                onClick={(icon) => setIcon(icon)}
                background={bgColor}
                selected={icon}
                color={textColor || ""}
              />
            </div>
          </details>
          <div
            className={clsx([
              "w-full",
              "flex",
              "justify-end",
              "bg-inherit",
              "md:py-3",
              "sticky",
              "bottom-20",
              "md:bottom-0",
            ])}
          >
            <ButtonGroup
              buttons={[
                {
                  text: categoryData ? t("shared.update") : t("shared.save"),
                  icon: <MdCheck size={32} />,
                  kind: "success",
                  onClick: async () => {
                    setShowUpdateDialog(true);
                  },
                  disabled: isUpdating,
                  isLoading: isUpdating,
                },
              ]}
            />
          </div>
        </div>
      </div>
      {showUpdateDialog && (
        <ConfirmDialog
          title={t("dialogs.categories.update_dialog_title")}
          description={t("dialogs.categories.update_dialog_question")}
          onClose={() => {
            setShowUpdateDialog(false);
          }}
          isLoading={isUpdating}
          confirmKind="success"
          onConfirm={async () => {
            setIsUpdating(true);
            await addCategory({
              onResolved: (res: any) => {
                toast.success(t("categories_page.category_updated_message"));
                onChange();
                setIsUpdating(false);
                setShowUpdateDialog(false);
              },
              onError: (err: any) => {
                toast.error(t("categories_page.category_update_error_message"));
                setIsUpdating(false);
              },
              params: {
                category: categoryName,
                color: textColor,
                bg: bgColor,
                icon,
                originalCategory: categoryData?.category || categoryData,
              },
            });
          }}
          icon={<UPDATE_ICON size={CONFIRM_DIALOG_ICON_SIZE} />}
        />
      )}
    </>
  );
};

export default CategoryEditPanel;
