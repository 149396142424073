import moment from "moment";
import {
  FILE_TYPE_IMG,
  FILE_TYPE_PDF,
  FILE_TYPE_XML,
  PAYMENT_METHODS,
} from "./constants";
import JSON_CURRENCY_INFO from "../json/curriencies.json";
import JSON_COUNTRY_INFO from "../json/countries.json";
import _ from "lodash";

export const formatNumber = (value: string | number) => {
  return new Intl.NumberFormat()?.format?.(Number(value)) || value;
};

export const getRandomColor = (alpha = 1) => {
  const [r, g, b, a] = [
    Math.floor(Math.random() * 256),
    Math.floor(Math.random() * 256),
    Math.floor(Math.random() * 256),
    alpha,
  ];

  return `rgba(${r}, ${g}, ${b}, ${a})`;
};

export const transformToSelectOptions = ({
  data,
  labelField,
  valueField,
}: {
  data: Array<any>;
  labelField: string;
  valueField: string;
}) => {
  if (data?.length) {
    return data.map((option) => ({
      value: option[valueField],
      label: option[labelField],
    }));
  }

  return [];
};

export const findInArrayOfObjects = ({
  data,
  fieldToCompare,
  valueToFind,
}: {
  data: Array<any>;
  fieldToCompare: string;
  valueToFind: string;
}) => {
  return data?.find?.((row) => row[fieldToCompare] === valueToFind) || null;
};

export const existsInArrayOfObjects = ({
  data,
  fieldToCompare,
  valueToFind,
}: {
  data: Array<any>;
  fieldToCompare: string;
  valueToFind: string;
}) => {
  if (data?.length) {
    const exists = data.find?.(
      (row: any) => row?.[fieldToCompare] === valueToFind
    );

    return !!exists;
  }

  return false;
};

export const uniqueList = ({
  data,
  referenceField,
}: {
  data: Array<any>;
  referenceField: string;
}) => {
  if (data?.length) {
    const res: Array<any> = [];

    data.forEach?.((row: any) => {
      if (
        !existsInArrayOfObjects({
          data: res,
          fieldToCompare: referenceField,
          valueToFind: row[referenceField],
        })
      ) {
        res.push(row);
      }
    });
  }

  return [];
};

export const getDaysBetweenDates = function ({
  startDate,
  endDate,
}: {
  startDate: any;
  endDate: any;
}) {
  var dates = [];

  var currDate = moment(startDate).startOf("day");
  var lastDate = moment(endDate).startOf("day");

  while (currDate.add(1, "days").diff(lastDate) < 0) {
    dates.push(currDate.clone().toDate());
  }

  return dates;
};

export const getDomainName = (url: string) => {
  try {
    const { hostname } = new URL(url);

    if (hostname) {
      if (hostname.toLowerCase().startsWith("www.")) {
        return hostname.replace("wwww.", "");
      }

      return hostname;
    }

    return null;
  } catch (_e) {
    return null;
  }
};

export const isFullscreen = document?.fullscreenElement;

export const toggleFullscreen = () => {
  let elem: any = document.documentElement;
  const isFullscreen = document?.fullscreenElement;

  if (isFullscreen) {
    document.exitFullscreen();
  } else {
    elem
      .requestFullscreen({ navigationUI: "show" })
      .then(() => {})
      .catch((err: any) => {
        alert(
          `An error occurred while trying to switch into fullscreen mode: ${err.message} (${err.name})`
        );
      });
  }
};

export const hasFullscreenSupport = () => {
  return !!document?.fullscreenEnabled;
};

export const getFileTypeByExtension = (filename: string) => {
  const theFilename = `${filename}`.toLowerCase?.();

  if (
    theFilename.endsWith(".jpg") ||
    theFilename.endsWith(".png") ||
    theFilename.endsWith(".jpeg") ||
    theFilename.endsWith(".webp")
  ) {
    return FILE_TYPE_IMG;
  }

  if (theFilename.endsWith(".xml")) {
    return FILE_TYPE_XML;
  }

  if (theFilename.endsWith(".pdf")) {
    return FILE_TYPE_PDF;
  }
};

export const CURRENCY_SELECT_OPTIONS = _.sortBy(
  transformToSelectOptions({
    data: JSON_CURRENCY_INFO,
    labelField: "name",
    valueField: "code",
  }),
  ["label"]
);

export const COUNTRY_SELECT_OPTIONS = _.sortBy(
  transformToSelectOptions({
    data: JSON_COUNTRY_INFO,
    labelField: "name",
    valueField: "code",
  }),
  ["label"]
);

export const getCurrencyDataByCode = ({ code }: { code: string }) => {
  return JSON_CURRENCY_INFO.find(({ code: theCode }) => theCode === code);
};

export const isDarkMode = () => {
  localStorage.setItem("theme", "dark");

  return localStorage?.theme === "dark";
};

export const setMode = () => {
  if (isDarkMode()) {
    document.documentElement.classList.add("dark");
  } else {
    document.documentElement.classList.remove("dark");
  }

  var link = document.createElement("meta");
  link.setAttribute("name", "theme-color");
  if (isDarkMode()) {
    link.content = "#4b5563";
  } else {
    link.content = "red";
  }
  document.getElementsByTagName("head")[0].appendChild(link);
};

export const getTotalByTransactionType = ({
  type,
  account,
  transactions,
}: {
  type: "INCOME" | "EXPENSE";
  account?: string;
  transactions: Array<any>;
}) => {
  let total = 0;

  transactions
    ?.filter?.(
      ({ type: documentType, account: transactionAccount }) =>
        documentType === type &&
        (account ? transactionAccount === account : true)
    )
    ?.forEach?.(({ total: nada = 0 }) => {
      total += Number(nada);
    });

  return total;
};

export const getTotalByCategory = ({
  type,
  account,
  transactions,
  category,
}: {
  type: "INCOME" | "EXPENSE";
  account?: string;
  transactions: Array<any>;
  category: string;
}) => {
  let total = 0;

  transactions
    ?.filter?.(
      ({
        type: documentType,
        account: transactionAccount,
        category: transactionCategory,
      }) =>
        documentType === type &&
        (account ? transactionAccount === account : true) &&
        category === transactionCategory
    )
    ?.forEach?.(({ total: nada = 0 }) => {
      total += Number(nada);
    });

  return total;
};

export const getTotalByPaymentMethod = ({
  type,
  account,
  transactions,
  paymentMethod,
}: {
  type: "INCOME" | "EXPENSE";
  account?: string;
  transactions: Array<any>;
  paymentMethod: string;
}) => {
  let total = 0;

  transactions
    ?.filter?.(
      ({
        type: documentType,
        account: transactionAccount,
        paymentMethod: transactionMethod,
      }) =>
        documentType === type &&
        (account ? transactionAccount === account : true) &&
        (paymentMethod ? transactionMethod === paymentMethod : true)
    )
    ?.forEach?.(({ total: nada = 0 }) => {
      total += Number(nada);
    });

  return total;
};

export const getTotalByPayTo = ({
  type,
  account,
  transactions,
  payTo,
}: {
  type: "INCOME" | "EXPENSE";
  account?: string;
  transactions: Array<any>;
  payTo: string;
}) => {
  let total = 0;

  transactions
    ?.filter?.(
      ({
        type: documentType,
        account: transactionAccount,
        payTo: transactionPayTo,
      }) =>
        documentType === type &&
        (account ? transactionAccount === account : true) &&
        (payTo ? transactionPayTo === payTo : true)
    )
    ?.forEach?.(({ total: nada = 0 }) => {
      total += Number(nada);
    });

  return total;
};

export const getTotalByMonth = ({
  type,
  account,
  transactions,
  month,
}: {
  type: "INCOME" | "EXPENSE";
  account?: string;
  transactions: Array<any>;
  month: number;
}) => {
  let total = 0;

  transactions
    ?.filter?.(
      ({
        type: documentType,
        account: transactionAccount,
        date: transactionDate,
      }) =>
        documentType === type &&
        (account ? transactionAccount === account : true) &&
        (month ? month === new Date(transactionDate).getMonth() + 1 : true)
    )
    ?.forEach?.(({ total: nada = 0 }) => {
      total += Number(nada);
    });

  return total;
};

export const getUniqueAccountsFromTransactions = ({
  transactions,
}: {
  transactions: Array<any>;
}) =>
  Array.from(
    new Set(transactions?.map?.(({ account }) => account) || [])
  )?.filter?.((account) => account);

export const rgbaObjectToString = ({
  r,
  g,
  b,
  a,
}: {
  r: any;
  g: any;
  b: any;
  a: any;
}) => {
  return `rgba(${r}, ${g}, ${b}, ${a})`;
};

export const getUniqueYearsFromTransactions = ({
  transactions,
}: {
  transactions: Array<any>;
}) => {
  return _.sortBy(
    Array.from(
      new Set(
        transactions?.map?.((yearDate) =>
          new Date(yearDate.date).getFullYear()
        ) || []
      )
    ),
    ["value"]
  );
};

export const getUniqueCategoriesFromTransactions = ({
  transactions,
}: {
  transactions: Array<any>;
}) => {
  const categories = transactions?.reduce?.((acc, curr) => {
    if (curr.details?.length) {
      acc.concat(
        curr.details?.map(
          (detail: any) => detail.category?.trim?.() || "Sin categ"
        )
      );
    } else {
      acc.push(curr.category?.trim?.() || "Sin cate");
    }

    return acc;
  }, []);

  return Array.from(new Set(categories))?.sort?.() || [];
};

export const getUniquePaymentMethodsFromTransactions = ({
  transactions,
}: {
  transactions: Array<any>;
}) => {
  const methods = transactions
    ?.map?.((transaction) => transaction.paymentMethod)
    ?.filter?.((method) => method);

  return Array.from(new Set(methods))?.sort?.() || [];
};

export const getUniquePaidToFromTransactions = ({
  transactions,
}: {
  transactions: Array<any>;
}) => {
  const paidTo = transactions
    ?.map?.((transaction) => transaction.payTo)
    ?.filter?.((payTo) => payTo);

  return Array.from(new Set(paidTo))?.sort?.() || [];
};

export const getUniqueMonthsFromTransactions = ({
  transactions,
}: {
  transactions: Array<any>;
}) => {
  return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
};

export const getPaymentMethodById = (paymentMethod: string) => {
  return PAYMENT_METHODS()?.find?.(({ id }) => id === paymentMethod);
};
