import { create } from "zustand";
import { TRANSACTIONS_TABLE_VIEW } from "../helpers/constants";

const userPreferencesStore = (set: any, get: any) => ({
  rightPanelIsVisible: true,
  navIsCollapsed: false,
  transactionsFilterIsOpen: false,
  transactionsActiveTab: 0,
  transactionsViewStyle: TRANSACTIONS_TABLE_VIEW,
  lastUsedPaymentMethod: "",
  lastUsedAccount: "",
  reportsSelectedAccount: "",
  transactionsSelectedAccount: "",

  setRightPanelIsVisible: (isVisible: boolean) => {
    set({ rightPanelIsVisible: isVisible });
  },

  setNavIsCollapsed: (isCollapsed: boolean) => {
    set({ navIsCollapsed: isCollapsed });
  },

  setTransactionsFilterIsOpen: (isOpen: boolean) => {
    set({ transactionsFilterIsOpen: isOpen });
  },

  setTransactionsActiveTab: (tab: number) => {
    set({ transactionsActiveTab: tab });
  },

  setTransactionsViewStyle: (viewStyle: number) => {
    set({ transactionsViewStyle: viewStyle });
  },

  setLastUsedPaymentMethod: (methodId: string) => {
    set({ lastUsedPaymentMethod: methodId });
  },

  setLastUsedAccount: (accountId: string) => {
    set({ lastUsedAccount: accountId });
  },

  setReportsSelectedAccount: (accountId: string) => {
    set({ reportsSelectedAccount: accountId });
  },

  setTransactionsSelectedAccount: (accountId: string) => {
    set({ transactionsSelectedAccount: accountId });
  },
});

export default create(userPreferencesStore);
