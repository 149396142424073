import {
  MdCoffee,
  MdCoffeeMaker,
  MdFoodBank,
  MdHome,
  MdFace,
  MdFace2,
  MdFace3,
  MdFace4,
  MdFace5,
  MdFace6,
  MdFavorite,
  MdCarRepair,
  MdPhone,
  MdHolidayVillage,
  MdCategory,
  MdDevices,
  MdLocalPizza,
  MdIcecream,
  MdBakeryDining,
  MdEgg,
  MdKitchen,
  MdTravelExplore,
  MdLocalGroceryStore,
  MdWater,
  MdShop,
  MdConstruction,
  MdHandyman,
  MdPlumbing,
  MdRoomService,
  MdMedicalServices,
  MdCleaningServices,
  MdHomeRepairService,
  MdDesignServices,
  MdMiscellaneousServices,
  MdElectricalServices,
  MdLocalLaundryService,
  MdTwoWheeler,
  MdAirplaneTicket,
  MdFastfood,
  MdFemale,
  MdMale,
  MdFactory,
  MdBuild,
  MdFlight,
  MdForest,
  MdFireTruck,
  MdSmartphone,
  MdSmartScreen,
  MdBusAlert,
  MdBook,
  MdSchool,
  MdBikeScooter,
  MdPedalBike,
  MdElectricBike,
  MdKeyboard,
  MdLaptop,
  MdStar,
  MdMuseum,
  MdApartment,
  MdWork,
  MdInventory2,
  MdWifi,
  MdRocket,
  MdLightbulb,
  MdCarRental,
  MdCamera,
  MdCampaign,
  MdRestaurant,
  MdPrint,
  MdPsychology,
  MdVolunteerActivism,
  MdPalette,
  MdPallet,
  MdMusicNote,
  MdVideocam,
  MdWaterDrop,
  MdChecklist,
  MdAdsClick,
  MdMood,
  MdCloud,
  MdHealthAndSafety,
  MdFitnessCenter,
  MdConfirmationNumber,
  MdDiamond,
  MdHeadphones,
  MdRealEstateAgent,
  MdWeb,
  MdDirections,
  MdCake,
  MdCottage,
  MdCorporateFare,
  MdSelfImprovement,
  MdWallet,
  MdSubscriptions,
  MdBed,
  MdVaccines,
  MdCompost,
  MdChair,
  MdLan,
  MdNature,
  MdPeople,
  MdAir,
  MdHotel,
  MdBatteryFull,
  MdNavigation,
  MdWarehouse,
  MdLandscape,
  MdHiking,
  MdBeachAccess,
  MdWatch,
  MdWoman,
  MdBusiness,
  MdPark,
  MdLocalGasStation,
  MdArchitecture,
  MdCheckroom,
  MdCardMembership,
  MdSolarPower,
  MdLuggage,
  MdGrass,
  MdShower,
  MdAgriculture,
  MdSportsBaseball,
  MdSportsFootball,
  MdSportsBar,
  MdSailing,
  MdFax,
  MdWindPower,
  MdElderly,
  MdElderlyWoman,
  MdLocalDrink,
  MdYard,
  MdWineBar,
  MdGames,
  MdGamepad,
  MdElectricCar,
  MdRamenDining,
  MdChurch,
  MdNightlife,
  MdSick,
  MdCookie,
  MdGarage,
  MdSportsGymnastics,
  MdSurfing,
  MdSportsMotorsports,
  MdEmail,
  MdAttachMoney,
} from "react-icons/md";

export const ALL_USER_ICONS = [
  { name: "MdFitnessCenter", icon: MdFitnessCenter },
  { name: "MdConfirmationNumber", icon: MdConfirmationNumber },
  { name: "MdDiamond", icon: MdDiamond },
  { name: "MdHeadphones", icon: MdHeadphones },
  { name: "MdRealEstateAgent", icon: MdRealEstateAgent },
  { name: "MdWeb", icon: MdWeb },
  { name: "MdDirections", icon: MdDirections },
  { name: "MdCake", icon: MdCake },
  { name: "MdCottage", icon: MdCottage },
  { name: "MdCorporateFare", icon: MdCorporateFare },
  { name: "MdSelfImprovement", icon: MdSelfImprovement },
  { name: "MdWallet", icon: MdWallet },
  { name: "MdSubscriptions", icon: MdSubscriptions },
  { name: "MdBed", icon: MdBed },
  { name: "MdVaccines", icon: MdVaccines },
  { name: "MdCompost", icon: MdCompost },
  { name: "MdChair", icon: MdChair },
  { name: "MdLan", icon: MdLan },
  { name: "MdNature", icon: MdNature },
  { name: "MdPeople", icon: MdPeople },
  { name: "MdAir", icon: MdAir },
  { name: "MdHotel", icon: MdHotel },
  { name: "MdBatteryFull", icon: MdBatteryFull },
  { name: "MdNavigation", icon: MdNavigation },
  { name: "MdWarehouse", icon: MdWarehouse },
  { name: "MdLandscape", icon: MdLandscape },
  { name: "MdHiking", icon: MdHiking },
  { name: "MdBeachAccess", icon: MdBeachAccess },
  { name: "MdWatch", icon: MdWatch },
  { name: "MdWoman", icon: MdWoman },
  { name: "MdBusiness", icon: MdBusiness },
  { name: "MdPark", icon: MdPark },
  { name: "MdLocalGasStation", icon: MdLocalGasStation },
  { name: "MdArchitecture", icon: MdArchitecture },
  { name: "MdCheckroom", icon: MdCheckroom },
  { name: "MdCardMembership", icon: MdCardMembership },
  { name: "MdSolarPower", icon: MdSolarPower },
  { name: "MdLuggage", icon: MdLuggage },
  { name: "MdGrass", icon: MdGrass },
  { name: "MdShower", icon: MdShower },
  { name: "MdAgriculture", icon: MdAgriculture },
  { name: "MdSportsBaseball", icon: MdSportsBaseball },
  { name: "MdSportsFootball", icon: MdSportsFootball },
  { name: "MdSportsBar", icon: MdSportsBar },
  { name: "MdSailing", icon: MdSailing },
  { name: "MdFax", icon: MdFax },
  { name: "MdWindPower", icon: MdWindPower },
  { name: "MdElderly", icon: MdElderly },
  { name: "MdElderlyWoman", icon: MdElderlyWoman },
  { name: "MdLocalDrink", icon: MdLocalDrink },
  { name: "MdYard", icon: MdYard },
  { name: "MdWineBar", icon: MdWineBar },
  { name: "MdGames", icon: MdGames },
  { name: "MdGamepad", icon: MdGamepad },
  { name: "MdElectricCar", icon: MdElectricCar },
  { name: "MdRamenDining", icon: MdRamenDining },
  { name: "MdChurch", icon: MdChurch },
  { name: "MdNightlife", icon: MdNightlife },
  { name: "MdSick", icon: MdSick },
  { name: "MdCookie", icon: MdCookie },
  { name: "MdGarage", icon: MdGarage },
  { name: "MdSportsGymnastics", icon: MdSportsGymnastics },
  { name: "MdSurfing", icon: MdSurfing },
  { name: "MdSportsMotorsports", icon: MdSportsMotorsports },
  { name: "MdMuseum", icon: MdMuseum },
  { name: "MdApartment", icon: MdApartment },
  { name: "MdWork", icon: MdWork },
  { name: "MdInventory2", icon: MdInventory2 },
  { name: "MdWifi", icon: MdWifi },
  { name: "MdRocket", icon: MdRocket },
  { name: "MdLightbulb", icon: MdLightbulb },
  { name: "MdCarRental", icon: MdCarRental },
  { name: "MdCamera", icon: MdCamera },
  { name: "MdCampaign", icon: MdCampaign },
  { name: "MdRestaurant", icon: MdRestaurant },
  { name: "MdPrint", icon: MdPrint },
  { name: "MdPsychology", icon: MdPsychology },
  { name: "MdVolunteerActivism", icon: MdVolunteerActivism },
  { name: "MdPalette", icon: MdPalette },
  { name: "MdPallet", icon: MdPallet },
  { name: "MdMusicNote", icon: MdMusicNote },
  { name: "MdVideocam", icon: MdVideocam },
  { name: "MdWaterDrop", icon: MdWaterDrop },
  { name: "MdChecklist", icon: MdChecklist },
  { name: "MdAdsClick", icon: MdAdsClick },
  { name: "MdMood", icon: MdMood },
  { name: "MdCloud", icon: MdCloud },
  { name: "MdHealthAndSafety", icon: MdHealthAndSafety },
  { name: "MdEmail", icon: MdEmail },
  { name: "MdBook", icon: MdBook },
  { name: "MdSchool", icon: MdSchool },
  { name: "MdBikeScooter", icon: MdBikeScooter },
  { name: "MdPedalBike", icon: MdPedalBike },
  { name: "MdElectricBike", icon: MdElectricBike },
  { name: "MdKeyboard", icon: MdKeyboard },
  { name: "MdLaptop", icon: MdLaptop },
  { name: "MdBusAlert", icon: MdBusAlert },
  { name: "MdFireTruck", icon: MdFireTruck },
  { name: "MdSmartphone", icon: MdSmartphone },
  { name: "MdSmartScreen", icon: MdSmartScreen },
  { name: "MdFastfood", icon: MdFastfood },
  { name: "MdFemale", icon: MdFemale },
  { name: "MdMale", icon: MdMale },
  { name: "MdFactory", icon: MdFactory },
  { name: "MdBuild", icon: MdBuild },
  { name: "MdFlight", icon: MdFlight },
  { name: "MdForest", icon: MdForest },
  { name: "MdTwoWheeler", icon: MdTwoWheeler },
  { name: "MdAirplaneTicket", icon: MdAirplaneTicket },
  { name: "MdConstruction", icon: MdConstruction },
  { name: "MdHandyman", icon: MdHandyman },
  { name: "MdPlumbing", icon: MdPlumbing },
  { name: "MdRoomService", icon: MdRoomService },
  { name: "MdMedicalServices", icon: MdMedicalServices },
  { name: "MdCleaningServices", icon: MdCleaningServices },
  { name: "MdHomeRepairService", icon: MdHomeRepairService },
  { name: "MdDesignServices", icon: MdDesignServices },
  { name: "MdMiscellaneousServices", icon: MdMiscellaneousServices },
  { name: "MdElectricalServices", icon: MdElectricalServices },
  { name: "MdLocalLaundryService", icon: MdLocalLaundryService },
  { name: "MdLocalGroceryStore", icon: MdLocalGroceryStore },
  { name: "MdWater", icon: MdWater },
  { name: "MdTravelExplore", icon: MdTravelExplore },
  { name: "MdShop", icon: MdShop },
  { name: "MdDevices", icon: MdDevices },
  { name: "MdLocalPizza", icon: MdLocalPizza },
  { name: "MdIcecream", icon: MdIcecream },
  { name: "MdBakeryDining", icon: MdBakeryDining },
  { name: "MdKitchen", icon: MdKitchen },
  { name: "MdEgg", icon: MdEgg },
  { name: "MdHolidayVillage", icon: MdHolidayVillage },
  { name: "MdCategory", icon: MdCategory },
  { name: "MdCoffee", icon: MdCoffee },
  { name: "MdCoffeeMaker", icon: MdCoffeeMaker },
  { name: "MdFoodBank", icon: MdFoodBank },
  { name: "MdFace", icon: MdFace },
  { name: "MdFace2", icon: MdFace2 },
  { name: "MdFac3", icon: MdFace3 },
  { name: "MdFace4", icon: MdFace4 },
  { name: "MdFace5", icon: MdFace5 },
  { name: "MdFace6", icon: MdFace6 },
  { name: "MdHome", icon: MdHome },
  { name: "MdFavorite", icon: MdFavorite },
  { name: "MdCarRepair", icon: MdCarRepair },
  { name: "MdPhone", icon: MdPhone },
  { name: "MdStar", icon: MdStar },
  { name: "MdAttachMoney", icon: MdAttachMoney },
];
