import { ReactElement } from "react";
import { MdInfo } from "react-icons/md";
import { IButton } from "../Button/Button";
import clsx from "clsx";
import ButtonGroup from "../ButtonGroup/ButtonGroup";

interface IInfoBlock {
  icon?: ReactElement;
  message?: String | ReactElement;
  buttons?: Array<IButton>;
  bg?: String;
  title?: String;
}

const InfoBlock: React.FC<IInfoBlock> = ({
  icon = <MdInfo size={50} />,
  title,
  message,
  buttons = null,
  bg = "bg-gray-200",
}) => {
  return (
    <div className={clsx(["flex", "items-center", "justify-center", "w-full"])}>
      <div
        className={clsx([
          "w-full",
          "flex",
          "flex-col",
          "sm:flex-row",
          "rounded-md",
          "md:rounded-lg",
          "items-center",
          "gap-2",
          "sm:gap-4",
          "p-2",
          "md:p-4",
          "text-gray-800",
          bg,
          "relative",
        ])}
      >
        {icon && (
          <>
            <div className={clsx(["text-cyan-400", "hidden", "lg:flex"])}>
              {icon}
            </div>
            {/* <div
              className={clsx([
                "absolute",
                "bottom-2",
                "left-2",
                "scale-75",
                "pointer-events-none",
                "text-cyan-400",
                "md:hidden",
              ])}
            >
              {icon}
            </div> */}
          </>
        )}
        <div
          className={clsx([
            "flex",
            "items-center",
            "flex-col",
            "lg:flex-row",
            "gap-2",
            "md:gap-1",
            "lg:gap-6",
            "w-full",
            "justify-between",
            "z-0",
          ])}
        >
          <div className={clsx(["flex", "flex-col", "w-full"])}>
            {title && (
              <h5 className={clsx(["font-medium", "text-sm", "md:text-base"])}>
                {title}
              </h5>
            )}
            <p className={clsx(["text-sm", "md:text-md", "text-left"])}>
              {message}
            </p>
          </div>
          {buttons && (
            <div
              className={clsx([
                "flex",
                "shrink-0",
                "justify-end",
                "w-full",
                "lg:w-auto",
              ])}
            >
              <ButtonGroup buttons={buttons} gap={2} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InfoBlock;
