import Routes from "./Routes";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, Slide } from "react-toastify";
import "moment/locale/es";
import ApplyTheme from "./components/ApplyTheme/ApplyTheme";
import "flag-icons";
import "react-tooltip/dist/react-tooltip.css";

// moment.locale("es");

function App() {
  const theme = localStorage?.getItem?.("theme") || "";

  if ("serviceWorker" in navigator) {
    // console.log("Si tiene service worker");
    // window.addEventListener("load", () => {
    //   navigator.serviceWorker.register("/worker.js").then((registration) => {
    //     registration.unregister();
    //   });
    // });
  }

  return (
    <>
      <ApplyTheme />
      <ToastContainer
        transition={Slide}
        position="top-center"
        autoClose={2000}
        pauseOnHover
        pauseOnFocusLoss={false}
        closeOnClick
        limit={3}
        theme={theme}
      />
      <Routes />
    </>
  );
}

export default App;
