import { MdClose } from "react-icons/md";
import clsx from "clsx";
import Button from "../Button/Button";
import TextInput from "../TextInput/TextInput";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

export interface IModalHeaderSearch {
  onClose: () => void;
  onChange: (e: any) => void;
  value: string;
  placeholder: string;
  onEnterKey?: () => void;
}

const ModalHeaderSearch: React.FC<IModalHeaderSearch> = ({
  onClose,
  onChange,
  value,
  placeholder,
  onEnterKey,
}) => {
  const componentContainer = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (componentContainer?.current) {
      const el = componentContainer?.current;

      if (el) {
        (componentContainer?.current as HTMLElement).focus?.();
      }
    }
  }, [componentContainer]);
  return (
    <div
      className={clsx([
        "flex",
        "flex-row",
        "items-start",
        "justify-between",
        "gap-6",
        "bg-white",
        "p-4",
        "md:p-6",
      ])}
      autoFocus
      tabIndex={0}
      ref={componentContainer}
    >
      <TextInput
        value={value}
        onChange={(e: any) => onChange(e)}
        placeholder={placeholder}
        onKeyDown={({ keyCode }) => {
          if (keyCode === 13) {
            onEnterKey?.();
          }
        }}
      />
      <Button
        kind="danger"
        onClick={() => onClose()}
        icon={<MdClose size={28} />}
        tooltip={t("shared.close")}
        classes={clsx([
          "!h-[35px] md:!h-[40px]",
          "max-w-auto min-w-unset !w-[20px] md:!w-[30px]",
          "justify-center",
          "rounded-sm",
        ])}
      />
    </div>
  );
};

export default ModalHeaderSearch;
