import { create } from "zustand";

const useModalsStore = (set: any, get: any) => ({
  showCreateNewAccount: false,
  showCreateNewTask: false,
  showCreateNewLink: false,
  showEditProfile: false,
  showEditAccount: "",
  showChangePassword: false,
  showDeleteHistoryConfirm: false,

  setShowCreateNewAccount: (isVisible: boolean) => {
    set({ showCreateNewAccount: isVisible });
  },

  setShowCreateNewTask: (isVisible: boolean) => {
    set({ showCreateNewTask: isVisible });
  },

  setShowCreateNewLink: (isVisible: boolean) => {
    set({ showCreateNewLink: isVisible });
  },

  setShowChangePassword: (isVisible: boolean) => {
    set({ showChangePassword: isVisible });
  },

  setShowEditProfile: (isVisible: boolean) => {
    set({ showEditProfile: isVisible });
  },

  setShowEditAccount: (accountId: string) => {
    set({ showEditAccount: accountId });
  },

  setShowDeleteHistoryConfirm: (isVisible: boolean) => {
    set({ showDeleteHistoryConfirm: isVisible });
  },
});

export default create(useModalsStore);
