import { MdCheck, MdClose, MdNoteAlt } from "react-icons/md";
import { useState } from "react";
import Modal from "../Modal/Modal";
import tasksStore from "../../store/tasksStore";
import { toast } from "react-toastify";

import clsx from "clsx";
import { CONFIRM_DIALOG_ICON_SIZE } from "../../constants/icons";
import { useTranslation } from "react-i18next";

interface INewTaskModal {
  onClose?: () => void;
  onCreated?: (e: any) => void;
}

const NewTaskModal: React.FC<INewTaskModal> = ({
  onClose = () => {},
  onCreated = () => {},
}) => {
  const [isAddingTask, setIsAddingTask] = useState<boolean>(false);
  const [task, setTask] = useState<string>("");
  const { addTask, getTasks } = tasksStore();
  const { t } = useTranslation();

  return (
    <>
      <Modal
        width="md:min-w-[600px]"
        onEscape={() => {
          if (!isAddingTask) {
            onClose();
          }
        }}
      >
        <Modal.Header
          title={t("notes.add_note_title")}
          onClose={() => {
            if (!isAddingTask) {
              onClose();
            }
          }}
          icon={<MdNoteAlt size={CONFIRM_DIALOG_ICON_SIZE} />}
        />
        <Modal.Body>
          <form
            id="create-task"
            onSubmit={async (e) => {
              e?.preventDefault?.();
              setIsAddingTask(true);

              await addTask({
                onError: (e) => {
                  console.log("Errpr", e);
                  setIsAddingTask(false);
                  toast.error(t("notes.error_adding_note"));
                },
                content: task,
                onResolved: (e) => {
                  setIsAddingTask(false);
                  toast.success(t("notes.note_added_message"));
                  setTask("");
                  getTasks({
                    onResolved: () => {},
                    onError: () => {},
                  });
                  // onClose();
                },
              });
            }}
          >
            <div className="flex flex-col gap-4">
              <textarea
                required
                rows={8}
                disabled={isAddingTask}
                className={clsx([
                  "w-full",
                  "border",
                  "text-lg",
                  "rounded-sm",
                  "p-4",
                  "placeholder:text-gray-400",
                  "placeholder:text-sm",
                  "placeholder:font-light",
                ])}
                placeholder={t("notes.add_note_placeholder")}
                onChange={(e) => setTask(e?.target?.value)}
                value={task}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer
          actions={[
            {
              text: t("shared.save"),
              onClick: () => {},
              kind: "success",
              icon: <MdCheck size={32} />,
              type: "submit",
              form: "create-task",
              disabled: isAddingTask,
              isLoading: isAddingTask,
            },
            {
              text: t("shared.close"),
              onClick: () => onClose(),
              kind: "secondary",
              icon: <MdClose size={32} />,
              disabled: isAddingTask,
            },
          ]}
        />
      </Modal>
    </>
  );
};

export default NewTaskModal;
