import { useTranslation } from "react-i18next";
import AccountsSlider from "../../components/Accounts/AccountsSlider";
import PageTitle from "../../components/PageTitle/PageTitle";
import TwoSidebars from "../../layouts/TwoSidebars/TwoSidebars";
import ButtonGroup from "../../components/ButtonGroup/ButtonGroup";
import clsx from "clsx";
import SettingsBlocks from "../../components/SettingsBlocks/SettingsBlocks";
import { useNavigate } from "react-router-dom";
import useModalsStore from "../../store/modalsStore";

import {
  MdAdd,
  MdArrowBack,
  MdAttachMoney,
  MdCompareArrows,
  MdOutlineShoppingCart,
  MdViewSidebar,
} from "react-icons/md";
import { useMediaQuery } from "react-responsive";
import { BUTTON_STYLES } from "../../helpers/constants";

const AccountsPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setShowCreateNewAccount, setShowEditAccount } = useModalsStore();

  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  return (
    <TwoSidebars
      right={
        <>
          {<SettingsBlocks layout="vertical" columns={isMobile ? 2 : 1} />}

          {/* <SettingsBlocks layout="vertical" /> */}
        </>
      }
    >
      <div className={clsx(["flex", "w-full", "flex-col", "gap-0"])}>
        <PageTitle
          title={t("settings.title")}
          subtitle={t("settings.accounts_title")}
          titleLink="/settings"
          isLoading={false}
          rightSide={
            <ButtonGroup
              align="right"
              gap={2}
              buttons={[
                {
                  icon: <MdArrowBack size={24} />,
                  tooltip: "Atras",
                  onClick: () => {
                    navigate("/settings");
                  },
                  hide: !isMobile,
                  classes: BUTTON_STYLES.HEADING,
                },
                {
                  icon: <MdOutlineShoppingCart size={24} />,
                  tooltip: "Añadir gasto",
                  onClick: () => {
                    navigate("/transactions/expense");
                  },
                  hide: isMobile,
                },

                {
                  icon: <MdAttachMoney size={24} />,
                  tooltip: "Añadir ingreso",
                  hide: isMobile,
                  onClick: () => {
                    navigate("/transactions/income");
                  },
                },
                {
                  icon: <MdCompareArrows size={24} />,
                  tooltip: "Transacciones",
                  onClick: () => {
                    navigate("/transactions");
                  },
                  hide: isMobile,
                },
                {
                  icon: <MdAdd size={24} />,
                  tooltip: "Add new",
                  onClick: () => {
                    setShowCreateNewAccount(true);
                  },
                  hide: !isMobile,
                  classes: BUTTON_STYLES.HEADING,
                },
              ]}
            />
          }
        />

        <AccountsSlider
          layout="horizontal"
          showTitle={false}
          onCardClick={({ account }) => {
            navigate(`/reports/${account}`);
          }}
          desktopSettings={
            {
              // rows: 2,
              // slidesPerRow: 2,
            }
          }
          mobileSettings={{
            unslick: true,
          }}
        />
      </div>
    </TwoSidebars>
  );
};

export default AccountsPage;
