import clsx from "clsx";
import { Link } from "react-router-dom";
import ExpenserTriangle from "../ExpenserTriangle/ExpenserTriangle";

interface ISidebarLogo {
  rotate?: boolean;
}

const SidebarLogo: React.FC<ISidebarLogo> = ({ rotate = false }) => {
  return (
    <Link
      to="/dashboard"
      className={clsx([
        "z-[1000]",
        "relative",
        "flex",
        "flex-row",
        "justify-center",
        "items-center",
        "md:justify-end",
        {
          "md:[writing-mode:vertical-lr]": rotate,
        },
        "gap-1",
      ])}
    >
      <ExpenserTriangle
        size={30}
        className={clsx([
          "text-red-600",
          {
            "rotate-90": rotate,
            "rotate-0": !rotate,
          },
        ])}
      />
      <h1
        className={clsx([
          "text-2xl",
          "md:text-3xl",
          "font-medium",
          {
            flex: rotate,
          },
        ])}
      >
        eXpenser
        <div className="text-xs dark:text-red-500 inline-flex uppercase items-end md:items-start pb-1 md:pb-0">
          .net
        </div>
      </h1>
    </Link>
  );
};

export default SidebarLogo;
