import clsx from "clsx";
import TransactionsByTypeChart from "../Charts/TransactionsByTypeChart";
import AccountsSummaryTable from "../InvoicesChart/AccountsSummaryTable";
import TransactionsSummary from "../../pages/Transactions/TransactionsSummary";
import { useMediaQuery } from "react-responsive";

interface ITransactionsByTypeReport {
  transactions: Array<any>;
  currency: string;
}

const TransactionsByTypeReport: React.FC<ITransactionsByTypeReport> = ({
  transactions,
  currency,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  return (
    <div className={clsx(["flex", "w-full", "flex-col", "gap-4"])}>
      {isMobile && (
        <div
          className={clsx([
            "flex",
            "w-full",
            "px-10",
            "items-center",
            "justify-center",
          ])}
        >
          <TransactionsSummary
            onUpdateNeeded={() => {}}
            transactions={transactions}
            layout="horizontal"
            isLoading={false}
          />
        </div>
      )}
      <TransactionsByTypeChart
        transactions={transactions}
        currency={currency}
      />
      <AccountsSummaryTable transactions={transactions} currency={currency} />
    </div>
  );
};

export default TransactionsByTypeReport;
