import DataTable from "react-data-table-component";
import {
  EXPENSE_TYPE,
  INCOME_TYPE,
  PAGINATION_MIN,
  PAGINATION_ROWS_PER_PAGE_OPTIONS,
} from "../../helpers/constants";
import { MdArrowDownward, MdOutlineFolder } from "react-icons/md";
import { useTranslation } from "react-i18next";
import CellWithBackground from "../../pages/Transactions/BackgroundCell";

import TransactionTypeCell from "../../pages/Transactions/TransactionTypeCell";
import {
  formatNumber,
  getCurrencyDataByCode,
  getTotalByMonth,
} from "../../helpers/utils";
import moment from "moment";
import i18next from "i18next";
import _ from "lodash";
import transactionsStore from "../../store/transactionsStore";
import { useNavigate } from "react-router-dom";
import ButtonGroupCell from "../../pages/Transactions/ButtonGroupCell";
import clsx from "clsx";

interface IMonthlySummaryTable {
  transactions: Array<any>;
  currency: string;
}

const MonthlySummaryTable: React.FC<IMonthlySummaryTable> = ({
  transactions,
  currency,
}) => {
  const currentLanguage = i18next.language?.toLowerCase?.() || "en";
  const { filters } = transactionsStore();
  const {
    main: mainFilter = {
      dateFrom: "",
      dateTo: "",
      type: "",
      payTo: "",
      status: "",
      account: "",
      paymentMethod: "",
      tags: [],
    },
  } = filters || {};

  const currencySymbol =
    getCurrencyDataByCode({
      code: currency,
    })?.symbol || "";

  const formattedData = () => {
    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
      ?.map((month: any) => {
        const expenses = getTotalByMonth({
          month: month,
          type: EXPENSE_TYPE,
          transactions,
        });
        const income = getTotalByMonth({
          month: month,
          type: INCOME_TYPE,
          transactions,
        });
        return {
          month: _.capitalize(
            `${moment(month, "MM").locale(currentLanguage).format("MMMM")}`
          ),
          income,
          expense: expenses,
          balance: income - expenses,
        };
      })
      ?.reduce?.((acc: any, curr: any) => {
        if (!curr.income && !curr.expense) {
          return acc;
        } else {
          return [...acc, curr];
        }
      }, []);
  };

  const navigate = useNavigate();

  const { t } = useTranslation();
  const COLUMNS = [
    {
      cell: (row: any) => {
        return (
          <CellWithBackground
            background={"#34d399"}
            color={"#fff"}
            value={_.capitalize(row.month)}
          />
        );
      },
      selector: (row: any) => row.month,
      name: t("shared.month"),
      sortable: true,
    },
    {
      name: t("shared.income"),
      selector: (row: any) => row.income,
      sortable: true,
      cell: (row: any) => {
        return (
          <TransactionTypeCell currency={currencySymbol} type={INCOME_TYPE}>
            {formatNumber(row.income)}
          </TransactionTypeCell>
        );
      },
      right: true,
      omit: mainFilter?.type === EXPENSE_TYPE,
    },
    {
      right: true,
      name: t("shared.expenses"),
      selector: (row: any) => row.expense,
      sortable: true,
      cell: (row: any) => {
        return (
          <TransactionTypeCell currency={currencySymbol} type={EXPENSE_TYPE}>
            {formatNumber(row.expense)}
          </TransactionTypeCell>
        );
      },
      omit: mainFilter?.type === INCOME_TYPE,
    },
    {
      right: true,
      cell: (row: any) => {
        const isRed = row.balance < 0;

        return (
          <CellWithBackground
            background={isRed ? "rgb(239, 68, 68)" : "rgb(34, 197, 94)"}
            color={"#fff"}
            value={`${currencySymbol}${formatNumber(row.balance)}`}
          />
        );
      },
      selector: (row: any) => row.balance,
      name: t("shared.balance"),
      sortable: true,
      omit: !!mainFilter?.type,
    },
    {
      cell: (row: any) => {
        return (
          <div
            className={clsx(["flex", "w-full", "justify-end", "items-center"])}
          >
            <ButtonGroupCell
              buttons={[
                // {
                //   icon: <MdAttachment size={24} />,
                //   onClick: () => {},
                //   hide: !row?.attachments?.length,
                // },
                {
                  onClick: (e: any) => {
                    if (e?.metaKey || e?.altKey) {
                      window.open(
                        `/transactions/${mainFilter?.account || ""}`,
                        "_blank"
                      );
                    } else {
                      navigate(`/transactions/${mainFilter?.account || ""}`);
                    }
                  },
                  icon: <MdOutlineFolder size={24} />,
                },
              ]}
            />
          </div>
        );
      },
      selector: (row: any) => row,
      name: "",
      sortable: false,
      right: true,
    },
  ];

  return (
    <DataTable
      columns={COLUMNS}
      data={formattedData()}
      pagination={false}
      highlightOnHover
      persistTableHead
      sortIcon={<MdArrowDownward />}
      selectableRowsVisibleOnly
      paginationRowsPerPageOptions={[]}
      paginationPerPage={12}
      // striped
    />
  );
};

export default MonthlySummaryTable;
