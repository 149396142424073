import { create } from "zustand";
import apiClient from "../helpers/api-client";
import {
  API_ENDPOINT_ADD_CATEGORY,
  API_ENDPOINT_ALL_CATEGORIES,
} from "../helpers/constants";
import { toast } from "react-toastify";
import _ from "lodash";

const categoriesStore = (set: any, get: any) => ({
  storeNotes: [],
  isLoadingStoreNotes: false,
  errorLoadingStoreNotes: false,
  allCategories: [],
  error: null,
  isLoading: true,

  setAllCategories: async ({
    onResolved = () => {},
    onError = () => {},
  }: {
    onResolved?: (e: any) => void;
    onError?: (e: any) => void;
  }) => {
    set({ isLoading: true, error: null });

    apiClient
      .get(API_ENDPOINT_ALL_CATEGORIES)
      .then((data: any) => {
        onResolved?.(data.data);
        set({ allCategories: data.data, isLoading: false });
      })
      .catch((e: any) => {
        onError?.(e);
        set({ isLoading: false, error: e });
      });
  },

  refreshAllCategories: async () => {
    await get().setAllCategories({});
  },

  getCategoryByName: (category: string) => {
    const allCategories = get().allCategories;
    const error = get().error;

    if (error) {
      return null;
    } else {
      return _.find(allCategories, { category });
    }
  },

  getCategories: async ({
    onResolved,
    onError,
  }: {
    onResolved: (e: any) => void;
    onError?: (e: any) => void;
  }) => {
    apiClient
      .get(API_ENDPOINT_ALL_CATEGORIES)
      .then((notes) => {
        onResolved(notes.data);
      })
      .catch((e: any) => {
        if (onError) {
          onError(e);
        } else {
          toast.error(e);
        }
      });
  },

  addCategory: async ({
    onResolved = () => {},
    onError = () => {},
    params = {},
  }: {
    onResolved: (e: any) => void;
    onError: (e: any) => void;
    params: any;
  }) => {
    apiClient
      .post(API_ENDPOINT_ADD_CATEGORY, params)
      .then((res: any) => {
        onResolved(res?.data);
      })
      .catch((e: any) => {
        onError(e);
      });
  },
});

export default create(categoriesStore);
