import {
  MdAccountBox,
  MdArrowBack,
  MdAttachMoney,
  MdCheck,
  MdCompareArrows,
  MdOutlineShoppingCart,
  MdSettings,
  MdViewSidebar,
} from "react-icons/md";
import PageTitle from "../../components/PageTitle/PageTitle";
import TwoSidebars from "../../layouts/TwoSidebars/TwoSidebars";
import ButtonGroup from "../../components/ButtonGroup/ButtonGroup";
import { Link, useNavigate } from "react-router-dom";
import userPreferences from "../../store/userPreferences";
import clsx from "clsx";
import { useMediaQuery } from "react-responsive";
import InfoBlock from "../../components/InfoBlock/InfoBlock";
import TextInput from "../../components/TextInput/TextInput";
import Select from "../../components/Select/Select";
import { useTranslation } from "react-i18next";
import { useEffect, useId, useState } from "react";
import usersStore from "../../store/usersStore";
import { LazyLoadImage } from "react-lazy-load-image-component";
import LoadingWithText from "../../components/LoadingWithText/LoadingWithText";
import apiClient from "../../helpers/api-client";
import {
  API_ENDPOINT_UPDATE_PROFILE,
  APP_USER_ID_KEY,
  BUTTON_STYLES,
  FILE_STORAGE_SERVER_URL,
} from "../../helpers/constants";
import { toast } from "react-toastify";
import { COUNTRY_SELECT_OPTIONS } from "../../helpers/utils";
import SettingsBlocks from "../../components/SettingsBlocks/SettingsBlocks";
import ChangePasswordModal from "../../components/ChangePasswordModal/ChangePasswordModal";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import { CONFIRM_DIALOG_ICON_SIZE } from "../../constants/icons";
import SectionTitle from "../../components/SectionTitle/SectionTitle";

const FORM_ID = "update-profile";

const ProfilePage: React.FC = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const { getUser } = usersStore();
  const [name, setName] = useState<string>("Cali");
  const [picture, setPicture] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [website, setWebsite] = useState<string>("");

  const [isUpdatingProfile, setIsUpdatingProfile] = useState<boolean>(false);
  const [file, setFile] = useState<any>(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const tooltipId = useId();
  const { t } = useTranslation();

  const { rightPanelIsVisible, setRightPanelIsVisible } = userPreferences();

  useEffect(() => {
    const data = localStorage.getItem(APP_USER_ID_KEY);

    const nada = async () => {
      if (data) {
        const res = await getUser({ _id: data });

        if (res.success) {
          setPicture(
            res?.data?.picture
              ? `${FILE_STORAGE_SERVER_URL}/${res?.data?.picture}`
              : ""
          );
          setName(res?.data?.name || "");
        } else {
          console.log("Error en los datos del usuario");
        }
      } else {
        console.log("No se puede");
      }
    };

    nada();
  }, []);

  const updateProfile = () => {
    setIsUpdatingProfile(true);

    const formData = new FormData();

    if (file) {
      formData.append("file", file);
    }

    formData.append("name", name);

    apiClient
      .put(API_ENDPOINT_UPDATE_PROFILE, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(async (res: any) => {
        toast.success("Perfil actualizado");
        setShowConfirmDialog(false);
      })
      .catch((e) => {
        toast.error("Error actualizando tu perfil");
      })
      .finally(() => {
        setIsUpdatingProfile(false);
      });
  };

  return (
    <>
      <TwoSidebars
        right={
          <>
            {<SettingsBlocks layout="vertical" columns={isMobile ? 2 : 1} />}
            {/* <TasksListPanel />
            <CustomLinks /> */}
          </>
        }
      >
        <div className={clsx(["flex", "w-full", "flex-col", "gap-0"])}>
          <PageTitle
            title={t("settings.title")}
            subtitle={t("profile_page.title")}
            titleLink="/settings"
            isLoading={false}
            rightSide={
              <>
                <ButtonGroup
                  align="right"
                  gap={2}
                  buttons={[
                    {
                      icon: <MdArrowBack size={24} />,
                      tooltip: "Atras",
                      onClick: () => {
                        navigate("/settings");
                      },
                      hide: !isMobile,
                      classes: BUTTON_STYLES.HEADING,
                    },
                    {
                      icon: <MdOutlineShoppingCart size={24} />,
                      tooltip: "Añadir gasto",
                      onClick: () => {
                        navigate("/transactions/expense");
                      },
                      hide: isMobile,
                    },

                    {
                      icon: <MdAttachMoney size={24} />,
                      tooltip: "Añadir ingreso",
                      hide: isMobile,
                      onClick: () => {
                        navigate("/transactions/income");
                      },
                    },
                    {
                      icon: <MdCompareArrows size={24} />,
                      tooltip: "Transacciones",
                      onClick: () => {
                        navigate("/transactions");
                      },
                      hide: isMobile,
                    },

                    {
                      icon: <MdViewSidebar size={24} />,
                      tooltip: "Panel lateral",
                      kind: rightPanelIsVisible ? "primary" : "secondary",
                      hide: isMobile,
                      onClick: () => {
                        setRightPanelIsVisible(!rightPanelIsVisible);
                      },
                    },
                  ]}
                />
              </>
            }
          />
          <div className="grid grid-cols-1 gap-6">
            <input
              className="hidden"
              accept="image/*"
              type="file"
              id="select-file-to-upload"
              onChange={async (e: any) => {
                const attachment = e?.target?.files;

                if (attachment?.length) {
                  setFile(attachment?.[0]);
                  setPicture(URL.createObjectURL(attachment?.[0]));
                } else {
                  setFile(null);
                  setPicture("");
                }
              }}
              required
            />
            <div>
              <div>
                <LazyLoadImage
                  alt=""
                  className={clsx([
                    "w-[180px] md:w-[200px]",
                    "h-[180px] md:h-[200px]",
                    "cursor-pointer",
                    "rounded-md md:rounded-lg",
                    "bg-gray-300",
                    "m-auto",
                    "mb-5 md:mb-10",
                    "object-cover",
                  ])}
                  data-tooltip-id={tooltipId}
                  data-tooltip-content="Insertar imagen"
                  data-tooltip-float
                  data-tooltip-position-strategy="fixed"
                  data-tooltip-offset={20}
                  src={picture}
                  placeholder={
                    <div
                      className={clsx([
                        "flex",
                        "h-full",
                        "flex",
                        "items-center",
                        "justify-center",
                        "w-full",
                      ])}
                    >
                      <LoadingWithText />
                    </div>
                  }
                  onClick={() => {
                    const el = document?.getElementById?.(
                      "select-file-to-upload"
                    );

                    if (el) {
                      el?.click?.();
                    }
                  }}
                />
              </div>
            </div>

            <div className={clsx(["flex", "gap-4", "flex-col"])}>
              <SectionTitle title={t("profile_page.personal_info_title")} />
              <InfoBlock message={t("profile_page.personal_info_note")} />
              <form
                className={clsx([
                  "grid",
                  "grid-cols-1",
                  "md:grid-cols-2",
                  "gap-6",
                ])}
                id={FORM_ID}
                onSubmit={async (e: any) => {
                  e?.preventDefault?.();
                  setShowConfirmDialog(true);
                }}
              >
                <TextInput
                  value={name}
                  onChange={(e: any) => setName(e?.target?.value)}
                  label={t("profile_page.name_label")}
                  placeholder={t("profile_page.name_placeholder")}
                  required
                />
                <Select
                  options={COUNTRY_SELECT_OPTIONS}
                  value={country}
                  onChange={(e: any) => setCountry(e?.target?.value)}
                  label={t("profile_page.country_label")}
                />
                <TextInput
                  value={website}
                  onChange={(e: any) => setWebsite(e?.target?.value)}
                  label={t("profile_page.website_label")}
                  placeholder={t("profile_page.website_placeholder")}
                  type="url"
                />
                <TextInput
                  value={phone}
                  onChange={(e: any) => setPhone(e?.target?.value)}
                  type="tel"
                  label={t("profile_page.phone_label")}
                  placeholder={t("profile_page.phone_placeholder")}
                />
              </form>
              <div
                className={clsx([
                  "w-full",
                  "flex",
                  "justify-end",
                  "bg-inherit",
                  "md:py-3",
                  "sticky",
                  "bottom-20",
                  "md:bottom-0",
                ])}
              >
                <ButtonGroup
                  buttons={[
                    {
                      text: t("shared.update"),
                      onClick: () => {
                        setShowConfirmDialog(true);
                      },
                      icon: <MdCheck size={32} />,
                      kind: "success",
                      isLoading: isUpdatingProfile,
                      disabled: isUpdatingProfile,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
          <div className={clsx(["flex", "gap-4", "flex-col", "hidden"])}>
            <SectionTitle title={t("profile_page.change_password_title")} />
            <ChangePasswordModal />
            <div
              className={clsx([
                "w-full",
                "flex",
                "justify-end",
                "bg-inherit",
                "md:py-3",
              ])}
            >
              <ButtonGroup
                buttons={[
                  {
                    text: t("shared.update"),
                    onClick: () => {
                      setShowConfirmDialog(true);
                    },
                    icon: <MdCheck size={32} />,
                    kind: "success",
                    isLoading: isUpdatingProfile,
                    disabled: isUpdatingProfile,
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </TwoSidebars>
      {showConfirmDialog && (
        <ConfirmDialog
          icon={<MdAccountBox size={CONFIRM_DIALOG_ICON_SIZE} />}
          title={t("dialogs.profile.update_dialog_title")}
          description={t("dialogs.profile.update_dialog_question")}
          onClose={() => {
            setShowConfirmDialog(false);
          }}
          confirmKind="success"
          onConfirm={() => {
            updateProfile();
          }}
          isLoading={isUpdatingProfile}
        />
      )}
    </>
  );
};

export default ProfilePage;
