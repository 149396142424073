import { toast } from "react-toastify";

import useModalsStore from "../../store/modalsStore";
import NewAccountModal from "../Button/NewAccountModal/NewAccountModal";
import NewTaskModal from "../NewTaskModal/NewTaskModal";
import NewLinkModal from "../NewLinkModal/NewLinkModal";
import ProfileEditModal from "../ProfileEditModal/ProfileEditModal";
import ChangePasswordModal from "../ChangePasswordModal/ChangePasswordModal";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import useHistoryStore from "../../store/historyStore";

const ModalsLoader: React.FC = () => {
  const {
    setShowCreateNewAccount,
    showCreateNewAccount,
    setShowCreateNewTask,
    showCreateNewTask,
    showCreateNewLink,
    setShowCreateNewLink,
    setShowChangePassword,
    setShowEditProfile,
    showEditProfile,
    showChangePassword,
    showEditAccount,
    setShowEditAccount,
    showDeleteHistoryConfirm,
    setShowDeleteHistoryConfirm,
  } = useModalsStore();

  const { clearHistory } = useHistoryStore();

  return (
    <>
      {/* create new account */}
      {showCreateNewAccount && (
        <NewAccountModal
          onClose={() => setShowCreateNewAccount(false)}
          onCreated={async (data: any) => {
            setShowCreateNewAccount(false);
            toast.success("Cuenta creada correctamente");
          }}
        />
      )}
      {showEditAccount && (
        <NewAccountModal onClose={() => setShowEditAccount("")} />
      )}
      {/* create new note */}
      {showCreateNewTask && (
        <NewTaskModal onClose={() => setShowCreateNewTask(false)} />
      )}
      {/* create new link */}
      {showCreateNewLink && (
        <NewLinkModal onClose={() => setShowCreateNewLink(false)} />
      )}
      {/* edit profile */}
      {showEditProfile && (
        <ProfileEditModal
          onClose={() => setShowEditProfile(false)}
          onUpdate={() => {}}
        />
      )}
      {/* update password */}
      {showChangePassword && <ChangePasswordModal />}
      {/* payment methods */}
      {/* <PaymentMethodsModal onClose={() => {}} /> */}

      {showDeleteHistoryConfirm && (
        <ConfirmDialog
          description="¿Deseas limpiar tu historial?"
          title="Limpiar historial"
          onConfirm={() => {
            clearHistory();
            setShowDeleteHistoryConfirm(false);
          }}
          onClose={() => setShowDeleteHistoryConfirm(false)}
          confirmKind="danger"
        />
      )}
    </>
  );
};

export default ModalsLoader;
