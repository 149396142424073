import { create } from "zustand";
import {
  API_ENDPOINT_ADD_ACCOUNT,
  API_ENDPOINT_ALL_ACCOUNTS,
  API_ENDPOINT_DELETE_ACCOUNT,
  API_ENDPOINT_GET_ACCOUNT,
  API_ENDPOINT_UPDATE_ACCOUNT,
} from "../helpers/constants";
import apiClient from "../helpers/api-client";
import _ from "lodash";
import { transformToSelectOptions } from "../helpers/utils";

const useAccountsStore = (set: any, get: any) => ({
  allAccounts: [],
  error: null,
  isLoading: true,
  allPayToList: [],

  setAllAccounts: async ({
    onResolved = () => {},
    onError = () => {},
  }: {
    onResolved?: (e: any) => void;
    onError?: (e: any) => void;
  }) => {
    set({ isLoading: true, error: null });

    apiClient
      .get(API_ENDPOINT_ALL_ACCOUNTS)
      .then((data: any) => {
        onResolved?.(data.data);
        set({ allAccounts: data.data, isLoading: false });
      })
      .catch((e: any) => {
        onError?.(e);
        set({ isLoading: false, error: e });
      });
  },

  refreshAllAccounts: async () => {
    await get().setAllAccounts({});
  },

  getAccountById: (account: string) => {
    const allAccounts = get().allAccounts;
    const error = get().error;

    if (error) {
      return null;
    } else {
      return _.find(allAccounts, { account });
    }
  },

  registerNewAccount: async ({
    account,
    name,
    bank,
    number,
    currency,
    settings = {},
  }: {
    account: string;
    name: string;
    bank: string;
    number: string;
    currency: string;
    settings: Object;
  }) => {
    return await apiClient
      .post(API_ENDPOINT_ADD_ACCOUNT, {
        account,
        name,
        bank,
        number,
        currency,
        settings,
      })
      .then(async () => {
        await get().refreshAllAccounts();

        return {
          success: true,
        };
      })
      .catch((e: any) => {
        return {
          success: false,
          error: e,
        };
      });
  },

  updateExistingAccount: async ({
    account,
    name,
    bank,
    number,
    currency,
    settings = {},
  }: {
    account: string;
    name: string;
    bank: string;
    number: string;
    currency: string;
    settings: Object;
  }) => {
    return await apiClient
      .put(`${API_ENDPOINT_UPDATE_ACCOUNT}`, {
        account,
        bank,
        name,
        number,
        currency,
        settings,
      })
      .then(async () => {
        await get().refreshAllAccounts();

        return {
          success: true,
        };
      })
      .catch((e: any) => {
        return {
          success: false,
          error: e,
        };
      });
  },

  deleteAccount: async ({ account }: { account: string }) => {
    return await apiClient
      .delete(`${API_ENDPOINT_DELETE_ACCOUNT}/${account}`)
      .then(async (data) => {
        await get().refreshAllAccounts();

        return {
          success: true,
        };
      })
      .catch((e: any) => {
        return {
          success: false,
          error: e,
        };
      });
  },

  getAccount: async ({ account }: { account: string }) => {
    return await apiClient
      .get(`${API_ENDPOINT_GET_ACCOUNT}/${account}`)
      .then((data: any) => {
        return {
          success: true,
          data,
        };
      })
      .catch((e) => {
        return {
          success: false,
          error: e,
        };
      });
  },

  getAccountsListForSelect: () => {
    return transformToSelectOptions({
      data: get().allAccounts || [],
      labelField: "name",
      valueField: "account",
    });
  },

  getFirstAccountInTheList: () => {
    const accounts = get().allAccounts;

    if (accounts?.length) {
      const [firstAccount] = accounts;

      return firstAccount;
    }

    return null;
  },
});

export default create(useAccountsStore);
