import { ReactElement, ReactNode } from "react";
import { MdClose, MdFavorite } from "react-icons/md";
import clsx from "clsx";
import Button from "../Button/Button";
import { CONFIRM_DIALOG_ICON_SIZE } from "../../constants/icons";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

interface IModalHeader {
  title?: string;
  onClose: () => void;
  children?: ReactNode;
  subtitle?: string;
  icon?: ReactElement;
}

const ModalHeader: React.FC<IModalHeader> = ({
  title = "",
  onClose,
  children,
  subtitle = "",
  icon = "",
}) => {
  const hasTitle = title || subtitle;
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  return hasTitle ? (
    <div
      className={clsx([
        "flex",
        "flex-row",
        "items-center",
        {
          "justify-between": hasTitle,
          "justify-end": !hasTitle,
        },
        "relative mb-4",
        "sm:mb-10",
        "after:content-['']",
        "after:border-b-2",
        "sm:after:border-b-[2px]",
        "after:w-1/2",
        "after:border-b-gray-500",
        "after:absolute",
        "after:bottom-0",
        "after:left-0",
        "after:p-6",
        "after:h-[2px]",
        "gap-6",
        "bg-white",
        "px-4 pt-4",
        "md:p-6",
      ])}
    >
      {hasTitle && (
        <div className={clsx(["flex", "gap-1", "sm:gap-2", "items-center"])}>
          {/* {icon && (
            <div className="dark:text-gray-900 text-primary">{icon}</div>
          )} */}
          <h2 className={clsx(["text-sm", "md:text-base", "font-medium"])}>
            {title}
          </h2>
        </div>
      )}
      <Button
        kind="danger"
        onClick={() => onClose()}
        icon={<MdClose size={isMobile ? 24 : 28} />}
        tooltip={t("shared.close")}
        classes={clsx([
          "!h-[35px] md:!h-[40px]",
          "max-w-auto min-w-unset !w-[20px] md:!w-[30px]",
          "justify-center",
          "rounded-sm",
        ])}
      />
    </div>
  ) : (
    <div className={clsx(["absolute", "top-10", "right-10"])}>
      <Button
        kind="secondary"
        onClick={() => onClose()}
        icon={<MdClose size={42} />}
      />
    </div>
  );
};

export default ModalHeader;
